import { useEffect, useState } from "react";
import { Button } from "../ui/Button";
import { useDispatch } from "react-redux";
import { financialApproverData } from "@/state/slices/SubscriptionSlice";
import { AppDispatch } from "@/state/store";
import PlatformSubscribeDialog from "../subscribe/PlatformSubscribeDialog";
import { APPROVER } from "@/utils/appConstants";
import { getUserRoles } from "@/utils/utils";

const ActivateSubscriptionButton = ({
  setIsLoading,
  setSubscriptionApprovalList,
  setIsPlatformSubscribed
}: any) => {

  
  const dispatch = useDispatch<AppDispatch>();
  const [isPlatformSubscribing, setIsPlatformSubscribing] = useState(false);
 
  const loadFinancialApproverData = () => {
    if (getUserRoles().includes(APPROVER)) {
      dispatch(financialApproverData()).then((action: any) => {
        if (financialApproverData.fulfilled.match(action)) {
          setSubscriptionApprovalList(action.payload.data);
          setIsLoading(false);
        } else if (financialApproverData.rejected.match(action)) {
          //@ts-ignore
          error(action?.error?.message);
        }
      });
    }
  };
  useEffect(() => {
    setIsLoading(false);
    loadFinancialApproverData();
  }, [isPlatformSubscribing]);

  const handlePlatformSubscription = () => {
    setIsPlatformSubscribing(true);
  };
  return (
    <>
    <div className="h-4/5 flex flex-col justify-center items-center">
      <div className="centered-div">
        <Button
          variant="success"
          className="float-right m-4 mt-0"
          onClick={() => handlePlatformSubscription()}
          >
          Activate Platform Subscription
        </Button>
      </div>
    </div>
    {isPlatformSubscribing && (
        <PlatformSubscribeDialog
          setIsPlatformSubscribing={setIsPlatformSubscribing}
          setIsPlatformSubscribed={setIsPlatformSubscribed}
        />
      )}
            </>
  );
};

export default ActivateSubscriptionButton;
