import { lemmLogo } from "@/assets/icons-color";
import { useNavigate } from "react-router-dom";

const SideNavBarHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="flex-between mb-4">
      {/* <div className="text-white text-lg font-semibold">Lemma</div> */}
      <img src={lemmLogo} className="h-17 w-[114px]" alt="Lemmata Logo" />
      {/* Notification icon */}
      {/* Below code commented as part of LEM-667 ticket.
      <div className="relative">
        <img
          src={notification}
          className="text-white text-xl h-[24px] w-[24px]"
          alt="notification"
        />
        {// Notification indicator }
        <div className="absolute bottom-[-2px] right-[-4px] w-4 h-4 bg-red-500 rounded-full flex-center text-white text-xs">
          3
        </div>
      </div> */}
    </div>
  );
};
export default SideNavBarHeader;
