import { submitToPublish } from "@/assets/icons";
import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/Button";
import { useDispatch } from "react-redux";
import { getCsvList, uploadSingleFile } from "@/state/slices/CsvSlice";
import useToast from "@/hooks/useToast";
import DropArea from "@/components/custom-components/upload-csv/DropArea";
import Uploads from "@/components/custom-components/upload-csv/Uploads";
import ModalHeader from "@/components/custom-components/upload-csv/ModalHeader";
import CommentArea from "./CommentArea";
import { AppDispatch } from "@/state/store";
import Loader from "@/components/common/Loader";
import ConfirmationDialog from "@/components/common/ConfirmationBoxForFileOverwrite";

type ManageUploadsModalProps = {
  onClose: () => void;
  isOpen: boolean;
  portfolioId: string;
};
const ManageUploadsModal: React.FC<ManageUploadsModalProps> = ({
  onClose,
  isOpen,
  portfolioId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [csvList, setCsvList] = useState([]);
  const [file, setFile] = useState<File | null>(null);
  const [isUpload, setIsUpload] = useState<boolean>(true);
  const [fileComment, setFileComment] = useState<string>("");
  const [updatedFileName, setUpdatedFileName] = useState<string>("");
  const [isRequestResolved, setIsRequestResolved] = useState<boolean>(false);
  const [loaderTextState, setLoaderTextState] = useState("");
  const [isSingleUpdate, setIsSingleUpdate] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [overloadedFilesList, setOverloadedFilesList] = useState<string[]>([]);
  const { error, info, success } = useToast();
  const handleCancel = () => {
    setFile(null);
    setIsUpload(true);
    onClose();
  };
  const handleUpload = async (e: any) => {
    e.preventDefault();    
    if (file && file.length == 0) {
      setTimeout(() => info("Please select/drop CSV file."), 1000);
      return;
    }
    if(isSingleUpdate){
      const newUploadedfileName = file[0]?.path?.replace(/^.*[\\/]/, "");
      if (updatedFileName !== newUploadedfileName){
        setTimeout(() => error(`File name mismatch! Please select a file named ${updatedFileName} to proceed.`), 1000);
        return;
      }
    }

    let overwriteFlag = isSingleUpdate ? "true" : "false";

    if (!isSingleUpdate) {
      const extractedFiles = file.map((uploadedFile: any) =>
        typeof uploadedFile === "string" ? uploadedFile.replace(/^.*[\\/]/, "") : uploadedFile.name
      );

      const duplicates = extractedFiles.filter((fileName: string) =>
        csvList.some((csv: any) => csv.fileName.trim() === fileName.trim())
      );

      if (duplicates.length > 0) {
        setOverloadedFilesList(duplicates);
        setShowConfirmDialog(true);
        return;
      }
    }

    startUpload(overwriteFlag);
  };

  const startUpload = async (overwriteFlag: string) => {
    setIsRequestResolved(true);
    setIsUpload(true);
    setLoaderTextState("upload");

    const batchSize = 3;
    const FilesList = Object.values(file);
    let currentFileIndex = 0;
  
    const uploadFile = async (item: any) => {
      try {
        const action = await dispatch(
          uploadSingleFile({
            pid: portfolioId,
            file: item,
            fileComments: fileComment,
            overwriteExistingFile: overwriteFlag,
          })
        );
  
        if (uploadSingleFile.fulfilled.match(action)) {
          setIsUpload(true);
          success(`File uploaded successful: ${item.name}`);
          return { item, success: true };
        } else {
          error(`Error uploading file: ${item.name}`);
          return { item, success: false, error: action.payload };
        }
      } catch (err: any) {
        console.error(`Error uploading item: ${item.name}`, err);
        return { item, success: false, error: err.message };
      }
    };
  
    const processQueue = async () => {
      const activeUploads = new Set();
  
      while (currentFileIndex < FilesList.length || activeUploads.size > 0) {
        while (activeUploads.size < batchSize && currentFileIndex < FilesList.length) {
          const item = FilesList[currentFileIndex++];
          const uploadPromise = uploadFile(item).finally(() => activeUploads.delete(uploadPromise));
          activeUploads.add(uploadPromise);
          setIsRequestResolved(true);
        }
  
        await Promise.race(activeUploads);
        setIsRequestResolved(true);
      }
  
      try {
        const action = await dispatch(getCsvList({ id: portfolioId }));
        if (getCsvList.fulfilled.match(action)) {
          setIsUpload(true);
          setCsvList(action.payload.data);
        } else {
          error("Error while getting the list of files");
        }
      } catch (err) {
        console.error("Error fetching CSV list:", err);
      } finally {
        setIsRequestResolved(false);
      }
    };
  
    await processQueue();
  };
  
  useEffect(() => {
    if (isOpen) {
      dispatch(getCsvList({ id: portfolioId })).then((action) => {
        if (getCsvList.fulfilled.match(action)) {
          setCsvList(action.payload.data);
        }
        if (getCsvList.rejected.match(action)) {
          error("Error while getting the list of files");
        }
      });
    }
  }, [isOpen, dispatch, portfolioId]);

  useEffect(() => {}, [isRequestResolved, setIsRequestResolved]);

  const handleUploadMore = (singleUpdateValue: boolean, updateFileNameFromList:string='') => {
    setIsSingleUpdate(singleUpdateValue);
    if(updateFileNameFromList !== ''){
      setUpdatedFileName(updateFileNameFromList)
    }
    setIsUpload(false);
    setFile(null);
    setFileComment("");
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-49 my-auto bg-gray-800 bg-opacity-50 ">
      {isRequestResolved ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-900 bg-opacity-60 z-50">
         <Loader message={`File ${loaderTextState} in progress... Please do not refresh the page!`} />
        </div>
      ) : null}
      <div className="bg-white rounded-lg p-6 sm:w-1/2 w-2/3">
        <ModalHeader
          isUpload={isUpload}
          handleUploadMore={handleUploadMore}
          handleCancel={handleCancel}
          isSubmitToPublish={false}
          modalTitle={"Manage Manual Uploads"}
        />
        {/* Showing blank upload scren when user clicks on upload more.  */}
        {!isUpload && <DropArea file={file} isSingleUpdate={isSingleUpdate} setFile={setFile} />}
        {!isUpload && (
          <CommentArea
            fileComment={fileComment}
            setFileComment={setFileComment}
          />
        )}
        {isUpload && <Uploads handleUploadMore={handleUploadMore} csvList={csvList} setCsvList={setCsvList} setIsRequestResolved={setIsRequestResolved} setLoaderTextState={setLoaderTextState} />}
        <div className="flex justify-end">
          {!isUpload && (
            <>
              <div className="mr-2">
                <Button
                  variant="secondary"
                  className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <Button
                onClick={handleUpload}
                className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center ${
                  !file ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!file}
              >
                <img src={submitToPublish} alt="Upload" className="mr-2" />
                {isRequestResolved ? "Uploading..." : "Upload"}
              </Button>
            </>
          )}
        </div>
        <ConfirmationDialog
            open={showConfirmDialog}
            title="Overwrite Existing Files?"
            message={
              <pre className="whitespace-pre-wrap">
                The following files already exist:
                {`\n${overloadedFilesList.join("\n")}\n\nDo you want to overwrite them?`}
              </pre>
            }
            onConfirm={() => {
              setShowConfirmDialog(false);
              startUpload("true");
            }}
            onCancel={() => setShowConfirmDialog(false)}
          />
      </div>
    </div>
  );
};
export default ManageUploadsModal;
