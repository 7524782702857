import Header from "@/components/homepage/Header";
import Navbar from "@/components/homepage/Navbar";
import PortfolioPage from "./PortfolioPage";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  APPLICATION_SUBSCRIPTIONS,
  APPROVER,
  GLOBAL_NO_SUBSCRIPTION,
  PLATFORM_SUBSCRIPTION,
  platformSubscriptionStatus,
  SideNavBarTabs,
  SYSTEM_ADMIN,
  USER_EMAIL,
  USER_ROLES,
} from "@/utils/appConstants";
import FinancialApprover from "./FinancialApproverRequest";
import NewSubscription from "./NewSubscription";
import { getUserDetails } from "@/state/slices/LoginSlice";
import {
  pendingPortfolios,
  getMyPortfolios,
  isLoadingPortfolio,
  getSubscribedPortfolios,
  getMySubscription,
  PortfolioSliceUpdateSelector,
} from "@/state/slices/PortfolioSliceUpdate";
import { AppDispatch } from "@/state/store";
import EmptyScreen from "@/components/common/EmptyScreen";
import Loader from "@/components/common/Loader";
import {
  getTokenFromLocalStorage,
  saveTokenToLocalStorage,
} from "@/components/common/AuthService";
import {
  financialApproverData,
  platformSubscripionStatus,
} from "@/state/slices/SubscriptionSlice";
import ActivateSubscriptionButton from "@/components/admin-page/ActivateSubscriptionButton";
import { getUserRoles } from "@/utils/utils";
import { useNavigate } from "react-router-dom";
import ZohoSalesIQEmbed from "@/components/homepage/ZohoSalesIQEmbed";
import * as Sentry from "@sentry/react";
const HomePage = () => {
 
  const navigate = useNavigate();
  const ZOHO_SALES_IQ_ENABLED = import.meta.env.VITE_ZOHO_SALES_IQ_ENABLED as
    | string
    | undefined;
  const [portfolioDataMapped, setPortfolioDataMapped] = useState([]);
  const [incomingRequestData, setIncomingRequestData] = useState<null | string>(
    null
  );
  const [isApprover, setIsApprover] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(isLoadingPortfolio);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    `${SideNavBarTabs._UN_PUBLISHED}`
  );
  const [isPlatformSubscribed, setIsPlatformSubscribed] = useState<
    boolean | null
  >(null);
  const PortfolioCount = useSelector(PortfolioSliceUpdateSelector);
  const [portfolioCounts, setPortfolioCounts] = useState({
    RapidPortfolio: 0,
    RapidPending: 0,
    AcceleratePortfolio: 0,
    AcceleratePending: 0,
    ElitePortfolio: 0,
    ElitePending: 0,
    PublishedPortfolio: 0,
    UnPublishedPortfolio: 0,
    subscriptionApproval: 0,
  });
  const [onLoadPortFolioCount, setOnLoadPortFolioCount] = useState({
    mySubscriptionCount: 0,
    published: 0,
    unPublished: 0,
    subscriptionApprovals: 0,
  });
  const [subscriptionApprovalList, setSubscriptionApprovalList] = useState([]);
  const updateCounter = () => {
    // get he subscribed portfolios list based on plan type
    dispatch(getSubscribedPortfolios(SideNavBarTabs.ELITE));
    dispatch(getSubscribedPortfolios(SideNavBarTabs.RAPID));
    dispatch(getSubscribedPortfolios(SideNavBarTabs.ACCELERATE));
    // get the pending approvals from the following dispatch list based on plan type
    dispatch(pendingPortfolios(SideNavBarTabs.ELITE));
    dispatch(pendingPortfolios(SideNavBarTabs.RAPID));
    dispatch(pendingPortfolios(SideNavBarTabs.ACCELERATE));
  };
  // This use effect is used only for the
  useEffect(() => {
    if (PortfolioCount) {
      const {
        portfolioRapid,
        pendingRapid,
        portfolioAccelerate,
        pendingAccelerate,
        portfolioElite,
        pendingElite,
        published,
        unPublished,
      } = PortfolioCount;

      if (isPlatformSubscribed) {
        setPortfolioCounts({
          RapidPortfolio: portfolioRapid?.data?.length ?? 0,
          RapidPending: pendingRapid?.length ?? 0,
          AcceleratePortfolio: portfolioAccelerate?.data?.length ?? 0,
          AcceleratePending: pendingAccelerate?.length ?? 0,
          ElitePortfolio: portfolioElite?.data?.length ?? 0,
          ElitePending: pendingElite?.length ?? 0,
          PublishedPortfolio: published?.length ?? 0,
          UnPublishedPortfolio: unPublished?.length ?? 0,
          subscriptionApproval:
            subscriptionApprovalList.rapid?.length +
              subscriptionApprovalList.accelerate?.length +
              subscriptionApprovalList.elite?.length || 0,
        });
      }
    }
  }, [PortfolioCount, isPlatformSubscribed, subscriptionApprovalList]);
  // we use this function to map the data for the portfolios
  const filterMenuData = useCallback(
    (status: string) => {
      setIsLoading(true);
      setIncomingRequestData(status);
      switch (status) {
        // Rapid BLOCK
        case `${APPLICATION_SUBSCRIPTIONS.RAPID_PORTFOLIOS}`:
          dispatch(getSubscribedPortfolios(SideNavBarTabs.RAPID))
            .then((action) => {
              if (getSubscribedPortfolios.fulfilled.match(action)) {
                setPortfolioDataMapped(action.payload.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case `${APPLICATION_SUBSCRIPTIONS.RAPID_PENDING_APPROVALS}`:
          dispatch(pendingPortfolios(SideNavBarTabs.RAPID))
            .then((action) => {
              if (pendingPortfolios.fulfilled.match(action)) {
                setPortfolioDataMapped(action.payload.portfolios);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break;

        // Accelerate BLOCK
        case `${APPLICATION_SUBSCRIPTIONS.ACCELERATE_PORTFOLIOS}`:
          dispatch(getSubscribedPortfolios(SideNavBarTabs.ACCELERATE))
            .then((action) => {
              if (getSubscribedPortfolios.fulfilled.match(action)) {
                setPortfolioDataMapped(action.payload.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case `${APPLICATION_SUBSCRIPTIONS.ACCELERATE_PENDING_APPROVALS}`:
          dispatch(pendingPortfolios(SideNavBarTabs.ACCELERATE))
            .then((action) => {
              if (pendingPortfolios.fulfilled.match(action)) {
                setPortfolioDataMapped(action.payload.portfolios);
              }
              if (pendingPortfolios.rejected.match(action)) {
                setPortfolioDataMapped([]);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break;

        // ELITE BLOCK
        case `${APPLICATION_SUBSCRIPTIONS.ELITE_PORTFOLIOS}`:
          dispatch(getSubscribedPortfolios(SideNavBarTabs.ELITE))
            .then((action) => {
              if (getSubscribedPortfolios.fulfilled.match(action)) {
                setPortfolioDataMapped(action.payload.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case `${APPLICATION_SUBSCRIPTIONS.ELITE_PENDING_APPROVALS}`:
          dispatch(pendingPortfolios(SideNavBarTabs.ELITE))
            .then((action) => {
              if (pendingPortfolios.fulfilled.match(action)) {
                setPortfolioDataMapped(action.payload.portfolios);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        // My Portfolio Block
        case `${APPLICATION_SUBSCRIPTIONS.PUBLISHED}`:
          dispatch(getMyPortfolios())
            .then((action) => {
              if (getMyPortfolios.fulfilled.match(action)) {
                setPortfolioDataMapped(action.payload.data.published);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break; // My Portfolio Block
        case `${APPLICATION_SUBSCRIPTIONS.UN_PUBLISHED}`:
          dispatch(getMyPortfolios())
            .then((action) => {
              if (getMyPortfolios.fulfilled.match(action)) {
                setPortfolioDataMapped(action.payload.data.unpublished);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case `${SideNavBarTabs.MY_SUBSCRIPTIONS}`:
          dispatch(getMySubscription())
            .then((action) => {
              if (getMySubscription.fulfilled.match(action)) {
                setPortfolioDataMapped(action.payload.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case `${GLOBAL_NO_SUBSCRIPTION}`:
          setPortfolioDataMapped([]);
          break;

        default:
          setPortfolioDataMapped([]);
          break;
      }
    },
    [dispatch]
  );

  const getFinantialArroverData =()=>{
    if (getUserRoles().includes(APPROVER)) {
      dispatch(financialApproverData()).then((action) => {
        if (financialApproverData.fulfilled.match(action)) {
          setSubscriptionApprovalList(action.payload.data);
        }
      });
    }
  }
  useEffect(() => {
    if (isPlatformSubscribed) {
      // get he subscribed portfolios list based on plan type
      updateCounter();
    }
    //  financial approval
    if (isPlatformSubscribed && getUserRoles().includes(APPROVER)) {
      getFinantialArroverData();
    }
    // Platform Subscription
    dispatch(platformSubscripionStatus()).then((action: any) => {
      if (platformSubscripionStatus.fulfilled.match(action)) {
        if (
          action?.payload?.data?.status === platformSubscriptionStatus.ACTIVE ||
          action?.payload?.data?.status ===
            platformSubscriptionStatus.IN_TRIAL ||
          action?.payload?.data?.status ===
            platformSubscriptionStatus.NON_RENEWING
        ) {
          setIsPlatformSubscribed(true);
        }
      } else if (platformSubscripionStatus.rejected.match(action)) {
        setIsPlatformSubscribed(false);
        setIncomingRequestData(PLATFORM_SUBSCRIPTION);
        const user_roles = getTokenFromLocalStorage(USER_ROLES);
        const userRole = user_roles ? JSON.parse(user_roles) : [];
        // check if the roles platform subscription is false and if user role includes APPROVER.
        if (isPlatformSubscribed === false && userRole.includes(APPROVER)) {
          setSelectedMenuItem(SideNavBarTabs.INCOMINGREQUESTS);
          setIncomingRequestData(SideNavBarTabs.INCOMINGREQUESTS);
        }
      }
    });
    // this code is used for getting the initial count, wether to show subscription or published or un published
    const fetchDataCount = async () => {
      try {
        const [mySubscription, published] = await Promise.all([
          dispatch(getMySubscription()).unwrap(),
          dispatch(getMyPortfolios()).unwrap(),
        ]);
        setOnLoadPortFolioCount({
          mySubscriptionCount: mySubscription.data.length,
          published: published.data.published.length,
          unPublished: published.data.unpublished.length,
          subscriptionApprovals: 0,
        });
        if (getUserRoles().includes(APPROVER)) {
          try {
            const [subscriptionApprovals] = await Promise.all([
              dispatch(financialApproverData()).unwrap(),
            ]);
            setOnLoadPortFolioCount((prevState) => ({
              ...prevState,
              subscriptionApprovals:
                subscriptionApprovals.rapid?.length +
                subscriptionApprovals.accelerate?.length +
                subscriptionApprovals.elite?.length,
            }));
          } catch (err) {
            console.log(err);
          }
        }
      } catch (error) {
        console.error("Error fetching portfolio data:", error);
      }
    };
    isPlatformSubscribed && fetchDataCount();
  }, [dispatch, isPlatformSubscribed]);

  useEffect(() => {
    const updateMenu = (menuItem: string) => {
      setSelectedMenuItem(menuItem);
      isPlatformSubscribed && filterMenuData(menuItem);
    };
    if (onLoadPortFolioCount.mySubscriptionCount > 0) {
      updateMenu(SideNavBarTabs.MY_SUBSCRIPTIONS);
    } else if (onLoadPortFolioCount.published > 0) {
      updateMenu(SideNavBarTabs.PUBLISHED);
    } else if (onLoadPortFolioCount.unPublished > 0) {
      updateMenu(SideNavBarTabs._UN_PUBLISHED);
    } else {
      updateMenu(SideNavBarTabs._UN_PUBLISHED);
    }
  }, [onLoadPortFolioCount]);

  // used for loader
  useEffect(() => {
    const handler = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [loading]);

  // used for mapping the for the sid bar
  useEffect(() => {
    // this is executed only if the selected menu item is income request and platform subscription is false
    selectedMenuItem === SideNavBarTabs.INCOMINGREQUESTS &&
      !isPlatformSubscribed &&
      filterMenuData(selectedMenuItem);
    // this is executed when the platform subscription is true
    isPlatformSubscribed && filterMenuData(selectedMenuItem);
  }, [selectedMenuItem, filterMenuData, isPlatformSubscribed]);

  // get the user details
  const userDetails = async () => {
    await dispatch(getUserDetails())
      .then((action) => {
        if (getUserDetails.fulfilled.match(action)) {
          const userRolesArray = action?.payload?.data?.roles;
          saveTokenToLocalStorage(USER_ROLES, JSON.stringify(userRolesArray));
          const isApproverRole = userRolesArray.includes(APPROVER);
          setIsApprover(isApproverRole);
          const isAdmin = JSON.parse(
            getTokenFromLocalStorage(USER_ROLES) as string
          )?.includes(SYSTEM_ADMIN);
          if (isAdmin) {
            navigate("/admin");
          }
          setIsAdmin(isAdmin);
        } else if (getUserDetails.rejected.match(action)) {
          console.log(action.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // no dependency for this uesEffect as we are calling user details
  useEffect(() => {
    userDetails();    
    const useremail = getTokenFromLocalStorage(USER_EMAIL);
    if (useremail) {
      Sentry.setUser({ id: useremail, email: useremail });
    }
    return () => {
      setPortfolioDataMapped([]);
    };
  }, []);

  const isValidPortfolioData =
    Object.values(APPLICATION_SUBSCRIPTIONS).includes(incomingRequestData) &&
    portfolioDataMapped &&
    Array.isArray(portfolioDataMapped);

  return (
    <div className="flex h-screen overflow-hidden scrollbar-custom">
      <Navbar
        isApprover={isApprover}
        isAdmin={isAdmin}
        setSelectedMenuItem={setSelectedMenuItem}
        selectedMenuItem={selectedMenuItem}
        portfolioCounts={portfolioCounts}
        isPlatformSubscribed={isPlatformSubscribed}
        getFinantialArroverData={getFinantialArroverData}
      />
      <div className="w-full overflow-auto scrollbar-custom">
        <Header
          setIncomingRequestData={setIncomingRequestData}
          setSelectedMenuItem={setSelectedMenuItem}
          filterMenuData={filterMenuData}
          isPlatformSubscribed={isPlatformSubscribed}
        />
        {isPlatformSubscribed === false && isApprover && (
          <ActivateSubscriptionButton
            setIsLoading={setIsLoading}
            etSubscriptionApprovalList={setSubscriptionApprovalList}
            subscriptionApprovalList={subscriptionApprovalList}
            setIsPlatformSubscribed={setIsPlatformSubscribed}
          />
        )}
        {incomingRequestData === SideNavBarTabs.INCOMINGREQUESTS &&
          isPlatformSubscribed && (
            <FinancialApprover
              isPlatformSubscribed={isPlatformSubscribed}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              subscriptionApprovalList={subscriptionApprovalList}
              setSubscriptionApprovalList={setSubscriptionApprovalList}
              updateCounter={updateCounter}
            />
          )}
        {incomingRequestData === SideNavBarTabs.SUBSCRIBE && (
          <NewSubscription setIsLoading={setIsLoading} isLoading={isLoading}     updateCounter={updateCounter}/>
        )}
        {isLoading && incomingRequestData !== SideNavBarTabs.SUBSCRIBE ? (
          <Loader />
        ) : isValidPortfolioData && portfolioDataMapped?.length > 0 ? (
          <PortfolioPage
            portfolio={portfolioDataMapped}
            requestName={incomingRequestData}
            setSelectedMenuItem={setSelectedMenuItem}
            updateCounter={updateCounter}
            incomingRequestData={incomingRequestData}
          />
        ) : (
          incomingRequestData !== SideNavBarTabs.SUBSCRIBE &&
          incomingRequestData !== SideNavBarTabs.INCOMINGREQUESTS && (
            <EmptyScreen incomingRequest={incomingRequestData} />
          )
        )}
      </div>
      {ZOHO_SALES_IQ_ENABLED === "true" && (
        <>
          <ZohoSalesIQEmbed />
        </>
      )}
 
    </div>
  );
};

export default HomePage;
