import React from "react";
import { useDispatch } from "react-redux";
import { unSubscribe } from "@/state/slices/PortfolioSlice";
import { useNavigate } from "react-router-dom";
import useToast from "@/hooks/useToast";
import { CONFIRMBOX_TYPE } from "@/utils/appConstants";
import { AppDispatch } from "@/state/store";
type ConfirmationBoxProps = {
  isConfirmModelOpen?: boolean;
  portfolioId?: string;
  setIsUnsubscribeConfirmModelOpen?: any;
  requestType?: string;
  headerText?: string;
  confirmBoxMsg?: string;
};

const ConfirmationBox = ({
  isConfirmModelOpen,
  portfolioId,
  setIsUnsubscribeConfirmModelOpen,
  requestType,
  headerText,
  confirmBoxMsg,
}: ConfirmationBoxProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { success } = useToast();
  const handleConfirmation = () => {
    if (requestType == CONFIRMBOX_TYPE.UNSUBSCRIBE) {
      const payload = {
        requestType: "subscribe_portfolio",
        portfolioId: portfolioId,
      };
      dispatch(unSubscribe(payload))
        .then((action: any) => {
          if (unSubscribe.fulfilled.match(action)) {
            success(action.payload.data.message);
            navigate("/"); // Navigate after resetting the state
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };
  const handleCancelModel = () => {
    setIsUnsubscribeConfirmModelOpen?.(false);
  };

  return (
    <>
      {isConfirmModelOpen && (
        <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 sm:w-1/3 w-1/3 l:w-1/3 w-1/3">
            <h2 className="text-lg font-semibold ml-4 mb-4">{headerText}</h2>
            <div className="p-4">
              <div className="mb-4 text-center">
                <p>{confirmBoxMsg}</p>
              </div>
              <div className="flex justify-center items-center gap-4">
                <button
                  className="mr-2 px-4 py-2 border border-gray-300 rounded-md flex-1 max-w-24 mt-5 hover:bg-gray-100"
                  onClick={handleCancelModel}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded-md  flex-1 max-w-24 mt-5 hover:bg-red-600"
                  onClick={handleConfirmation}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationBox;
