import { SYSTEM_ADMIN } from '@/utils/appConstants';
import { getUserRoles } from '@/utils/utils';
import React from 'react';

const Notfound: React.FC<{ isBadRoute?: boolean }> = ({ isBadRoute }) => {
  const userRoles = getUserRoles();
  const userRoleData = userRoles.includes(SYSTEM_ADMIN) ? "/admin" : "/dashboard";
  return (
    <div className="flex h-screen items-center justify-center bg-white">

      <div className="text-center animate-fade-in">
        <h1 className="text-9xl font-extrabold text-sky-600">{!isBadRoute ? "503":"404"}</h1>
        <p className="text-3xl  mt-2 font-semibold">{!isBadRoute ? "Service Unavailable" : ''}</p>
        <p className="text-2xl mt-4 ">
          {!isBadRoute ? "The server is temporarily unavailable. Please check back again shortly." : "Page Not Found"}
          {/* { "Please check your internet connection" } */}
        </p>
        <a
          href={userRoleData}
          className="mt-6 inline-block px-6 py-3 bg-sky-600 text-white text-lg rounded-full shadow-lg transition-transform transform hover:scale-105"
        >
          {/* { "Refresh" } */}
          {!isBadRoute ? "Refresh" : "Go back"}
        </a>
      </div>
    </div>
  );
};

export default Notfound;
