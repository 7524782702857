import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import instance from "@/utils/axiosConfig";
import { RootState } from "@/state/store";
const API_PATH = import.meta.env.VITE_API_CORE_PATH;
interface PortfolioState {
  data: any | null;
  loading: boolean;
  error: string | null | undefined;
  embed: string;
}

const initialState: PortfolioState = {
  data: null,
  loading: false,
  error: null,
  embed: "",
};

export const getAllPortfolios = createAsyncThunk(
  "/api/portfolios",
  async () => {
    try {
      const response = await instance.get(`${API_PATH}/portfolios/`);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

// Creating a new portfolio.
export const createPortfolioPost = createAsyncThunk(
  "/api/core/portfolios",
  async ({ data }: { data: any }) => {
    const formData = new FormData();
    formData.append("portfolioName", data?.portfolioName);
    formData.append("description", data?.description);
    formData.append("productCode", data?.selectProduct);
    formData.append('isDataConnectionRequested', data?.dataConnector ?? "false");
    formData.append('dataConnectionComments', data?.comments ?? "");
    formData.append('isFileUploadRequested',data?.fileUploadCheck ?? "false");
    // Handle file uploads
    if (data?.file && Array.isArray(data.file)) {
      data.file.forEach((file: File) => {
        formData.append("files", file);
      });
    }
    try {
      const response = await instance.post(`${API_PATH}/portfolios`, formData);
      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.message[0].includes("already exists")) {
        throw error?.response?.data?.message[0];
      }
      throw error.response ? error.message : error.response.data;
    }
  }
);

export const unSubscribe = createAsyncThunk(
  "Unsubscribe",
  async (data: any) => {
    try {
      const response = await instance.post(
        `${API_PATH}/subscriptions/unsubscribe/`,
        data
      );
      return response.data;
    } catch (error: any) {
      throw error.response
        ? error?.response?.data?.message[0]
        : "Error while unsubscribing the plan";
    }
  }
);

export const createEmbedPost = createAsyncThunk(
  "Post Embed Html",
  async ({ id, data }: { id: string; data: any }) => {
    try {
      const response = await instance.patch(
        `${API_PATH}/portfolios/${id}/embed-html`,
        data
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const getPortfolioEmbedHtml = createAsyncThunk(
  "get portfolio embed data",
  async ({ id }: { id: string }) => {
    try {
      const response = await instance.get(
        `${API_PATH}/portfolios/${id}/embed-html`
      );
      return response.data.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const getPortfolioDetails = createAsyncThunk(
  "get portfolio details",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await instance.get(`${API_PATH}/portfolios/${id}`);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue({
        message: error.response?.data?.message[0] || "Unknown error occurred",
      });
    }
  }
);

export const deletePortfolio = createAsyncThunk(
  "delete portfolio details",
  async ({ id }: { id: string }) => {
    try {
      const response = await instance.delete(`${API_PATH}/portfolios/${id}`);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const updatePortfolioAttributes = createAsyncThunk(
  "update portfolio attributes",
  async ({ id, data }: { id: string | number; data: any }) => {
    try {
      const response = await instance.put(`${API_PATH}/portfolios/${id}`, data);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const renamePortfolio = createAsyncThunk(
  "rename portfolio",
  async ({ id, data }: { id: string; data: any }) => {
    try {
      const response = await instance.patch(
        `${API_PATH}/portfolios/${id}/name`,
        data
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.message : error.response.data;
    }
  }
);

export const publishPortfolio = createAsyncThunk(
  "Publish Portfolio",
  async ({ id }: { id: string }) => {
    try {
      const response = await instance.put(
        `${API_PATH}/portfolios/${id}/publish`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const publishDraftDashboard = createAsyncThunk(
  "Publish Draft Dashboard",
  async ({ id }: { id: string }) => {
    try {
      const response = await instance.post(
        `${API_PATH}/portfolios/${id}/publish-draft`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const publishDashboard = createAsyncThunk(
  "Publish dashboard",
  async ({ id }: { id: string }) => {
    try {
      const response = await instance.put(
        `${API_PATH}/dashboards/${id}/publish`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

// export const getPendingSubscriptions = createAsyncThunk(
//   "get Peding Subscriptions",
//   async () => {
//     try {
//       const response = await instance.get(`${API_PATH}/subscriptions/pending`);
//       return response.data.data;
//     } catch (error: any) {
//       throw error.response ? error.response.data : error.message;
//     }
//   }
// );

const PortfoliosSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPortfolios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPortfolios.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllPortfolios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(createPortfolioPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPortfolioPost.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createPortfolioPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(updatePortfolioAttributes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePortfolioAttributes.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updatePortfolioAttributes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(renamePortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(renamePortfolio.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(renamePortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(getPortfolioDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPortfolioDetails.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPortfolioDetails.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          //@ts-ignore
          state.error = action.payload.message;
        } else {
          state.error = action.error.message || 'Unknown error occurred';
        }


      });

    builder
      .addCase(deletePortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePortfolio.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deletePortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(publishPortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(publishPortfolio.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(publishPortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(publishDashboard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(publishDashboard.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(publishDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default PortfoliosSlice.reducer;
export const PortfolioSelector = (state: RootState) => state?.portfolio?.data;
export const PortfolioStatus = (state: RootState) => state?.portfolio;
