import { useState } from "react";

import UploadDashboardModal from "@/components/createportfolio/CreatePortfolioModal";

import { searchIcon } from "../../assets/icons-color/index";
import { Button } from "@/components/ui/Button";
import {
  APPLICATION_SUBSCRIPTIONS,
  SideNavBarTabs, CREATE_NEW, PAGES_NAME, SUBSCRIBER
} from "@/utils/appConstants";
interface HeaderProps {
  setIncomingRequestData: any;
  setSelectedMenuItem: any;
  filterMenuData: (status: string) => void;
  isPlatformSubscribed: boolean | null;
}
export type ErrorObjectType = {
  portfolioName: string;
  description: string;
  file: File | null;
  selectProduct: string;
  dataConnector: boolean;
  comments: string;
  fileUploadCheck: boolean;
};

import useToast from "@/hooks/useToast";
import { pendingPortfolios } from "@/state/slices/PortfolioSliceUpdate";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/state/store";
import { createPortfolioPost } from "@/state/slices/PortfolioSlice";
const Header = ({ setSelectedMenuItem, filterMenuData, isPlatformSubscribed }: HeaderProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isRequestResolved, setIsRequestResolved] = useState<boolean | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { success, error, info } = useToast();
  const [planType, setPlanType] = useState("");
  const [APIError, setApiError] = useState({
    portfolioName: "",
    description: "",
    file: null,
    selectProduct: "",
    dataConnector: false,
    comments: "",
    fileUploadCheck: false,
  });

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };
  const [isUploadDashboardModalOpen, setIsUploadDashboardModalOpen] =
    useState(false);
  function handleModal(type: string) {
    setPlanType(type);
    setIsUploadDashboardModalOpen(true);
  }

  const handleUpdatePortfolioUrl = async (
    e: any,
    _url: any,
    callback?: any
  ) => {
    try {
      info("Creating Portfolio...");
      e.preventDefault();
      setIsRequestResolved(true);
      const { planType, ...payload } = _url;
      await dispatch(createPortfolioPost({ data: payload })).then(
        (action: any) => {
          if (createPortfolioPost.fulfilled.match(action)) {
            setIsUploadDashboardModalOpen(false);
            dispatch(pendingPortfolios(planType));
            filterMenuData(APPLICATION_SUBSCRIPTIONS.UN_PUBLISHED);
            setTimeout(() => {
              success("Portfolio creation successful.");
            }, 1000);
            setSelectedMenuItem(APPLICATION_SUBSCRIPTIONS.UN_PUBLISHED);
            setApiError((prev:ErrorObjectType) => ({
              ...prev,
              portfolioName: "",
              description: "",
              file: null,
              selectProduct: "",
              dataConnector: false,
              comments: "",
              fileUploadCheck: false,
            }));
            callback();
          }
          if (createPortfolioPost.rejected.match(action)) {
            setIsUploadDashboardModalOpen(true);
            setApiError((prev) => ({
              ...prev,
              portfolioName: "Portfolio name already exists",
            }));
            error(action.error.message ?? "Error while uploading the file");
          }
        }
      );
    } catch (err: any) {
      setIsUploadDashboardModalOpen(true);
      error("Unable to create portfolio");
    } finally {
      setIsRequestResolved(false);
    }
  };

  const handleOnClickSubscribe = () => {
    setSelectedMenuItem(SideNavBarTabs.SUBSCRIBE);
  };
  return (
    <div className="p-4 flex items-center justify-between h-[76px] gap-3  w-full">
      {/* search bar below */}
      <div className="flex items-center bg-white text-black rounded-md p-2 w-full border-gray">
        <img src={searchIcon} alt="Search" className="w-6 h-6 mr-3" />
        <input
          type="text"
          placeholder="Search all portfolios"
          value={searchTerm}
          onChange={handleSearchChange}
          className="flex-grow outline-none text-black !important"
        />
      </div>
      <div>
        <Button
          variant="default"
          className={
            !isPlatformSubscribed
              ? "bg-[#CBD4EF] hover:bg-[#CBD4EF] cursor-no-drop"
              : "bg-[#26AB49] hover:bg-[#288a42]"
          }
          //  className='bg-[#26AB49] hover:bg-[#288a42]'
          disabled
          onClick={
            isPlatformSubscribed ? () => handleOnClickSubscribe() : undefined
          }
        >
          {SUBSCRIBER}
        </Button>
      </div>

      <Button
        variant="default"
        onClick={
          isPlatformSubscribed
            ? () => handleModal(SideNavBarTabs.ELITE)
            : undefined
        }
        className={
          !isPlatformSubscribed
            ? "cursor-no-drop"
            : ""
        }
      >
        {CREATE_NEW}
      </Button>
      <UploadDashboardModal
        isOpen={isUploadDashboardModalOpen}
        onClose={() => setIsUploadDashboardModalOpen(false)}
        onSubmit={handleUpdatePortfolioUrl}
        page={PAGES_NAME.DASHBOARD}
        planType={planType}
        APIError={APIError}
        isRequestResolved={isRequestResolved}
        setApiError={setApiError}
      />
    </div>
  );
};

export default Header;
