import { getTokenFromLocalStorage } from "@/components/common/AuthService";
import { useEffect } from "react";

// Extend the Window interface
declare global {
  interface Window {
    $zoho?: {
      salesiq?: {
        visitor?: {
          name: (name: string) => void;
          email: (email: string) => void;
          info: (info: { [key: string]: string }) => void;
        };
        ready?: () => void;
      };
    };
  }
}

const ZohoSalesIQEmbed = () => {
  useEffect(() => {
    const ZOHO_SALES_IQ_URL = import.meta.env.VITE_ZOHO_SALES_IQ_URL as string;
    if (ZOHO_SALES_IQ_URL) {
      // Create script element
      const script = document.createElement("script");
      script.id = "zsiqscript";
      script.src = (ZOHO_SALES_IQ_URL);      
      script.defer = true;
      script.async = true;

      // Append script to body
      document.body.appendChild(script);

      // Zoho setup code
      window.$zoho = window.$zoho || {};
      window.$zoho.salesiq = window.$zoho.salesiq || {
        ready: function () {},
      };

      return () => {
        // Remove script when component unmounts
        document.body.removeChild(script);
      };
    }
  }, []);

  useEffect(() => {
    const userEmail = getTokenFromLocalStorage("userEmail") || "";
    const userName = getTokenFromLocalStorage("userName") || "Guest";
    const domain = userEmail.includes("@") ? userEmail.split("@")[1] : "";

    let attempts = 0;
    const maxAttempts = 5;

    const initializeZoho = () => {
      if (
        window.$zoho &&
        window.$zoho.salesiq &&
        window.$zoho.salesiq.visitor
      ) {
        window.$zoho.salesiq.visitor.name(userName);
        window.$zoho.salesiq.visitor.email(userEmail);
        window.$zoho.salesiq.visitor.info({
          "User ID": userEmail,
          Domain: domain,
        });
        //console.log("Zoho SalesIQ initialized.");
      } else if (attempts < maxAttempts) {
        attempts += 1;
        //console.log(`Zoho SalesIQ not ready, retrying (${attempts})...`);
        setTimeout(initializeZoho, 1000); // Retry after 1 second
      } else {
        console.warn(
          "Failed to initialize Zoho SalesIQ after multiple attempts."
        );
      }
    };
    initializeZoho();
  }, []);
 
  /* Display the zoho sales iq chat bot if it is hidden. */
  useEffect(() => {
    const zohoWidget = document.querySelector("div[data-id='zsalesiq']") as HTMLElement | null;
    if (zohoWidget) {
      zohoWidget.style.display = "block";
    }
  }, [])

  return null;
};

export default ZohoSalesIQEmbed;
