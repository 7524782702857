import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  saveTokenToLocalStorage,
  getTokenFromLocalStorage,
} from "@/components/common/AuthService";
import {
  ACCESS_TOKEN,
  ENABLED_TWO_FA,
  REFRESH_TOKEN,
  SYSTEM_ADMIN,
  USER_EMAIL,
  USER_NAME,
  AUTH_MTHOD,
  USER_ROLES,
} from "@/utils/appConstants";
import instance from "@/utils/axiosConfig";
import { getUserRoles } from "@/utils/utils";
import Loader from "@/components/common/Loader";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
const LoginPage = (props:any) => {
  const navigate = useNavigate();
  const savedAcccessToken = getTokenFromLocalStorage(ACCESS_TOKEN);
  const refreshToken = getTokenFromLocalStorage(REFRESH_TOKEN);

  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const API_PATH = import.meta.env.VITE_API_CORE_PATH;
  const BASE_AUTH_URL = import.meta.env.VITE_BASE_AUTH_URL;
  const API_AUTH_PATH = import.meta.env.VITE_API_AUTH_PATH;
  const location = useLocation();
  const TENANTS_URL = import.meta.env.VITE_TENANTS_URL;
  let userRoles = getUserRoles();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const getToken = params.get("token");
    authenticateToken(getToken);
    if(!getToken) {
      if (savedAcccessToken && refreshToken) {
        const decodedToken = jwtDecode(savedAcccessToken);
    
        const expirationTime = decodedToken.exp! * 1000;
        const now = new Date().getTime();
        const timeUntilExpiration = expirationTime - now;

        const decodedRefreshToken = jwtDecode(refreshToken);
        const refreshTokenExpirationTime = decodedRefreshToken.exp! * 1000;
        const timeNow = new Date().getTime();
        const refreshTokenValidTill = refreshTokenExpirationTime - timeNow;

        // If access token time expired and refresh token time not expired
        if (timeUntilExpiration < 0 && refreshTokenValidTill > 0) {
          refreshTokenCall();
        }
        // Refresh token expired
        if(refreshTokenValidTill<0) {
          window.location.href = TENANTS_URL;
        }
        userRoles.includes(SYSTEM_ADMIN) ? navigate("/admin") : navigate("/dashboard");
      } else {
        window.location.href = TENANTS_URL;
      }
    }
  }, [location]);

  const refreshTokenCall = async () => {
    const response = await axios.post(
      `${BASE_AUTH_URL}${API_AUTH_PATH}/refresh-token`,
      { refreshToken: refreshToken }
    );
    const { accessToken, newRefreshToken } = response?.data?.data;
    if (accessToken && newRefreshToken) {
      saveTokenToLocalStorage(ACCESS_TOKEN, accessToken);
      saveTokenToLocalStorage(REFRESH_TOKEN, newRefreshToken);
    }
  }

  const authenticateToken = async (sessionToken: any) => {
    if (sessionToken) {
      try {
       props.setLoading(true);
       const response = await instance.post(
         `${BASE_AUTH_URL}${API_AUTH_PATH}/token-exchange`,
         { session_token: sessionToken }
       );
       saveTokenToLocalStorage(
           ACCESS_TOKEN,
           response?.data?.data?.accessToken
       );
       saveTokenToLocalStorage(
           REFRESH_TOKEN,
           response?.data?.data?.refreshToken
       );
       saveTokenToLocalStorage(
        ENABLED_TWO_FA,
        response?.data?.data?.mfaEnabled ?? true
      );
      saveTokenToLocalStorage(
        AUTH_MTHOD,
        response?.data?.data?.authMethod
      );
       // Saving User Details in local storage.
       try {
           const response = await instance.get(
           `${BASE_URL}${API_PATH}/userDetails`
           );
           saveTokenToLocalStorage(
               USER_NAME,
               response?.data?.data?.firstName + ' ' + response?.data?.data?.lastName
             );
             saveTokenToLocalStorage(
               USER_EMAIL,
               response?.data?.data?.email
             );
             saveTokenToLocalStorage(USER_ROLES, JSON.stringify(response?.data?.data?.roles))
           props.setLoading(false);
           userRoles = getUserRoles();
           userRoles.includes(SYSTEM_ADMIN) ? navigate("/admin") : navigate("/dashboard");
       } catch (error) {
          console.error('Error fetching user details:', error);
       }
     } catch (error: any) {
       props.setLoading(false);
       if(error?.response.data.response.subStatus === 'ORGANIZATION_MISMATCH')
        window.location.href = TENANTS_URL + `?logout=yes&orgError=yes`;
       else
       window.location.href = TENANTS_URL + `?logout=yes&orgError=no`;
       throw error?.response ? error.response?.data?.message[0] : error.message;
     }
   }
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
       <Loader />
    </div>
  );
};

export default LoginPage;
