import { Card, CardContent } from "../ui/Card";
import {
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
} from "@/components/common/AuthService";
import { useStytchB2BClient } from "@stytch/react/b2b";
import {
  USER_NAME,
  ENABLED_TWO_FA,
  AUTH_MTHOD,
  SYSTEM_ADMIN,
} from "@/utils/appConstants";
import {
  createMonogram,
  getUserRoles,
  redirectToTenants,
  truncatedString,
} from "@/utils/utils";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/Dropdown-menu";
import { Button } from "../ui/Button";
import { ChevronDown } from "lucide-react";
import { useDispatch } from "react-redux";
import { LogoutCall } from "@/state/slices/LogoutSlice";
import { AppDispatch } from "@/state/store";
import useToast from "@/hooks/useToast";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/Tooltip";
const TENANTS_URL = import.meta.env.VITE_TENANTS_URL;
const Logout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const stytch = useStytchB2BClient();
  const { success } = useToast();
  function handleLogout() {
    // Revoke Stytch session if it exists
    if (stytch?.session?.getSync()) {
      stytch.session.revoke();
    }

    if (getUserRoles().includes(SYSTEM_ADMIN)) {
      redirectToTenants();
      success("You have successfully logged out");
    } else {
      dispatch(LogoutCall()).finally(redirectToTenants);
    }
  }

  const userName = getTokenFromLocalStorage(USER_NAME) ?? "";
  function enable2FA() {
    removeTokenFromLocalStorage([
      "Roles",
      "accessToken",
      "refreshToken",
      "userEmail",
      "userName",
    ]);
    window.location.href = TENANTS_URL + "setup-2fa";
  }
  function change2FA() {
    removeTokenFromLocalStorage([
      "Roles",
      "accessToken",
      "refreshToken",
      "userEmail",
      "userName",
    ]);
    window.location.href = TENANTS_URL + "change-2fa-number";
  }
  function disable2FA() {
    //removeTokenFromLocalStorage(['Roles', 'accessToken', 'refreshToken', 'userEmail', 'userName'])
    window.location.href = TENANTS_URL + "disable-2fa";
  }
  const is2FAEnabled: string | null = getTokenFromLocalStorage(ENABLED_TWO_FA);
  const authMethod: string | null = getTokenFromLocalStorage(AUTH_MTHOD);
  return (
    <Card className=" mb-5 border-grey-900 ">
      <CardContent className="p-3">
        <div className="flex flex-start align-center ml-1 gap-2">
          {userName && (
            <div className="bg-blue-600 rounded-full  p-2">
              <span className="text-white text-lg uppercase mx-1 ">
                {createMonogram(userName)}
              </span>
            </div>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger className="w-full flex flex-row flex-wrap justify-between items-center">
              <Button className="bg-transparent text-black hover:bg-transparent font-bold text-lg">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      {<span>{truncatedString(userName, 16)}</span>}
                    </TooltipTrigger>
                    <TooltipContent className="text-gray-600 font-normal text-sm mb-2">
                      <strong>{userName}</strong>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Button>
              <div>
                {/* <ChevronUp className="h-4 w-4 opacity-85" /> */}
                <ChevronDown className="h-4 w-4 opacity-85" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuGroup>
                {is2FAEnabled === "false" &&
                  (authMethod == "magic_link" ||
                    authMethod == "sso" ||
                    authMethod == "password") && (
                    <>
                      <DropdownMenuItem
                        className="text-md"
                        onClick={() => enable2FA()}
                      >
                        Enable 2FA
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                    </>
                  )}
                {is2FAEnabled === "true" && (
                  <>
                    <DropdownMenuItem
                      className="text-md "
                      onClick={() => change2FA()}
                    >
                      Change 2FA Number
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      className="text-md text-cursor"
                      onClick={() => disable2FA()}
                    >
                      Disable 2FA
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                  </>
                )}
                <DropdownMenuItem
                  className="text-md"
                  onClick={() => handleLogout()}
                >
                  Logout
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardContent>
    </Card>
  );
};

export default Logout;
