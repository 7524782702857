import { infoInfo } from "@/assets/icons";
import { EMPTY_SCREENS_NAME, SideNavBarTabs } from "@/utils/appConstants";
// used for add HTML in a string
import DOMPurify from "dompurify";

type EmptyScreenProps = {
  incomingRequest: string | null;
};
const EmptyScreen = ({ incomingRequest }: EmptyScreenProps) => {
  const data = EMPTY_SCREENS_NAME.find(
    (item) =>
      item.name ===
      (incomingRequest === "" ? SideNavBarTabs.MY_PORTFOLIOS : incomingRequest)
  );
  const cleanHeaderHtml: string = data?.header
    ? DOMPurify.sanitize(data.header)
    : "";
  const cleanSubHeaderHtml: string = data?.sub_header
    ? DOMPurify.sanitize(data.sub_header)
    : "";
  return (
    <div
      style={{
        height: "80%",
        alignContent: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div className="centered-div">
        <div className="">
          {cleanHeaderHtml !== "" && (
            <img src={infoInfo} alt="question icon" className="w-10 h-10" />
          )}
        </div>
      </div>
      <div className="centered-div mt-3">
        <span
          className="text-lg font-semibold"
          dangerouslySetInnerHTML={{ __html: cleanHeaderHtml }}
        ></span>
      </div>
      <div className="centered-div mb-5">
        <span
          className="font-medium text-base"
          dangerouslySetInnerHTML={{ __html: cleanSubHeaderHtml }}
        ></span>
      </div>
    </div>
  );
};
export default EmptyScreen;
