import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "@/utils/axiosConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const API_PATH = import.meta.env.VITE_API_CORE_PATH;

export const financialApproverData = createAsyncThunk(
  "Financial Approver List",
  async () => {
    try {
      const response = await instance.get(
        `${API_PATH}/subscriptions/finance/pending`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.message : error.response.data;
    }
  }
);
export const getSubscribeList = createAsyncThunk(
  "Get Subscribe Resource List",
  async () => {
    try {
      const response = await instance.get(
        `${API_PATH}/portfolios`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.message : error.response.data;
    }
  }
);

export const approveData = createAsyncThunk(
  "Approved Status",
  async ({ id, payload }: { id: any; payload: any }) => {
    try {
      const response = await instance.put(
        `${API_PATH}/subscriptions/request/${id}/approve-deny`, payload
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.message : error.response.data;
    }
  }
);


export const submitApproval = createAsyncThunk(
  "Submit for Approval",
  async ({ payload }: { payload: any }) => {
    try {
      const response = await instance.post(
        `${API_PATH}/subscriptions/submit-for-approval`, payload
      );
      return response.data.data;
    } catch (error: any) {
      throw error.response ? error?.response?.status : "Not Found";
    }
  }
);

export const platformSubscription = createAsyncThunk(
  "Submit for platform subscription",
  async (payload: any) => {
    try {
      const response = await instance.post(
        `${API_PATH}/subscriptions/platform`,
        payload
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error?.response?.status : "Not Found";
    }
  }
);

export const platformSubscripionStatus = createAsyncThunk(
  "Platform Subscription Status",
  async () => {
    try {
      const response = await instance.get(
        `${API_PATH}/subscriptions/platform/chargebee-status`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error?.response?.status : "Not Found";
    }
  }
);

const SubscriptionSlice = createSlice({
  name: "Approved_status",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(approveData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(approveData.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

   
    builder
      .addCase(submitApproval.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitApproval.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(submitApproval.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    builder
      .addCase(financialApproverData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(financialApproverData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(financialApproverData.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
    builder
      .addCase(getSubscribeList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubscribeList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getSubscribeList.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
    builder
      .addCase(platformSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(platformSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(platformSubscription.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
    builder
      .addCase(platformSubscripionStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(platformSubscripionStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(platformSubscripionStatus.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

export default SubscriptionSlice.reducer;