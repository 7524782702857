import instance from "@/utils/axiosConfig";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
const API_PATH = import.meta.env.VITE_API_CORE_PATH;

type dataProps = {
    _id: string,
    frequency: string,
    portfolioId?: string,
    timesPerDay: string,
    timezone: string,
    timeOfDay: string,
    runSchedule?: string,
    daysOfMonth?: [],
    daysOfWeek?: [],
    createdAt?: string,
    updatedAt?: string,
}
type initialStateProps = {
    data: dataProps;
    loading: boolean;
    error: string | null | undefined;
};
const initialState: initialStateProps = {
    data: {
        _id: "",
        frequency: "",
        portfolioId: "",
        timesPerDay: "",
        timezone: "",
        timeOfDay: "",
        runSchedule: "",
        daysOfMonth: [],
        daysOfWeek: [],
        createdAt: "",
        updatedAt: "",
    },
    loading: false,
    error: null,
};

export const PostAppReload = createAsyncThunk(
    "portfolios/reload-schedule/{id}",
    async ({ id, data }: { id: string; data: any }) => {
        try {
            const response = await instance.post(
                `${API_PATH}/portfolios/${id}/reload-schedule`,
                data
            );
            return response.data;
        } catch (error: any) {
            throw error.response ? error.response.data : error.message;
        }
    }
);
export const GetAppReload = createAsyncThunk(
    "portfolios/get-schedule/{id}",
    // core/portfolios/reload-schedule/677fb32f4810d55fc2b18de5
    async ({ id }: { id: string }) => {
        try {
            const response = await instance.get(
                `${API_PATH}/portfolios/${id}/reload-schedule`
            );
            return response.data;
        } catch (error: any) {
            throw error.response ? error.response.data : error.message;
        }
    }
);
const AppReloadSlice = createSlice({
    name: "Post operation App Reload",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(PostAppReload.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(PostAppReload.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(PostAppReload.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
        builder
            .addCase(GetAppReload.pending, (state) => {
                state.loading = true;
                state.error = null;
            }).addCase(GetAppReload.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(GetAppReload.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default AppReloadSlice.reducer;
export const AppReloadSliceData = (state: RootState) => state.appReload;
// export const DownloadCSVSliceSelector = (state:RootState)=> state.
