import { PortfolioProps } from "@/utils/utils";
import PortfolioCard from "./PortfolioCard";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/Dropdown-menu";
import { FilterIcon, SortArrows } from "@/assets/icons";
import { PortfolioSortItemProps } from "@/screens/PortfolioPage";
import { Button } from "../ui/Button";
import { useLayoutEffect, useState } from "react";
import ManageUploadsModal from "../custom-components/upload-csv/ManageUploadsModal";
import EmptyScreen from "../common/EmptyScreen";
import { ADMIN_PAGE_NAVBAR } from "@/utils/appConstants";
import Loader from "../common/Loader";

// select highlight sort

const PortfolioPage = ({
  portfolios,
  selectedMenuItem,
  portfolioSortList,
  sortButton,
  selectedSort,
  heightLighted,
  FilterData,
  isLoading,selectedFilter
}: any) => {
  const [portfolioIdForContextMenu, setPortfolioIdForContextMenu] =
    useState("");
  const [isManageUploadModalOpen, setIsManageUploadModalOpen] = useState(false);
  useLayoutEffect(() => {
    const body = document.body;
    body.style.overflow = isManageUploadModalOpen ? "hidden" : "visible";
  }, [isManageUploadModalOpen]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="ml-4 mr-4">
          <div className="mb-5 flex flex-row justify-end gap-3">
            {/* filter Section Start*/}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  <img src={FilterIcon} alt="sort" width="20px" height="20px" />
                  &nbsp; Filter
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="p-3">
                <DropdownMenuGroup>
                  {FilterData.map((data) => {
                    return (
                      <DropdownMenuGroup key={data.id}>
                        <div
                          className={`flex flex-col justify-between gap-1 px-1 ${data.onclick ? "cursor-pointer text-red-500" : ""}`}
                          onClick={data.onclick ? data.onclick : () => {}}
                        >
                          <span
                            className={`font-bold  ${
                              data.name === "Product Name"
                            } ? cursor-pointer : '' `}
                          >
                            {data.name}
                          </span>
                          <div className="gap-1 flex flex-col justify-between ">
                            {data.children?.map((child) => {
                              return (
                                <span
                                  key={child.id}
                                  className={`p-1 border-0 bg-transparent hover:bg-blue-50 cursor-pointer ${selectedFilter == child.name
                            ? " text-blue-600 font-bold"
                            : ""}`}
                                  onClick={child.onclick}
                                >
                                  &nbsp;&nbsp;{child.name}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </DropdownMenuGroup>
                    );
                  })}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
            {/* filter Section Ends */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  <img
                    src={SortArrows}
                    alt="sort"
                    width="20px"
                    height="20px"
                    style={{ transform: "rotate(180deg)" }}
                  />
                  &nbsp; Sort
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="p-3">
                <DropdownMenuGroup>
                  {portfolioSortList.map((item: PortfolioSortItemProps) => {
                    return (
                      <div
                        className={`flex flex-row items-center justify-between gap-4 px-1 ${
                          selectedSort == item.name
                            ? " text-blue-600 font-bold"
                            : ""
                        }`}
                        key={item.id}
                      >
                        <span>{item.name}</span>
                        <div className="gap-2">
                          <Button
                            name={item.name + "_asc"}
                            onClick={() => item.asc_onClick(item.name + "_asc")}
                            className={`border-0 bg-transparent items-center mr-1 hover:bg-blue-50 ${
                              sortButton === item.name + "_asc"
                                ? "bg-blue-100"
                                : ""
                            }`}
                          >
                            <img
                              src={item.asc_icon}
                              alt="sort"
                              width="20px"
                              height="20px"
                            />
                          </Button>
                          <Button
                            name={item.name + "_desc"}
                            onClick={() =>
                              item.des_onClick(item.name + "_desc")
                            }
                            className={`border-0 bg-transparent items-center hover:bg-blue-50 ${
                              sortButton === item.name + "_desc"
                                ? "bg-blue-100"
                                : ""
                            }`}
                          >
                            <img
                              src={item.des_icon}
                              alt="sort"
                              width="20px"
                              height="20px"
                            />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          {/* Sorting Section End */}
          {/* Sorting Section Start */}
          {portfolios && portfolios?.length > 0 ? (
            portfolios?.map((portfolio: PortfolioProps) => {
              return (
                <div key={portfolio._id}>
                  <PortfolioCard
                    portfolio={portfolio}
                    selectedMenuItem={selectedMenuItem}
                    setPortfolioIdForContextMenu={setPortfolioIdForContextMenu}
                    setIsManageUploadModalOpen={setIsManageUploadModalOpen}
                    heightLighted={heightLighted}
                  />
                </div>
              );
            })
          ) : (
            <EmptyScreen incomingRequest={ADMIN_PAGE_NAVBAR.ADMIN_PAGE} />
          )}
          <ManageUploadsModal
            isOpen={isManageUploadModalOpen}
            portfolioId={portfolioIdForContextMenu}
            onClose={() => setIsManageUploadModalOpen(!isManageUploadModalOpen)}
          />
        </div>
      )}
    </>
  );
};

export default PortfolioPage;
