import Header from "@/components/admin-page/Header";
import Navbar from "@/components/admin-page/Navbar";
import PortfolioPage from "@/components/admin-page/PortfolioPage";
import {
  AdminPageSelector,
  AllPortfolios,
  getAdminAllPortfolios,
} from "@/state/slices/AdminPageSlice";
import { AppDispatch, RootState } from "@/state/store";
import {
  ADMIN_PAGE_NAVBAR,
  PUBLISHED,
  SORTINGDATA,
  READY_FOR_MAPPING,
  READY_FOR_REVIEW_STATUS,
  UNPUBLISHED,
  VIEW_DASHBOARD,
  VIEW_DRAFT_DASHBOARD,
} from "@/utils/appConstants";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterPortfolios, sortPortfolios } from "@/utils/utils";
import {
  Sort_a_z,
  Sort_z_a,
  SortCalendarDown,
  SortCalendarUp,
} from "@/assets/icons/sortIcons";
import { PortfolioItem } from "./PortfolioPage";

const AdminPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [portfolios, setPortfolios] = useState<
    PortfolioItem[] | Record<string, any>[]
  >();
  const [isLoading, setIsLoading] = useState(true);
  const portfolioData = useSelector((state: RootState) =>
    AdminPageSelector(state)
  );
  const [selectedSort, setSelectedSort] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [sortButton, setSortButton] = useState<string>("");
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    `${ADMIN_PAGE_NAVBAR.PORTFOLIOS}`
  );
  const [portfoliosAll, setPortfoliosAll] = useState([]);
  const allPortfolios = useSelector((state: RootState) => AllPortfolios(state));
  const [heightLighted, setHeightLighted] = useState<string>("");
  useEffect(() => {
    if (portfolioData) {
      selectedMenuItem === "Portfolios"
        ? setPortfolios(portfolioData?.allPortfolios.data)
        : setPortfolios(portfolioData?.portfolios);
    }
  }, [selectedMenuItem]);

  // used for loader
  useEffect(() => {
    const handler = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [portfolios]);
  const filterMenuData = useCallback((status: string) => {
    setIsLoading(true);
    switch (status) {
      // Portfolio BLOCK
      case `${ADMIN_PAGE_NAVBAR.PORTFOLIOS}`:
        dispatch(getAdminAllPortfolios())
          .then((action) => {
            if (getAdminAllPortfolios.fulfilled.match(action)) {
              setPortfoliosAll(action.payload?.data);
              setPortfolios(action.payload?.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;

      default:
        setPortfolios([]);
        break;
    }
  }, []);
  useEffect(() => {
    filterMenuData(selectedMenuItem);
  }, [selectedMenuItem]);
  const handleSearch = (searchTerm: string) => {
    setIsLoading(true);
    setHeightLighted(searchTerm);

    const lowerCaseTerm = searchTerm.toLowerCase();
    let filtered = [];

    const getFilteredPortfolios = (portfolios: any) =>
      portfolios.filter(
        (portfolio: any) =>
          portfolio.portfolioName?.toLowerCase().includes(lowerCaseTerm) ||
          portfolio.status?.toLowerCase().includes(lowerCaseTerm) ||
          portfolio.owners.some(
            (owner: any) =>
              owner.firstName?.toLowerCase().includes(lowerCaseTerm) ||
              owner.lastName?.toLowerCase().includes(lowerCaseTerm)
          )
      );

    if (selectedMenuItem === "Portfolios") {
      filtered = getFilteredPortfolios(portfoliosAll);
    }

    if (filtered.length !== allPortfolios.data.length) {
      setPortfolios(filtered);
    } else {
      filterMenuData(selectedMenuItem);
    }
    return;
  };
  const portfolioSortList = [
    {
      id: 1,
      name: "Portfolio Name",
      asc_icon: Sort_a_z,
      des_icon: Sort_z_a,
      asc_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(
            portfolios as PortfolioItem[],
            SORTINGDATA.portfolio.asc
          )
        );
        setSelectedSort(SORTINGDATA.portfolio.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(
            portfolios as PortfolioItem[],
            SORTINGDATA.portfolio.desc
          )
        );
        setSelectedSort(SORTINGDATA.portfolio.name);
        setSortButton(btnName);
      },
    },
    {
      id: 2,
      name: "Product Name",
      asc_icon: Sort_a_z,
      des_icon: Sort_z_a,
      asc_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(
            portfolios as PortfolioItem[],
            SORTINGDATA.productNames.asc
          )
        );
        setSelectedSort(SORTINGDATA.productNames.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(
            portfolios as PortfolioItem[],
            SORTINGDATA.productNames.desc
          )
        );
        setSelectedSort(SORTINGDATA.productNames.name);
        setSortButton(btnName);
      },
    },
    {
      id: 3,
      name: "Creator",
      asc_icon: Sort_a_z,
      des_icon: Sort_z_a,
      asc_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(portfolios as PortfolioItem[], SORTINGDATA.creator.asc)
        );
        setSelectedSort(SORTINGDATA.creator.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(
            portfolios as PortfolioItem[],
            SORTINGDATA.creator.desc
          )
        );
        setSelectedSort(SORTINGDATA.creator.name);
        setSortButton(btnName);
      },
    },
    {
      id: 4,
      name: "Status",
      asc_icon: Sort_a_z,
      des_icon: Sort_z_a,
      asc_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(portfolios as PortfolioItem[], SORTINGDATA.status.asc)
        );
        setSelectedSort(SORTINGDATA.status.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(portfolios as PortfolioItem[], SORTINGDATA.status.desc)
        );
        setSelectedSort(SORTINGDATA.status.name);
        setSortButton(btnName);
      },
    },
    {
      id: 6,
      name: "Updated Date",
      asc_icon: SortCalendarUp,
      des_icon: SortCalendarDown,
      asc_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(
            portfolios as PortfolioItem[],
            SORTINGDATA.updatedDate.asc
          )
        );
        setSelectedSort(SORTINGDATA.updatedDate.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName: string) => {
        setPortfolios(
          sortPortfolios(
            portfolios as PortfolioItem[],
            SORTINGDATA.updatedDate.desc
          )
        );
        setSelectedSort(SORTINGDATA.updatedDate.name);
        setSortButton(btnName);
      },
    },
  ];

  const FilterData = [
    {
      id: 1,
      name: "Product Name",
    },
    {
      id: 2,
      name: "Status",
      children: [
        {
          id: 2.1,
          name: PUBLISHED,
          onclick: () => {
            setPortfolios(
              filterPortfolios(portfoliosAll as PortfolioItem[], PUBLISHED)
            );
            setSelectedFilter(PUBLISHED);
          },
        },
        {
          id: 2.2,
          name: UNPUBLISHED,
          onclick: () => {
            setPortfolios(
              filterPortfolios(portfoliosAll as PortfolioItem[], UNPUBLISHED)
            );
            setSelectedFilter(UNPUBLISHED);
          },
        },
        {
          id: 2.3,
          name: READY_FOR_MAPPING,
          onclick: () => {
            setPortfolios(
              filterPortfolios(
                portfoliosAll as PortfolioItem[],
                READY_FOR_MAPPING
              )
            );
            setSelectedFilter(READY_FOR_MAPPING);
          },
        },
        {
          id: 2.4,
          name: READY_FOR_REVIEW_STATUS,
          onclick: () => {
            setPortfolios(
              filterPortfolios(
                portfoliosAll as PortfolioItem[],
                READY_FOR_REVIEW_STATUS
              )
            );
            setSelectedFilter(READY_FOR_REVIEW_STATUS);
          },
        },
      ],
    },
    {
      id: 3,
      name: "View",
      children: [
        {
          id: 3.1,
          name: VIEW_DASHBOARD,
          onclick: () => {
            setPortfolios(
              filterPortfolios(
                portfoliosAll as PortfolioItem[],
                VIEW_DASHBOARD
              )
            );
            setSelectedFilter(VIEW_DASHBOARD);
          },
        },
        {
          id: 3.2,
          name: VIEW_DRAFT_DASHBOARD,
          onclick: () => {
            setPortfolios(
              filterPortfolios(
                portfoliosAll as PortfolioItem[],
                VIEW_DRAFT_DASHBOARD
              )
            );
            setSelectedFilter(VIEW_DRAFT_DASHBOARD);
          },
        },
      ],
    },
    {
      id: 4,
      name: "Clear Filter",
      onclick: () => {
        setPortfolios(
          filterPortfolios(portfoliosAll as PortfolioItem[], "Clear Filter")
        );
        setSelectedFilter("");
      },
    },
  ];

  return (
    <div className="flex h-screen align-middle">
      <Navbar
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
      />
      <div className="w-full border-l-2 border-gray-100">
        <Header onSearch={handleSearch} />

        <PortfolioPage
          portfolios={portfolios}
          selectedMenuItem={selectedMenuItem}
          portfolioSortList={portfolioSortList}
          selectedSort={selectedSort}
          selectedFilter={selectedFilter}
          sortButton={sortButton}
          heightLighted={heightLighted}
          FilterData={FilterData}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default AdminPage;
