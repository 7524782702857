import { useState } from "react";
import { Button } from "../ui/Button";
import { Textarea } from "../ui/Textarea";

type CommentBoxProps = {
  heading: string;
  onClose: (status: boolean) => void;
  onSubmit: (comment: string) => void;
  placeholder: string;
};

const CommentBox = ({ heading, onClose, onSubmit,placeholder}: CommentBoxProps) => {
  const [comment, setComment] = useState("");

  const handleCancel = () => {
    onClose(false);
  };

  const handleSubmit = () => {
    onSubmit(comment);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg">
        <h2 className="text-lg font-semibold mb-4">{heading}</h2>

        <Textarea
          id="comment"
          placeholder={placeholder}
          value={comment}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setComment(e.target.value)
          }
          className="mb-4"
        />

        <div className="flex justify-end gap-4">
          <Button
            variant="outline"
            className="px-4 py-2"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="default"
            className="px-4 py-2"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CommentBox;
