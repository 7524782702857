import { close } from "@/assets/icons/index";

type DialogBoxProps = {
  headerText?: string;
  DialogBoxMsg?: string;
  setIsDialogBox?: (value: boolean) => void;
  setDialogBoxMsg?: (value: string) => void;
};

const DialogBox = ({
  headerText,
  DialogBoxMsg,
  setIsDialogBox,
  setDialogBoxMsg,
}: DialogBoxProps) => {
  const handleCancelModel = () => {
    setIsDialogBox?.(false);
    setDialogBoxMsg?.("");
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className=" pb-[80px] bg-white rounded-lg p-6 sm:w-1/3 w-1/2 h-96 overflow-hidden">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">{headerText}</h2>
          <button className="text-gray-500" onClick={handleCancelModel}>
            <img src={close} className="w-6 h-6" alt="close" />
          </button>
        </div>
        <div className="p-4 h-full overflow-auto scrollbar-custom">
          <div className="mb-4">
            <span className="block break-words">{DialogBoxMsg}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;
