import { Button } from "@/components/ui/Button";
import { Attribute } from "@/components/createportfolio/Attribute";
import { Plus } from "@/assets/icons/";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import { closeSmallIcon } from "@/assets/icons/createportfolio";
import Header from "@/components/createportfolio/Header";
import { useEffect, useRef, useState } from "react";
import HierarchyChart from "@/layouts/hierarchy-chart/HierarchyChart";
import EmailModal from "@/components/custom-components/EmailModal";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  addAttributeLevel,
  checkAttributeIsEmpty,
  checkAttributesName,
  convertEdgeToPayload,
  createNodePayload,
  editModeUpdatePah,
  generateUuid,
  getUserRoles,
  truncatedString,
  updatePahAfterSaveAttributeInEditMode,
} from "@/utils/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { generateEdges, generateNodes } from "@/utils/utils";
import {
  getAllAttributes,
  updateAttributes,
} from "@/state/slices/AttributeListSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/state/store";
import { ConstantsItem } from "@/constants/nodes";
// importing google analytics script here
// import { loadGoogleAnalytics } from "../seo/analytics.ts";
import {
  createEmbedPost,
  getPortfolioDetails,
  updatePortfolioAttributes,
} from "@/state/slices/PortfolioSlice";
import UploadCsvModal from "@/components/custom-components/upload-csv/UploadCsvModal";
import UploadDashboardModal, {
  FormData,
} from "@/components/createportfolio/CreatePortfolioModal";
import useToast from "@/hooks/useToast";
import { AppDispatch } from "@/state/store";
import {
  VIEW_MODE,
  EDIT_MODE,
  ATTRIBUTES_ALLOWED_SPECIAL_CHARS_REG_EXP,
  PAGES_NAME,
  PORTFOLIO_PERMISSIONS,
  SYSTEM_ADMIN,
} from "@/utils/appConstants";
import {
  updateHierarchy,
  AddHierarchy,
  getAllHierarchyData,
  deleteHierarchy,
  renameHierarchy,
  resetHierarchyData,
  getPortfolioHierarchyDatabyId,
  updateAllHierarchyOnEdit,
} from "@/state/slices/HierarchySlice";
import TabMenuPopUp from "@/components/createportfolio/TabMenuPopUp";
import {
  ATTRIBUTE_CANT_BE_EMPTY,
  ATTRIBUTE_NAME_EXIST_MSG,
  ATTRIBUTE_NAME_REQUIRED_MSG,
} from "@/utils/messageConstants";
import { useReactFlow } from "reactflow";
import { toast } from "sonner";
import * as Sentry from "@sentry/react";

const CreatePortfolio = () => {
  const { id } = useParams();
  const [portfolioId, setPortfolioId] = useState<string>(id || "");
  const [portfolioName, setPortfolioName] = useState("Untitled Portfolio Name");
  const [mode, setMode] = useState(id ? VIEW_MODE : "");
  const { success, error, info } = useToast();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const attributeList =
    useSelector((state: RootState) => state.attributeList.value) || [];

  const reduxTabsData =
    useSelector((state: RootState) => state.hierarchy.data) || [];
  const isError = useSelector((state: RootState) => state.hierarchy.error);
  const [attributes, setAttributes] = useState<ConstantsItem[]>(attributeList);
  const [currentState, setCurrentState] = useState("defaultAttributes");
  const [tabsData, setTabsData] = useState(reduxTabsData);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const [isQuickStart, setIsQuickStart] = useState<boolean>(true);
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [isRenameModelOpen, setIsRenameModelOpen] = useState(false);
  /* Upload File code start */
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  /* Upload File code end  */
  const [emptyErrors, setEmptyErrors] = useState<{ [key: string]: string }>({});
  /* Undo And Redo State Declaration. */
  const [redoStack, setRedoStack] = useState<ConstantsItem[][]>([]);
  const [undoStack, setUndoStack] = useState<ConstantsItem[][]>([attributes]);
  const [isUploadDashboardModalOpen, setIsUploadDashboardModalOpen] =
    useState(false);
  const [tabId, setTabId] = useState("");
  const [tabName, setTabName] = useState("");
  const [selectedTab, setSelectedTab] = useState("");
  const [attributeSaveButton, setAttributeSaveButton] = useState(false);
  const [tabHistory, setTabHistory] = useState<string[]>([]);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [deboucningValue, setDebouncingValue] = useState<number>(1);
  const [savingState, setSavingState] = useState("");
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isFormDirty, setIsFormDirty] = useState(false); // Track if form data is unsaved
  // Detects in-app navigation (PUSH, REPLACE, or POP)

  const handleTabChange = (value: string) => {
    if (savingState) {
      setSavingState("");
    }
    setSelectedTab((prevSelectedTab) => value);
    setTabHistory((prevHistory) => {
      const newHistory = [...prevHistory.filter((id) => id !== value), value];
      return newHistory;
    });
  };

  const handleDelete = async (id: string) => {
    const res = await dispatch(deleteHierarchy({ hierarchyId: id }));
    if (res.payload) {
      success("Tab deleted");
      // Remove the deleted tab from the tabsData
      const updatedTabsData = tabsData.filter(
        (tab: any) => tab.hierarchyId !== id
      );
      // Update the selected tab and tab history
      if (id === selectedTab) {
        // The deleted tab is currently selected
        const newTabHistory = tabHistory.filter((tabId) => tabId !== id);
        setTabHistory(newTabHistory);
        // Select the most recently selected tab
        if (newTabHistory.length > 0) {
          setSelectedTab(newTabHistory[newTabHistory.length - 1]);
        } else if (updatedTabsData.length > 0) {
          // If no history, select the first tab from the remaining tabs
          setSelectedTab(updatedTabsData[0].hierarchyId);
        } else {
          setSelectedTab("");
        }
      } else {
        // The deleted tab is not currently selected
        setTabHistory((prevHistory) =>
          prevHistory.filter((tabId) => tabId !== id)
        );
      }

      // Update the tabsData state
      await dispatch(getAllHierarchyData());
    } else {
      error("Something went wrong, please try again.");
    }

    setIsDeleteModelOpen(!isDeleteModelOpen);
  };

  const handleDeleteModel = (id?: string) => {
    if (tabsData.length > 1) {
      id ? setTabId(id) : setTabId("");
      setIsDeleteModelOpen(!isDeleteModelOpen);
    }
  };

// TODO: Below block of code should be remvoed when we are going to QA/Prod
/* {
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    // On first load, trigger an error to Sentry
    if (!isInitialized) {
      console.log("Loading Sentry");
      setIsInitialized(true);
      // Trigger a custom error to Sentry
      Sentry.captureException(new Error('Test Error'));
      //throw new Error('Througing new errors from sentry.');
    }
  }, [isInitialized]);
} */

 /* Hidding the zoho sales iq chat bot */
  useEffect(() => {
    const zohoWidget = document.querySelector("div[data-id='zsalesiq']") as HTMLElement | null;
    if (zohoWidget) {
      zohoWidget.style.display = "none";
    }
  }, []);

  /* This is a special scenario where we we need to check the mode of the portfolio and updated the selected tab
   this is because as we update the redux state on edit mode to enable south and east buttons the initial value is giving the data new data
   to avoid this confusion we are rendering the data conditionally with setTimeout   */

  useEffect(() => {
    if (mode === "") {
      dispatch(getAllHierarchyData());
      setSelectedTab((prev) => tabsData[0]?.hierarchyId);
      setTabHistory([tabsData[0]?.hierarchyId]);
    } else if (mode === EDIT_MODE || mode === VIEW_MODE) {
      setTabHistory([tabsData[0]?.hierarchyId]);
      setTimeout(() => {
        setSelectedTab((prev) => tabsData[0]?.hierarchyId);
      }, 200);
    }
  }, [mode]);

  const handleRename = async (id: string, tabName: string) => {
    const res = await dispatch(
      renameHierarchy({ hierarchyId: id, hierarchyName: tabName })
    );
    if (res.payload) {
      success("Tab renamed");
      await dispatch(getAllHierarchyData());
    } else {
      error("Something went wrong, please try again.");
    }
    setIsRenameModelOpen(!isRenameModelOpen);
  };
  const handleRenameModel = (id?: string, tabName?: string) => {
    tabName ? setTabName(tabName) : setTabName("");
    id ? setTabId(id) : setTabId("");
    setIsRenameModelOpen(!isRenameModelOpen);
  };
  const handleEmailModel = () => {
    setIsEmailModalOpen(!isEmailModalOpen);
  };
  let userPermissionHandler: string[] = [];
  const fetchPortfolioDetails = async () => {
    await dispatch(getPortfolioDetails({ id: portfolioId })).then(
      (action: any) => {
        if (getPortfolioDetails.fulfilled.match(action)) {
          userPermissionHandler = action?.payload?.permissions;
          action?.payload?.attributes
            ? dispatch(updateAttributes(action?.payload?.attributes))
            : dispatch(getAllAttributes());

          action?.payload?.attributes
            ? setAttributes(action?.payload?.attributes)
            : setAttributes(attributeList);
          setPortfolioName(action?.payload?.portfolioName);
          // setPortfolioId(action?.payload?._id);
          const isEditPortfolio = action?.payload?.permissions.includes(
            PORTFOLIO_PERMISSIONS.EIDT
          );
          if (isEditPortfolio) {
            setIsEditAccess(true);
          }
        } else if (getPortfolioDetails.rejected.match(action)) {
          //@ts-ignore
          error(action?.payload.message);
          const userRoles = getUserRoles();
          if (userRoles.includes(SYSTEM_ADMIN)) {
            navigate("/admin");
          } else {
            navigate("/dashboard");
          }
          return;
        }
      }
    );

    await dispatch(getPortfolioHierarchyDatabyId({ id: portfolioId })).then(
      (action: any) => {
        if (getPortfolioHierarchyDatabyId.fulfilled.match(action)) {
          if (action?.payload[0]?.nodes?.length > 0) {
            setIsSaved(!isSaved);
            setIsQuickStart(false);
          } else {
            if (userPermissionHandler?.includes(PORTFOLIO_PERMISSIONS.EIDT)) {
              setMode("");
            } else {
              setMode(VIEW_MODE);
              setIsSaved(!isSaved);
            }
          }
        } else if (getPortfolioHierarchyDatabyId.rejected.match(action)) {
          console.log("error");
        }
      }
    );
  };

  useEffect(() => {
    portfolioId && fetchPortfolioDetails();
    setSelectedTab((prev) => tabsData[0]?.hierarchyId);
  }, [portfolioId]);

  useEffect(() => {
    if (reduxTabsData) {
      setTabsData(reduxTabsData);
      setDebouncingValue((prev) => prev + 1);
    }
  }, [reduxTabsData]);

  useEffect(() => {
    dispatch(getAllHierarchyData());
    dispatch(getAllAttributes());
    return () => {
      dispatch(resetHierarchyData());
      dispatch(getAllAttributes());
      setSelectedTab(() => "");
    };
    // this is how we call call this funtion to run the script --force
    // loadGoogleAnalytics();
  }, []);

  useEffect(() => {
    if (mode !== VIEW_MODE) {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
      setIsFormDirty(true);

      saveTimeoutRef.current = setTimeout(() => {
        if (tabsData[0].nodes.length) {
          handleSaveHierarchy(null, true, true);
        }
        saveTimeoutRef.current = null; // Clear reference after execution
        setIsFormDirty(false);
      }, 4000);

      return () => {
        if (saveTimeoutRef.current) {
          clearTimeout(saveTimeoutRef.current);
        }
      };
    }
  }, [deboucningValue]);

  const handleDeleteAttribute = (id: string) => {
    const newAttributes = attributes.filter(
      (item: any) => item.attributeId !== id
    );
    setAttributes(newAttributes);
    dispatch(updateAttributes(newAttributes));
    success("Attribute deleted");
    setUndoStack((prevStack) => [...prevStack, newAttributes]);
  };
  const { setNodes, setEdges } = useReactFlow();
  const quickStartFn = async (id: string) => {
    const index = tabsData.findIndex((item: any) => item.hierarchyId === id);
    const hierarchyName = tabsData[index]?.hierarchyName;
    let newTabTitle: string = "";
    let initialNodes;
    let initialEdges;
    if (hierarchyName) {
      newTabTitle = hierarchyName;
    } else {
      newTabTitle = `Untitled`;
    }
    if (tabsData[index].nodes.length > 0) {
      // initialNodes = generateNodes(attributes, id);
      // initialEdges = generateEdges(initialNodes);
      initialNodes = tabsData[index].nodes;
      initialEdges = tabsData[index].edges;
      setNodes(initialNodes);
      setEdges(initialEdges);
    } else {
      initialNodes = generateNodes(attributes, id);
      initialEdges = generateEdges(initialNodes);
    }

    await dispatch(
      updateHierarchy({
        nodes: initialNodes,
        edges: initialEdges,
        hierarchyId: id,
        hierarchyName: newTabTitle,
      })
    );

    if (mode === EDIT_MODE) {
      setNodes(initialNodes);
      setEdges(initialEdges);
    }
    await dispatch(getAllHierarchyData());
    setIsQuickStart(false);
  };

  const handleAddTab = async () => {
    const newId = generateUuid();
    const tabLength = tabsData.length;
    let tabName = tabLength === 0 ? "Untitled" : `Untitled (${tabLength})`;
    if (attributes.some(attribute => attribute.attributeName?.trim() === "")) {
      error(ATTRIBUTE_CANT_BE_EMPTY)
      return undefined
    } else {
      const initialNodes = generateNodes(attributes, newId);
      const initialEdges = generateEdges(generateNodes(attributes, newId));
      const res = await dispatch(
        AddHierarchy({
          hierarchyId: newId,
          hierarchyName: `${tabName}`,
          nodes: initialNodes,
          edges: initialEdges,
        })
      );
      if (res.payload) {
        setSelectedTab((prev) => newId);
        setTabHistory((prevHistory) => [...prevHistory, newId]);
        await dispatch(getAllHierarchyData());
      } else {
        error("Something went wrong, please try again.");
      }
    }
  };
  const handleAttributesSave = async (e: any, _ID = "") => {
    info(` Saving Portfolio ${_ID ? "" : "attributes"}...`);
    e.preventDefault();
    // Gather attribute values from the text boxes
    let isEmptyAttribute = false;
    let isNotValidAttribute = false;
    let isDuplicateAttribute = false;
    let isAllAttributesSaved = false;
    const updatedAttributes = attributes.map((item, index) => {
      // getting input text elements by using attribute id.
      const inputElement = document.getElementById(
        item.attributeId
      ) as HTMLInputElement | null;

      // Check if inputElement exists and it is an HTMLInputElement
      if (inputElement instanceof HTMLInputElement) {
        // Ensure that the input element is ready and available
        const inputValue = inputElement.value.trim(); // Trim whitespace
        if (inputValue == "") {
          error("Attribute name cannot be empty");
          isEmptyAttribute = true;
          setEmptyErrors((prevErrors) => ({
            ...prevErrors,
            [item.attributeId]: ATTRIBUTE_NAME_REQUIRED_MSG,
          }));
        }
        if (
          inputValue.trim() &&
          !ATTRIBUTES_ALLOWED_SPECIAL_CHARS_REG_EXP.test(inputValue)
        ) {
          isNotValidAttribute = true;
        }
        if (checkAttributesName(attributeList, inputValue, index)) {
          isDuplicateAttribute = true;
        }
        if (item.Edit) {
          isAllAttributesSaved = true;
        }
        const itemValues = {
          ...item,
          attributeName: inputValue,
        };
        return itemValues;
      } else {
        // return attributes containig name value
        return item;
      }
    });
    // if attribute name is empty then stopping user
    if (isEmptyAttribute || isNotValidAttribute) {
      error("Please ensure that the attribute name has valid characters");
      return;
    }
    if (isDuplicateAttribute) {
      error(ATTRIBUTE_NAME_EXIST_MSG);
      return;
    }
    if (isAllAttributesSaved) {
      error("All attributes must be saved");
      return;
    }
    setAttributes(updatedAttributes);
    // const newValue = useSelector((state: RootState) => state.attributeList.value)
    if (checkAttributeIsEmpty(updatedAttributes)) {
      const attributesData = addAttributeLevel(updatedAttributes);
      const payload = {
        attributes: attributesData,
      };

      dispatch(updateAttributes(updatedAttributes));
      if (mode === EDIT_MODE) {
        if (tabsData.length === 1 && tabsData[0].nodes.length === 0) {
          quickStartFn(tabsData[0].hierarchyId);
        } else {
          const updatePahTreeVal = tabsData?.map((item: any) => {
            const editData = updatePahAfterSaveAttributeInEditMode(
              item.nodes,
              attributeList
            );
            return { ...item, nodes: editData };
          });
          dispatch(updateAllHierarchyOnEdit(updatePahTreeVal));
          dispatch(getAllHierarchyData());
        }
        success("Attributes updated successfully");
      }
      dispatch(
        updatePortfolioAttributes({ id: portfolioId || _ID, data: payload })
      )
        .then((result: any) => {
          setTimeout(() => {
            success(
              `${_ID ? "Portfolio Saved" : "Attributes updated"} successfully`
            );
          }, 1000);
          _ID === "" && setIsSaved(!isSaved);
          // _ID && setPortfolioId(_ID);
        })
        .catch((error: any) => {
          error(`Error while saving ${_ID ? "portfolio" : "attributes"}`);
        });
    }

    mode === "" && quickStartFn(selectedTab);
  };
  const handleSaveHierarchy = (
    e: any,
    draft: boolean = false,
    debounce: boolean = false
  ) => {
    e && e.preventDefault();
    if (isError && !debounce) {
      toast.dismiss();
      toast.error(isError);
      return;
    }

    let isPayloadHasEmptyPah: boolean = false;
    let emptyTabName = "";
    !debounce ? info("Saving Hierarchy...") : setSavingState("Saving...");
    const payloadData =
      Array.isArray(tabsData) &&
      tabsData.length > 0 &&
      tabsData.map((item: any) => {
        if (!item.nodes.length) {
          emptyTabName = item.hierarchyName;
          isPayloadHasEmptyPah = true;
        }

        return {
          hierarchyName: item.hierarchyName,
          hierarchyId: item.hierarchyId,
          nodes: createNodePayload(item.nodes),
          edges: convertEdgeToPayload(item.edges),
        };
      });
    //checking for empty pah if empty then return from the function
    if (isPayloadHasEmptyPah) {
      error(
        `${emptyTabName} does not have a hierarchy. Please build the hierarchy or delete the tab and try again.`
      );
      return;
    }
    dispatch(
      updatePortfolioAttributes({
        id: portfolioId,
        data: { hierarchies: payloadData },
      })
    ).then((action: any) => {
      if (updatePortfolioAttributes.fulfilled.match(action)) {
        !debounce
          ? success("Hierarchy saved successfully")
          : setTimeout(() => {
            setSavingState("");
          }, 300);
        !draft && setIsUploadModalOpen(!isUploadModalOpen);
      } else if (updatePortfolioAttributes.rejected.match(action)) {
        !debounce
          ? error("Error while saving the hierarchy")
          : setSavingState("");
      }
    });
  };

  const handleUploadDashboardModalOpen = () => {
    setIsUploadDashboardModalOpen(!isUploadDashboardModalOpen);
  };

  const hanldeUpdateDashboardUrl = async (e: any, _url: string | FormData) => {
    info("Updating Dashboard URL...");
    e.preventDefault();
    await dispatch(
      createEmbedPost({ id: portfolioId, data: { embedHtml: _url } })
    ).then((action: any) => {
      if (createEmbedPost.fulfilled.match(action)) {
        setTimeout(() => {
          success("Dashboard URL updated successfully");
          handleUploadDashboardModalOpen();
        }, 2000);
      } else if (createEmbedPost.rejected.match(action)) {
        error("Error while updating the Dashboard URL");
      }
    });
  };

  const handleMode = async (e: any, _Mode: string) => {
    e.preventDefault();
    setMode(_Mode);
    // setIsSaved(!isSaved);
    info("Switching to " + _Mode + " mode");
    // we update the data in the redux store on edit mode to enable south and east buttons
    const tabEditData = tabsData?.map((item: any) => {
      const editData = editModeUpdatePah(item.nodes);
      return {
        ...item,
        nodes: editData,
      };
    });
    dispatch(updateAllHierarchyOnEdit(tabEditData));
  };

  /* Redo and Undo functionality */
  const handleUndoAttributes = () => {
    if (undoStack.length > 1) {
      let _currentState = undoStack.pop(); // Pop Present state
      setAttributes(undoStack[undoStack.length - 1]); // Restore previous state
      setRedoStack((prevStack: any) => [...prevStack, _currentState]); // Push current state to redo stack
    }
  };
  const handleRedoAttributes = () => {
    if (redoStack.length > 0) {
      let nextAttributes = redoStack.pop(); // Get next state
      setAttributes(nextAttributes!); // Restore next state
      setUndoStack((prevStack) => [...prevStack, nextAttributes!]); // Push current state to undo stack
    }
  };

  // Handle before unload (for page/tab close)
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isFormDirty) {
        const message = "You have unsaved changes. Are you sure you want to leave this page without saving?";
        event.returnValue = message; // Standard for most browsers
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);
  // Handle in-app navigation blocking
  const handleNavigationBlocking = (e: any) => {
    if (isFormDirty) {
      const confirmLeave = window.confirm("You have unsaved changes. Are you sure you want to leave this page without saving?");
      if (!confirmLeave) {
        e.preventDefault();
      }
    }
  };

  // Attach event listener for blocking navigation
  useEffect(() => {
    window.addEventListener("beforeunload", handleNavigationBlocking);
    return () => {
      window.removeEventListener("beforeunload", handleNavigationBlocking);
    }
  }, [isFormDirty]);

  return (
    <div className="py-2 px-4 w-full h-screen flex flex-col">
      <Header
        handleEmailModel={handleEmailModel}
        handleSaveHierarchy={handleSaveHierarchy}
        portfolioName={portfolioName}
        setPortfolioName={setPortfolioName}
        isEdit={mode}
        //handleUploadDashboardModalOpen={handleUploadDashboardModalOpen}
        isQuickStartEnabled={isQuickStart}
        handleMode={handleMode}
        portfolioId={portfolioId}
        handleUndoAttributes={handleUndoAttributes}
        handleRedoAttributes={handleRedoAttributes}
        undoStack={undoStack}
        redoStack={redoStack}
        isEditAcess={isEditAccess}
        savingState={savingState}
        isSaved={isSaved}
        isFormDirty={isFormDirty}
      />
      <div className="flex flex-1">
        <div
          className={`px-3 mx-3 rounded-[8px] border-[1px] border-[solid] 
          border-[var(--Border-Default,rgba(3,6,18,0.10))] !self-stretch";`}
        >
          {/* Attributes panel calling */}
          <Attribute
            className="!relative !left-[unset] !top-[unset]"
            attributeArray={attributes}
            setAttribute={setAttributes}
            deleteAttribute={handleDeleteAttribute}
            isSaved={isSaved}
            isEdit={mode}
            setUndoStack={setUndoStack}
            attributeErrors={emptyErrors}
            setEmptyErrors={setEmptyErrors}
            setAttributeSaveButton={setAttributeSaveButton}
            setIsSaved={setIsSaved}
            mode={mode}
          />
          <div className="flex items-center justify-center">
            {!isSaved && (
              <Button
                variant="default"
                size="default"
                className="w-[252px] h-[44px] hover:bg-blue-600 bg-[#3F5EFF] px-24 m-3"
                //onClick={() => handleUseDefault()}
                onClick={handleAttributesSave}
                // isLoading is used for disabling the button while saving
                isLoading={attributeSaveButton}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        {/* Right Side Panel Start Here */}
        <div
          className={`flex self-stretch flex-col items-start grow flex-1 relative ${currentState === "editing" ? "overflow-hidden" : ""
            }`}
        >
          <Tabs
            // defaultValue only works for multiple tabs not for single tab
            defaultValue={
              mode === VIEW_MODE ? tabsData[0]?.hierarchyId : undefined
            }
            value={
              mode == "" || mode === EDIT_MODE
                ? selectedTab
                : mode === VIEW_MODE && tabsData.length === 1
                  ? tabsData[0].hierarchyId
                  : undefined
            }
            key={tabsData.length}
            className="w-full h-full flex flex-col"
            onValueChange={(value) => handleTabChange(value)}
          >
            <TabsList className="flex items-center justify-start  w-full  p-0 bg-white shadow-none gap-[4px] ">
              {tabsData &&
                tabsData.length > 0 &&
                tabsData.map((tab: any, index: number) => {
                  return (
                    <div
                      className={`!py-0 border-[1px] flex items-center justify-center  rounded-t-lg border-solid ${selectedTab === tab.hierarchyId
                          ? "border-b-blue-400 text-blue-600"
                          : ""
                        }  p-0.5 border-b-2 `}
                      key={tab.hierarchyId}
                    >
                      <TabsTrigger
                        value={tab.hierarchyId}
                        key={tab.hierarchyId}
                        id={tab.hierarchyId}
                        className={`  !px-0.5  data-[state=active]:text-blue-600  `}
                      >
                        <div
                          className={` font-semibold  p-1`}
                          onDoubleClick={() => {
                            mode !== VIEW_MODE
                              ? tab.hierarchyName &&
                              handleRenameModel(
                                tab.hierarchyId,
                                tab.hierarchyName
                              )
                              : null;
                          }}
                        >
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <span>
                                  {truncatedString(tab.hierarchyName, 12)}
                                </span>
                              </TooltipTrigger>
                              <TooltipContent className="text-gray-500 font-normal text-sm mb-2">
                                <p>{tab.hierarchyName}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </TabsTrigger>
                      <div
                        className="cursor-pointer p-0 "
                        onClick={() => {
                          handleDeleteModel(tab.hierarchyId);
                        }}
                      >
                        {mode !== VIEW_MODE && (
                          <span>
                            <img src={closeSmallIcon} alt="Close" />
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              {mode !== VIEW_MODE && (
                <input
                  type="image"
                  src={Plus}
                  alt="add icon"
                  style={{ opacity: 0.7 }}
                  className={`${isQuickStart ||
                      (tabsData.length > 0 &&
                        tabsData[tabsData.length - 1].nodes.length === 0)
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                    }  ${tabsData.length === 8 && "hidden"} w-5 h-5  `}
                  onClick={() => {
                    if (
                      isQuickStart ||
                      (tabsData.length > 0 &&
                        tabsData[tabsData.length - 1].nodes.length === 0)
                    ) {
                      return null;
                    }
                    handleAddTab();
                  }}
                />
              )}
            </TabsList>
            {tabsData &&
              tabsData?.map((tab: any, index: number) => {
                if (!isSaved && mode !== EDIT_MODE) {
                  return (
                    <TabsContent
                      value={tab.hierarchyId}
                      key={tab.hierarchyId}
                      id={tab.hierarchyId}
                      className="!mt-0 w-full flex-grow border border-grey-50"
                    >
                      {tabsData[0].nodes.length ? (
                        <>
                          <HierarchyChart
                            id={tab.hierarchyId}
                            mode={mode}
                            isQuickStart={isQuickStart}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </TabsContent>
                  );
                } else {
                  return (
                    <TabsContent
                      value={tab.hierarchyId}
                      key={tab.hierarchyId}
                      id={tab.hierarchyId}
                      className="!mt-0 w-full flex-grow border-grey-50"
                    >
                      <HierarchyChart
                        id={tab.hierarchyId}
                        mode={mode}
                        isQuickStart={isQuickStart}
                      />
                    </TabsContent>
                  );
                }
              })}
          </Tabs>
        </div>
      </div>
      <EmailModal
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(!isEmailModalOpen)}
      />
      <UploadCsvModal
        isOpen={isUploadModalOpen}
        portfolioId={portfolioId}
        onClose={() => setIsUploadModalOpen(!isUploadModalOpen)}
      />

      <UploadDashboardModal
        isOpen={isUploadDashboardModalOpen}
        onClose={() => setIsUploadDashboardModalOpen(false)}
        onSubmit={hanldeUpdateDashboardUrl}
        page={PAGES_NAME.CREATE_PORTFOLIO}
        planType=""
      />
      <TabMenuPopUp
        hierarchyId={tabId}
        hierarchyName={tabName}
        handleRename={handleRename}
        handleDelete={handleDelete}
        isRenameModelOpen={isRenameModelOpen}
        handleRenameModel={handleRenameModel}
        isDeleteModelOpen={isDeleteModelOpen}
        handleDeleteModel={handleDeleteModel}
      />
    </div>
  );
};

export default CreatePortfolio;
