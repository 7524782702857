import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardHeader } from "@/components/ui/Card";
import { publishIcon, subscribePublishIcon } from "@/assets/icons";
import {
  approveData,
  financialApproverData,
} from "@/state/slices/SubscriptionSlice";
import {
  APPROVAL_REQUEST_TYPE,
  APPROVER,
  REASONBOX_TYPE,
  RequestStatus,
  SUBSCRIPTION_PLAN_TYPE,
  SideNavBarTabs,
  getProductDisplayName,
} from "@/utils/appConstants";
import useToast from "@/hooks/useToast";
import { AppDispatch } from "@/state/store";
import EmptyScreen from "@/components/common/EmptyScreen";
import ReasonBox from "@/components/common/ReasonBox";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { getUserRoles, truncatedString } from "@/utils/utils";
import DialogBox from "@/components/common/DialogBox";
import { Link } from "react-router-dom"; // Define the type for portfolioList
type PortfolioList = {
  [key: string]: Array<any>; // Adjust 'any' to the specific type of items in the portfolio list
};

export default function FinancialApprover({
  isPlatformSubscribed,
  setIsLoading,
  isLoading,
  subscriptionApprovalList,
  setSubscriptionApprovalList,
  updateCounter
}: any) {
  const dispatch = useDispatch<AppDispatch>();
  const { success, error } = useToast();

  const [portfolioList, setPortfolioList] = useState<PortfolioList>(
    subscriptionApprovalList
  );
  const [isDeny, setIsDeny] = useState(false);
  const [isDialogBox, setIsDialogBox] = useState(false);
  const [dialogBoxMsg, setDialogBoxMsg] = useState("");

  const [selectedTab, setSelectedTab] = useState(SUBSCRIPTION_PLAN_TYPE.RAPID);

  const [selectedItem, setSelectedItem] = useState<{
    item: any;
    reqType: string;
  } | null>(null);
  useEffect(() => {
    setIsLoading(false);
    loadFinancialApproverData();
  }, []);

  const loadFinancialApproverData = () => {
    if (getUserRoles().includes(APPROVER)) {
      dispatch(financialApproverData()).then((action: any) => {
        if (financialApproverData.fulfilled.match(action)) {
          setSubscriptionApprovalList(action.payload.data);
          setPortfolioList(action.payload.data);
          setIsLoading(false);
        } else if (financialApproverData.rejected.match(action)) {
          //@ts-ignore
          error(action?.error?.message);
        }
      });
    }
  };

  const approveOrDenyPayload = (
    param: any,
    approveORDeniedPayLoad: any,
    reason: string
  ) => {
    setIsLoading(true);
    const ApproveOrDenyReq = {
      status: approveORDeniedPayLoad,
      deniedReason: reason,
    };

    dispatch(
      approveData({ id: param.requestId, payload: ApproveOrDenyReq })
    ).then((action: any) => {
      if (action.meta.requestStatus === "fulfilled") {
        success(`Portfolio Request: ${approveORDeniedPayLoad}`);
        loadFinancialApproverData();
        setIsDeny(false);
        setIsLoading(false);
        updateCounter();
      } else {
        loadFinancialApproverData();
        error("Internal server error while approving/denying");
      }
    });
  };

  const handleDeny = (denyParams: any, denyStatus: any) => {
    setIsDeny(true);
    setSelectedItem({ item: denyParams, reqType: denyStatus });
    updateCounter();
  };

  const handleApprove = (approveParams: any, approveStatus: any) => {
    if (approveStatus) {
      approveOrDenyPayload(approveParams, approveStatus, ""); // Fourth parameter for reason message
    }
  };

  const handleTabChange = (value: string) => {
    setSelectedTab(value);
  };
  const callDialogBox = (desc: string) => {
    setDialogBoxMsg(desc);
    setIsDialogBox(true);
  };

  return (
    <>
      {!isLoading &&
        portfolioList &&
        isPlatformSubscribed &&
        Object.keys(portfolioList).length > 0 && (
          <>
            <Tabs
              className="w-full"
              onValueChange={(value) => handleTabChange(value)}
              value={selectedTab}
            >
              <TabsList className="grid w-full grid-cols-3 cursor-pointer">
                {portfolioList &&
                  Object.keys(portfolioList).map((tabName: any) => (
                    <div
                      className={`${
                        selectedTab === tabName
                          ? "border-b-blue-400 text-blue-600"
                          : ""
                      }`}
                      key={tabName}
                    >
                      <TabsTrigger
                        value={tabName}
                        id={tabName}
                        className={`w-[100%] data-[state=active]:text-blue-600`}
                      >
                        <div className={`font-semibold`}>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <span style={{ textTransform: "capitalize" }}>
                                  {truncatedString(tabName, 12)}
                                  {portfolioList[tabName]?.length > 0 && (
                                    <Badge variant="destructive">
                                      {portfolioList[tabName].length}
                                    </Badge>
                                  )}
                                </span>
                              </TooltipTrigger>
                              <TooltipContent className="text-gray-500 font-normal text-sm mb-2">
                                <p style={{ textTransform: "capitalize" }}>
                                  {tabName}
                                  {portfolioList[tabName]?.length > 0 && (
                                    <Badge variant="destructive">
                                      {portfolioList[tabName].length}
                                    </Badge>
                                  )}
                                </p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </TabsTrigger>
                    </div>
                  ))}
              </TabsList>
              {portfolioList &&
                Object.entries(portfolioList).map(([category, items]) => (
                  <TabsContent
                    value={category}
                    key={category}
                    id={category}
                    className="bg-white p-2 rounded-md"
                  >
                    {selectedTab === category &&
                      (items && items.length > 0 ? (
                        items.map((item: any) => (
                          <Card
                            className="w-full mb-4 overflow-y-auto"
                            key={item._id}
                          >
                            <CardHeader className="p-4">
                              <div className="wrapper flex-between">
                                <div className="flex-between">
                                  {item.requestType ===
                                  APPROVAL_REQUEST_TYPE.UPGRADE_PORTFOLIO_SUBSCRIPTION ? (
                                    <img
                                      src={publishIcon}
                                      alt="upgrade portfolio subscription"
                                    />
                                  ) : (
                                    <img
                                      src={subscribePublishIcon}
                                      alt="subscribe portfolio"
                                    />
                                  )}
                                  <span className="ml-1 text-black font-semibold text-sm">{`${item.requesterName}`}</span>
                                  <span className="ml-1">
                                    {item?.requestType ===
                                    APPROVAL_REQUEST_TYPE.UPGRADE_PORTFOLIO_SUBSCRIPTION
                                      ? "would like to upgrade subscription"
                                      : "would like to subscribe"}
                                  </span>
                                  <span className="ml-1">to</span> &nbsp;&nbsp;{" "}
                                  {item.productCode ? (
                                    <Badge className="bg-blue-500 bg-opacity-85 hover:bg-blue-500">
                                      <small className="text-sm">
                                        {getProductDisplayName(
                                          item.productCode
                                        )}
                                      </small>
                                    </Badge>
                                  ) : null}
                                  <span className="text-blue-600 font-semibold text-sm">
                                    <Link
                                      className="button-blue !p-2"
                                      to={`/portfolio/${item.portfolioId}`}
                                      rel="noopener noreferrer"
                                    >
                                      {item.portfolioName}
                                    </Link>
                                  </span>
                                </div>
                                {/* Right section */}
                                <div className="flex-between">
                                  <div className="flex-start deny">
                                    <button
                                      className="text-red-500 mr-2 pr-2 font-semibold text-sm"
                                      onClick={() => {
                                        handleDeny(
                                          item,
                                          RequestStatus.DENY_STATUS
                                        );
                                      }}
                                    >
                                      Deny
                                    </button>
                                    <button
                                      className="text-green-500 ml-1 font-semibold text-sm"
                                      onClick={() => {
                                        handleApprove(
                                          item,
                                          RequestStatus.APPROVED_STATUS
                                        );
                                      }}
                                    >
                                      Approve
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {item?.reason && (
                                <div className="flex-start pl-7 gap-1">
                                  <div className="text-gray text-xs">
                                    <span className="font-bold">
                                      Reason for subscribing:{" "}
                                    </span>
                                    {item.reason.length > 50 ? (
                                      <span
                                        onClick={() =>
                                          callDialogBox(item.reason)
                                        }
                                        className="cursor-pointer pl-1"
                                      >
                                        {item.reason.slice(0, 50)}
                                        ...
                                      </span>
                                    ) : (
                                      item.reason
                                    )}
                                  </div>
                                </div>
                              )}
                            </CardHeader>
                          </Card>
                        ))
                      ) : (
                        <div className="flex justify-center items-center min-h-[50vh]">
                          <EmptyScreen
                            incomingRequest={SideNavBarTabs.INCOMINGREQUESTS}
                          />
                        </div>
                      ))}
                  </TabsContent>
                ))}
            </Tabs>
          </>
        )}

      {isDeny && (
        <ReasonBox
          headerText={"Reason for Denial"}
          setIsDeny={setIsDeny}
          paramsData={selectedItem}
          requestType={REASONBOX_TYPE.DENY}
          methodCall={approveOrDenyPayload}
        />
      )}
      {isDialogBox && (
        <DialogBox
          headerText={"Reason for subscription"}
          DialogBoxMsg={dialogBoxMsg}
          setIsDialogBox={setIsDialogBox}
          setDialogBoxMsg={setDialogBoxMsg}
        />
      )}
    </>
  );
}
