
import { circlecheckblu } from "@/assets/icons-color";
import {
  ADMIN_PAGE_NAVBAR,
  BUILDING_VISUALIZATIONS,
  READY_FOR_MAPPING,
  READY_FOR_REVIEW_STATUS,
} from "@/utils/appConstants";

export const AdminPageSideBar = [
  {
    _id: 1,
    _name: ADMIN_PAGE_NAVBAR.PORTFOLIOS,
    children: [],
    onClick: "",
    isCollapsible: false,
    path: ADMIN_PAGE_NAVBAR.PORTFOLIOS,
    _icon: circlecheckblu,
    selectedIcon: circlecheckblu,
  }
];
export const AdminPortfolioStatusMenu = {
  // DRAFT: "Draft",
  READY_FOR_DATA_MAPPING: READY_FOR_MAPPING,
  BUILDING_VISUALIZATIONS: BUILDING_VISUALIZATIONS,
  READY_FOR_REVIEW: READY_FOR_REVIEW_STATUS,
};
