import React from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import useToast from "@/hooks/useToast";
import { NoteAddGrey, descriptionBlue } from "@/assets/icons";
import { formatFileSize, truncatedString } from "@/utils/utils";
import { Button } from "@/components/ui/Button";
import { Card } from "@/components/ui/Card";
import { Trash2 } from "lucide-react";
const MAX_CSV_FILE_SIZE = import.meta.env.VITE_MAX_FILE_SIZE_MB ;
const IS_FILE_UPLOAD_LIMIT_ALLOWED = import.meta.env.VITE_ENABLE_FILE_SIZE_RESTRICTION;
const max_file_upload_size = MAX_CSV_FILE_SIZE * 1024 * 1024;


type DropAreaProps = {
  file: any;
  setFile: any;
  height?: string;
  id?: string;
  className?: string;
  setFormData?: any;
  isSingleUpdate?:boolean;
};

const DropArea: React.FC<DropAreaProps> = ({
  file,
  setFile,
  height = "317px",
  id,
  className,
  setFormData,
  isSingleUpdate
}) => {
  const { error } = useToast();

  function isAllowedFile(file: any) {
    const allowedExtensions = ['.xlsx', '.xls', '.csv', '.txt', '.doc', '.docx'];
    return allowedExtensions.some(ext => file.name.toLowerCase().endsWith(ext));
  }

  const handleDrop = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    if (isSingleUpdate && rejectedFiles.length > 1) {
      error(
        `Maximum of 1 file${
          IS_FILE_UPLOAD_LIMIT_ALLOWED === "true"
            ? `, ${MAX_CSV_FILE_SIZE} MB per file allowed`
            : " can be uploaded"
        }`
      );
      return;
    }
    if (!isSingleUpdate && rejectedFiles.length > 10) {
      error(
        `Maximum of 10 files${
          IS_FILE_UPLOAD_LIMIT_ALLOWED === "true"
            ? `, ${MAX_CSV_FILE_SIZE} MB per file allowed`
            : " can be uploaded"
        }`
      );
      return;
    }
    let isValidFile = true;
    let isValidSize = true;
    await rejectedFiles.map((_file) => {
      if (!isAllowedFile(_file.file)) {
        isValidFile = false;
      }
      if (IS_FILE_UPLOAD_LIMIT_ALLOWED === 'true' && _file?.file.size > max_file_upload_size) {
        isValidSize = false;
      }
    });
    
    if (!isValidSize) {
      error(`File size should not exceed ${MAX_CSV_FILE_SIZE} MB.`);
    }
    if (!isValidFile) {
      setTimeout(() => {
        error("Unsupported file type. Please try again.");
      }, 500);
    }
    if (acceptedFiles?.length) {
      setFile(acceptedFiles);
      id === "fileUpload" &&
        setFormData((prev: any) => ({
          ...prev,
          error: {
            ...prev.error,
            file: "",
          },
        }));
    }
  };

  const handleDeleteFile = (indexToDelete: number) => {
    const newFiles = file.filter(
      (_: any, index: number) => index !== indexToDelete
    );
    setFile(newFiles);
  };

  return (
    <div className="space-y-2" id={id ?? ""}>
      <Dropzone
        onDrop={handleDrop}
        maxFiles={isSingleUpdate?1:10}
        accept={{
          "text/csv": [".csv"],
          "application/vnd.ms-excel": [".xls", ".xlw"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
            ".xlsm",
          ],
          "application/xml": [".xml"],
          "text/plain": [".txt", ".tab", ".log"],
          "text/html": [".html", ".htm"],
          "application/vnd.ms-qvd": [".qvd", ".qvo"],
          "application/octet-stream": [
            ".skv",
            ".fix",
            ".dat",
            ".qvx",
            ".prn",
            ".parquet",
          ],
          "application/pdf": [".pdf"],
          "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            [".pptx"],
          "application/msword": [".doc"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "text/markdown": [".md"],
          "application/vnd.oasis.opendocument.text": [".odt"],
          "application/rtf": [".rtf"],
          "application/json": [".json"],
          "application/x-httpd-php": [".php"],
          "application/vnd.ms-qvs": [".qvs"],
          "application/vnd.google-earth.kml+xml": [".kml"],
        }}
        maxSize={IS_FILE_UPLOAD_LIMIT_ALLOWED === 'true' ? max_file_upload_size : undefined}
        disabled={file?.length === 10}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={`border-2 border-dashed  ${
              file?.length === 10
                ? "opacity-60 cursor-not-allowed"
                : "cursor-pointer"
            } border-gray-300 rounded-lg transition-colors hover:border-gray-400 ${
              !file?.length ? `h-[${height}] ` : "h-15 "
            } flex items-center justify-center ${className}`}
          >
            <input {...getInputProps()} />
            <div className="text-center p-2">
              {!file?.length ? (
                <div>
                  <img
                    src={NoteAddGrey}
                    alt="Note"
                    className="mx-auto mb-2 text-gray-500 w-12 h-12"
                  />
                  <p className="text-lg font-semibold text-gray-500 mb-1">
                    Select a file to upload
                  </p>
                  <p className="text-sm font-medium text-gray-500">
                    Or drag and drop it here
                  </p>
                  <p className="text-sm font-medium text-gray-500 text-wrap">
                    Supported Files: .csv, .xlsx, .xls, .txt, .doc, .docx
                  </p>
                  {/* Qlik Supported Files <p className="text-sm font-medium text-gray-500 text-wrap">
                  .htm, .kml, .fix,.dat, .qvx, .prn, .php, .qvs, .parquet, .pdf, .pptx, .doc,
                    .docx, .md, .odt, .rtf, .json, .qvd, .xlw, .xlsm, .xml, .tab,
-                    .qvo, .skv, .log, .html,.csv, .xlsx, .xls, .txt, .doc, .docx
                  </p> */}
                  <span className="text-sm font-medium text-red-400 ">
                    Maximum of {isSingleUpdate?1:10} file(s) {IS_FILE_UPLOAD_LIMIT_ALLOWED === 'true' && `, ${MAX_CSV_FILE_SIZE} MB per file`} 
                  </span>
                </div>
              ) : (
                <div className="flex items-center justify-around gap-5">
                  <div className="flex  items-center gap-3">
                    <img
                      src={NoteAddGrey}
                      alt="Note"
                      className="mx-auto mb-2 text-gray-500"
                    />
                    <div className="flex flex-col justify-center">
                      <p className="text-sm text-gray-500">
                        Click or drop in another file to replace the current
                        ones
                      </p>
                      {/* {file.length === 10 && (
                        <span className="text-sm font-medium text-blue-500 ">
                          {`Maximum files has been added`}{" "}
                        </span>
                      )} */}
                    </div>
                  </div>
                  {file.length && (
                    <span className="text-sm font-medium text-blue-500 ">
                      {`${file.length} files added`}{" "}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>

      {/* Selected Files List */}
      {file?.length > 0 && (
        <Card className="p-3">
          <div className=" grid grid-cols-2 gap-2 ">
            {file.map((_file: any, idx: number) => (
              <div
                key={idx}
                className="flex items-center justify-between p-[2px]  rounded-lg backdrop-opacity-10 backdrop-invert bg-white/30  bg-gray-100 hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-center space-x-2 flex-1">
                  <img
                    src={descriptionBlue}
                    alt="File"
                    className="w-5 h-5 flex-shrink-0"
                  />
                  <span
                    className="font-medium  text-sm flex-1"
                    title={_file.name}
                  >
                    {truncatedString(_file.name, 20)}
                  </span>
                  <span className="text-sm text-gray-500 flex-shrink-0">
                    {formatFileSize(_file.size)}
                  </span>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  className=" text-red-400 hover:text-red-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteFile(idx);
                  }}
                >
                  <Trash2 className="w-4 h-4" />
                </Button>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  );
};

export default DropArea;
