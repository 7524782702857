import {
  ANALYZING_DATA,
  BUILDING_VISUALIZATIONS,
  DRAFT_STATUS,
  PROCESSING_STATUS,
  PUBLISHED,
  READY_FOR_MAPPING,
  READY_FOR_REVIEW_STATUS,
} from "@/utils/appConstants";
import { StatusTagType } from "@/utils/utils";
import React from "react";
interface TagStausProps {
  status: StatusTagType;
}
const StatusTag: React.FC<TagStausProps> = ({ status }) => {
  let textColor = "";
  let bgColor = "";
  const capitalizedStatus = status?.charAt(0).toUpperCase() + status?.slice(1);
  switch (status) {
    case PROCESSING_STATUS:
      textColor = "text-blue-500";
      bgColor = "bg-blue-100";
      break;
    case ANALYZING_DATA:
      textColor = "text-purple-500";
      bgColor = "bg-purple-100";
      break;
    case READY_FOR_MAPPING:
      textColor = "text-green-500";
      bgColor = "bg-green-100";
      break;
    case BUILDING_VISUALIZATIONS:
      textColor = "text-yellow-500";
      bgColor = "bg-yellow-100";
      break;
    case READY_FOR_REVIEW_STATUS:
      textColor = "text-orange-500";
      bgColor = "bg-orange-100";
      break;
    case PUBLISHED:
      textColor = "text-green-600";
      bgColor = "bg-green-200";
      break;
    default:
      textColor = "text-gray-500";
      bgColor = "bg-gray-100";
  }

  return (
    <div
      className={` ${bgColor} rounded-md px-2 py-0.5 ${textColor}  h-[28px] w-auto flex justify-center items-center`}
    >
      <span className="!opacity-100 text-xs">{capitalizedStatus}</span>
    </div>
  );
};

export default StatusTag;
