import { configureStore, combineReducers } from "@reduxjs/toolkit";
import postsReducer from "@/state/slices/postSlice";
import AttributeListReducer from "@/state/slices/AttributeListSlice";
import PortfoliosSlice from "@/state/slices/PortfolioSlice";
import HierarchySlice from "@/state/slices/HierarchySlice";
import LoginSlice from "@/state/slices/LoginSlice";
// @ts-ignore
import checkDuplicateValueMiddleware from "@/state/middleware/middleware";
import AIAnalyticsSlice from "./slices/AIAnalyticsSlice";

import PortfolioSliceUpdate from "./slices/PortfolioSliceUpdate";
import AdminPageSlice from "./slices/AdminPageSlice";
import AppReloadSlice from "./slices/AppReloadSlice";
const rootReducer = combineReducers({
  posts: postsReducer,
  attributeList: AttributeListReducer,
  portfolio: PortfoliosSlice,
  hierarchy: HierarchySlice,
  userDetails: LoginSlice,
  pyramidConfig: AIAnalyticsSlice,
  portfolioUpdate: PortfolioSliceUpdate,
  adminPage: AdminPageSlice,
  appReload:AppReloadSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(checkDuplicateValueMiddleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
