import { useState, useEffect, useCallback, useRef } from "react";
interface UseInactivityTrackerProps {
  inactivityTime?: number; // in minutes
  warningTime?: number; // in seconds
}

export const useInactivityTracker = ({
  inactivityTime = 15, 
  warningTime = 30, 
}: UseInactivityTrackerProps) => {
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [countdown, setCountdown] = useState(warningTime);

  const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimer = useCallback(() => {
    setIsWarningOpen(false);
    setCountdown(warningTime);
    clearTimeout(inactivityTimeoutRef.current!);
    clearInterval(countdownIntervalRef.current!);
  }, [warningTime]);


  const startInactivityTimer = () => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(() => {
      setIsWarningOpen(true);
      setCountdown(warningTime);
    }, inactivityTime * 60 * 1000); 
  };

  useEffect(() => {
    const { pathname } = window.location;
    const handleUserActivity = () => {
      if (!isWarningOpen) {
        clearTimeout(inactivityTimeoutRef.current!);
        clearInterval(countdownIntervalRef.current!); 
        startInactivityTimer();
      }
    };


    // Skip tracking on whitelisted routes
    const whitelist = ["/admin/login"];
    if (whitelist.includes(pathname)) return;

    const events = ["mousedown", "mousemove", "keydown", "scroll", "touchstart", "click"];
    events.forEach((event) => document.addEventListener(event, handleUserActivity));
    !isWarningOpen && startInactivityTimer();

    return () => {
      events.forEach((event) =>
        document.removeEventListener(event, handleUserActivity)
      );
      clearTimeout(inactivityTimeoutRef.current!);
      clearInterval(countdownIntervalRef.current!);
      setCountdown(warningTime)
    };
  }, [ window.location, isWarningOpen,startInactivityTimer,resetTimer]);

  const handleStayLoggedIn = useCallback(() => {
    resetTimer();
  }, [resetTimer]);

  return {
    isWarningOpen,
    countdown,
    handleStayLoggedIn,
    setIsWarningOpen
  };
};
