import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getHierarchyDataById,
  updateHierarchy,
} from "@/state/slices/HierarchySlice";
import { EDIT_MODE, VIEW_MODE } from "@/utils/appConstants";
import { editModeUpdatePah } from "@/utils/utils";
import { useReactFlow } from "reactflow";

export function useHierarchyData(id: string, mode: string) {
  const { setNodes, setEdges } = useReactFlow();
  const dispatch = useDispatch();
  const { nodesData, edgesData, hierarchyName } = getHierarchyDataById(id);
  const [hierarchyNodeDataRedux, setHierarchyNodeDataRedux] =
    useState(nodesData);
  const [hierarchyEdgeDataRedux, setHierarchyEdgeDataRedux] =
    useState(edgesData);
  const [_hierarchyName, setHierarchyName] = useState<string>(
    hierarchyName ? hierarchyName : "Untitled"
  );

  useEffect(() => {
    setHierarchyNodeDataRedux(nodesData);
    setHierarchyEdgeDataRedux(edgesData);
    setHierarchyName(hierarchyName ? hierarchyName : "Untitled");
  }, [nodesData, edgesData, hierarchyName, id]);

  useEffect(() => {
    if (mode === VIEW_MODE) {
        setNodes(hierarchyNodeDataRedux || []);
        setEdges(hierarchyEdgeDataRedux || []);
    }

    if (mode === EDIT_MODE) {
      const updatedPah = editModeUpdatePah(hierarchyNodeDataRedux);
      dispatch(
        updateHierarchy({
          hierarchyId: id,
          nodes: updatedPah,
          edges: hierarchyEdgeDataRedux || [],
          hierarchyName: hierarchyName ? hierarchyName : "Untitled",
        })
      );
      setNodes(updatedPah || []);
      setEdges(hierarchyEdgeDataRedux || []);
    }

  }, [id, mode]);
  return {
    nodesData: hierarchyNodeDataRedux,
    edgesData: hierarchyEdgeDataRedux,
    hierarchyName: _hierarchyName,
  };
}
