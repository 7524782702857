import { useMemo } from "react";
import { Card, CardHeader } from "@/components/ui/Card";
import {
  dotmenu,
  LemmataAILogo,
  GreenCircleTick,
  treeviewportfolio,
  publish,
  manageUpload,
} from "@/assets/icons-color";
import TagComponent from "../homepage/StatusTag";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/Dropdown-menu";

import {
  ActivateIcon,
  Chevron_Down,
  Configure_Icon,
  WorkFlow_Black,
} from "@/assets/icons";
import {
  capitalizeFirstLetter,
  functionToDownloadFile,
  getOwnerNames,
  getUpdateStatus,
  highlightText,
  truncatedString,
} from "@/utils/utils";
import { AdminPortfolioStatusMenu } from "./NavbarData";
import {
  getAdminAllPortfolios,
  getAdminPageQlikData,
  getProcessingPortfolios,
  updateStatusOfPortfolio,
} from "@/state/slices/AdminPageSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/state/store";
import useToast from "@/hooks/useToast";

import {
  ADMIN_PAGE_NAVBAR,
  BUILDING_VISUALIZATIONS,
  DashboardsConst,
  getProductDisplayName,
  PUBLISHED,
  READY_FOR_MAPPING,
  READY_FOR_REVIEW_STATUS,
  UNPUBLISHED,
} from "@/utils/appConstants";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Configure from "./Configure";
import { Badge } from "../ui/badge";
import DownloadComponent from "./DownloadComponent";
import { getCSVOnPortfolioId } from "@/state/slices/DownloadCSVSlice";
import { downloadIcon } from "@/assets/icons/createportfolio";

const API_PATH = import.meta.env.VITE_API_CORE_PATH;
import instance from "@/utils/axiosConfig";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/Tooltip";
import {
  publishDraftDashboard,
  publishPortfolio,
} from "@/state/slices/PortfolioSlice";
import { useCallback } from "react";
import { debounce } from "lodash";
type PortfolioCardProps = {
  portfolio: {
    _id: string;
    portfolioName: string;
    isPublished: boolean;
    status: string;
    updatedAt: string;
    lastDataUploadedDate?: string;
    subscribers: string[];
    productCode: string;
    isDraftContentAvailable:boolean;
    isLiveContentAvailable:boolean;
    isReloadDataAvailable:boolean;
    owners: [
      {
        _id: string;
        firstName: string;
        lastName: string;
      }
    ];
  };
  selectedMenuItem: string;
  setIsManageUploadModalOpen: any;
  setPortfolioIdForContextMenu: any;
  heightLighted: any;
};
export interface AxiosErrorResponse {
  message: string;
  name: string;
  stack: string;
  config: {
    transitional: {
      silentJSONParsing: boolean;
      forcedJSONParsing: boolean;
      clarifyTimeoutError: boolean;
    };
    adapter: string[];
    transformRequest: Array<null>;
    transformResponse: Array<null>;
    timeout: number;
    xsrfCookieName: string;
    xsrfHeaderName: string;
    maxContentLength: number;
    maxBodyLength: number;
    env: Record<string, unknown>;
    headers: {
      Accept: string;
      "Content-Type": string;
      "Access-Control-Allow-Origin": string;
      "Access-Control-Allow-Methods": string;
      Authorization: string;
    };
    baseURL: string;
    method: string;
    url: string;
  };
  code: string;
  status: number;
  response: { data: string };
}

type contextMenuProps = {
  _id: string;
  _icon: any;
  _name: string;
  onclick: () => void;
  heightLighted?: string;
};
const PortfolioCard = ({
  portfolio,
  selectedMenuItem,
  setIsManageUploadModalOpen,
  setPortfolioIdForContextMenu,
  heightLighted,
}: PortfolioCardProps) => {
  const [isConfigure, setIsConfigure] = useState<boolean>(false);
  const [isPortfolioPublished, setIsPortfolioPublished] = useState(
    portfolio.isPublished
  );
  const [isDraftDataAvailable, setIsDraftDataAvailable] = useState<boolean>(portfolio.isDraftContentAvailable);
  const [isLiveContentAvailable, setIsLiveContentAvailable] = useState<boolean>(portfolio.isLiveContentAvailable);
  const [portfolioStatus, setPortfolioStatus] = useState(portfolio.status);
  const contextMenuArray = useMemo(
    () => [
      {
        _id: "1",
        _icon: treeviewportfolio,
        _name: "View portfolio",
        onclick: () => {
          navigate(`/portfolio/${portfolio._id}`);
        },
      },
      {
        _id: "3",
        _icon: Configure_Icon,
        _name: "Qlik Configuration",
        onclick: () => {
          handleConfigure();
        },
      },
      {
        _id: "4",
        _icon: downloadIcon,
        _name: "Download",
        onclick: () => {
          handleDownload(true);
        },
      },
      {
        _id: "5",
        _icon: WorkFlow_Black,
        _name: "Download Template",
        onclick: () => {
          handleDownloadTemplateFile(portfolio._id, portfolio.portfolioName);
        },
      },
      {
        _id: "7",
        _icon: manageUpload,
        _name: "Manage Manual Uploads",
        onclick: () => {
          setIsManageUploadModalOpen(true);
          setPortfolioIdForContextMenu(portfolio._id);
        },
      },
      ...(portfolio?.isReloadDataAvailable
        ? [
            {
              _id: "9",
              _icon: ActivateIcon,
              _name: "Activate App Reload",

              onclick: () => {
                handleActivateAppReload();
              },
            },
          ]
        : []),
      ...(!isPortfolioPublished
        ? [
            {
              _id: "6",
              _icon: publish,
              _name: "Publish",
              onclick: () => handlePublish({ id: portfolio._id }),
            },
          ]
        : []),
      ...(isDraftDataAvailable
        ? [
            {
              _id: "8",
              _icon: publish,
              _name: "Publish Draft Dashboard",
              onclick: () => handlePublishDraftDashboard({ id: portfolio._id }),
            },
          ]
        : []),
    ],
    [
      portfolio._id,
      portfolio.isPublished,
      portfolio.portfolioName,
      isDraftDataAvailable
    ]
  );
  const [contextMenu, setContextMenu] = useState(contextMenuArray);
  const [showDownload, setShowDownload] = useState(false);
  const [csvList, setCsvList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { success, error } = useToast();
  const handleConfigure = () => {
    setIsConfigure(!isConfigure);    
     dispatch(getAdminPageQlikData({ id: portfolio._id }))
      .then((action: any) => {
        const contents = !!(action.payload?.data?.qlik?.contents?.length);
        const isDraftContentExist = !!(action.payload?.data?.qlik?.draftContents?.length);
        setIsDraftDataAvailable(isDraftContentExist);
        setIsLiveContentAvailable(contents);
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    setContextMenu(contextMenuArray);
  }, [contextMenuArray, isDraftDataAvailable]); // Add isDraftDataAvailable as a dependency

  const handleDownloadTemplateFile = async (
    portfolioId: string,
    fileName: string
  ) => {
    await instance
      .get(`${API_PATH}/admin/download/template-file/${portfolioId}`)
      .then((response) => {
        functionToDownloadFile(response.data, fileName + ".csv");
      })
      .catch((err) => {
        console.log("Error =>", err.response.data.subStatus);
        if (err?.response?.data?.subStatus == "FileNotFound") {
          error("Hierarchy has not been created.");
        } else {
          error(
            "The file could not be downloaded due to an unexpected issue. Please try again later."
          );
        }
      });
  };
  // Implemented debounce and useCallback to prevent unnecessary re-renders
  const handlePublish = useCallback(
    debounce(async (payload: { id: string }) => {
      try {
        const action = await dispatch(publishPortfolio(payload));
        if (publishPortfolio.fulfilled.match(action)) {
          success(action.payload.data.success);
          setIsPortfolioPublished(true);
          setContextMenu((prevMenu) =>
            prevMenu.filter((item) => item._id !== "6")
          );
          setIsLiveContentAvailable(true)
        } else {
          error("Error while publishing the portfolio");
        }
      } catch (err) {
        console.error(err);
        error("Error while publishing the portfolio");
      }
    }, 300),
    []
  );
  const handlePublishDraftDashboard = useCallback(
    debounce(async (payload: { id: string }) => {
      try {
        const action = await dispatch(publishDraftDashboard(payload));
        if (publishDraftDashboard.fulfilled.match(action)) {
          success("Draft Dashboard Published Successfully");
          //setIsPortfolioPublished(true);
          setContextMenu((prevMenu) =>
            prevMenu.filter((item) => item._id !== "8")
          );
          setIsDraftDataAvailable(false)
        } else {
          error("Error while publishing the Draft Dashboard");
        }
      } catch (err) {
        console.error(err);
        error("Error while publishing the Draft Dashboard");
      }
    }, 300),
    []
  );

  function handleStatusChange(value: string, portfolioID: string) {
    dispatch(
      updateStatusOfPortfolio({ id: portfolioID, data: { status: value } })
    )
      .then((action) => {
        if (updateStatusOfPortfolio.fulfilled.match(action)) {
          setPortfolioStatus(value);
          success("Portfolio Status Updated");
        } else if (updateStatusOfPortfolio.rejected.match(action)) {
          error("Error occurred while updating the status.");
        }
        // portfolio
        selectedMenuItem === ADMIN_PAGE_NAVBAR.PORTFOLIOS &&
          dispatch(getAdminAllPortfolios());
        // processing
        selectedMenuItem === ADMIN_PAGE_NAVBAR.PROCESSING &&
          dispatch(getProcessingPortfolios());
      })
      .catch((e) => {
        error("Error occurred while updating the status.");
      });
  }

  const handleActivateAppReload = useCallback(
    debounce(async () => {
      await instance
        .patch(`${API_PATH}/admin/${portfolio._id}/reload-schedule/activate`, {
          status: "Active",
        })
        .then((response) => {
          success("Activated");
        })
        .catch((err) => {
          error("Unable to activate");
        });
    }, 300),
    []
  );

  const handleDownload = (check: boolean) => {
    // do a api call to get the list of all the downloadable files
    if (check) {
      dispatch(getCSVOnPortfolioId({ id: portfolio._id }))
        .then((action) => {
          if (getCSVOnPortfolioId.fulfilled.match(action)) {
            setCsvList(action.payload.data);
          } else if (getCSVOnPortfolioId.rejected.match(action)) {
            error("Unable to fetch the files.");
            console.log(action);
          }
        })
        .catch((e) => {
          error("Error occurred while updating the status.");
          console.log(e);
        });
    }
    setShowDownload(!showDownload);
  };
  return (
    <Card className="w-full mb-4 overflow-y-auto">
      {/* portfolio section */}
      <CardHeader className="p-4">
        <div className="wrapper flex-between">
          {/* Left section */}
          <div className="flex-between gap-3">
            {/* user/portfolio/owner Image */}
            <div>
              <img
                src={LemmataAILogo}
                alt="avatar"
                className="w-[60px] h-[60px]"
              />
            </div>

            {/* Portfolio name, owner name  ,updated status ,sub count */}
            <div className="flex flex-col">
              <div className="flex-start gap-2 p-1">
                {portfolio.productCode ? (
                  <Badge className="border border-blue-500 bg-white text-blue-500 bg-opacity-85 hover:bg-transparent">
                    <small className="text-sm">
                      {getProductDisplayName(portfolio.productCode)}
                    </small>
                  </Badge>
                ) : null}
                <h4 className="font-semibold">
                  {heightLighted == "" ? (
                    portfolio.portfolioName
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlightText(
                          heightLighted,
                          portfolio.portfolioName
                        ),
                      }}
                    />
                  )}
                </h4>

                <span className="text-gray opacity-90 text-sm">
                  {Object.keys(portfolio?.owners?.[0] || {}).length !== 0 ? (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <span>
                            {heightLighted == "" ? (
                              getOwnerNames(portfolio?.owners)
                            ) : (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: highlightText(
                                    heightLighted,
                                    truncatedString(
                                      getOwnerNames(portfolio?.owners),
                                      18
                                    )
                                  ),
                                }}
                              />
                            )}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent className="text-gray-600 font-normal text-sm mb-2">
                          <strong>{getOwnerNames(portfolio?.owners)}</strong>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="flex-start gap-2 mt-2 pl-1">
                {isPortfolioPublished ? (
                  <span
                    className="bg-green-200 rounded-md px-2 py-0.5 text-green-600  h-[28px] w-auto flex justify-center items-center !opacity-100 text-xs"
                    dangerouslySetInnerHTML={{
                      __html: highlightText(
                        heightLighted,
                        capitalizeFirstLetter(PUBLISHED)
                      ),
                    }}
                  />
                ) : (
                  <TagComponent status={UNPUBLISHED} />
                )}
                {portfolio?.lastDataUploadedDate && (
                  <span className=" text-gray text-xs p-0 m-0">
                    Last data uploaded:
                    {getUpdateStatus(portfolio.lastDataUploadedDate, "")}
                  </span>
                )}
                {/* update section */}

                <span className="text-gray text-xs">
                  {getUpdateStatus(portfolio?.updatedAt, "Updated")}
                </span>

                {/* subscription */}
                {portfolio?.subscribers && (
                  <div className="flex-start gap-2 ml-3">
                    {/* <img src={subcriber} alt="subcriber" /> */}
                    <span className="text-gray text-xs">
                      {portfolio?.subscribers.length} subscribers
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Right section */}
          <div className="flex-between">
            {isLiveContentAvailable && ( 
              <DropdownMenu>
                <DropdownMenuTrigger className="flex pr-4 gap-2 justify-center items-center text-sm cursor-pointer">
                  <span>View Dashboard</span>
                  <img src={Chevron_Down} alt="Chevron_Down" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mt-3 mr-4">
                  {DashboardsConst.map((item, index) => (
                    <div key={index}>
                      <DropdownMenuItem className="">
                        <Link
                          className="!p-2 flex gap-2 align-middle items-center text-sm"
                          to={`/ai-analytics/${
                            portfolio._id
                          }/${capitalizeFirstLetter(item.name)}/live`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={item._icons} alt={item.name} />
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)}
                        </Link>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                    </div>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
            {isDraftDataAvailable && (
              <DropdownMenu>
                <DropdownMenuTrigger className="flex pr-4 gap-2 justify-center items-center text-sm cursor-pointer">
                  <span>View Draft Dashboard</span>
                  <img src={Chevron_Down} alt="Chevron_Down" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mt-3 mr-4">
                  {DashboardsConst.map((item, index) => (
                    <div key={index}>
                      <DropdownMenuItem className="">
                        <Link
                          className="!p-2 flex gap-2 align-middle items-center text-sm"
                          to={`/ai-analytics/${
                            portfolio._id
                          }/${capitalizeFirstLetter(item.name)}/draft`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={item._icons} alt={item.name} />
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)}
                        </Link>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                    </div>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
            {(portfolioStatus === READY_FOR_REVIEW_STATUS ||
              portfolioStatus === BUILDING_VISUALIZATIONS ||
              portfolioStatus === READY_FOR_MAPPING) && (
              <DropdownMenu>
                <DropdownMenuTrigger
                  className="flex pr-4 gap-2 justify-center items-center text-sm cursor-pointer"
                >
                  <span
                    className={`${
                      portfolioStatus === READY_FOR_REVIEW_STATUS
                        ? "text-blue-600"
                        : ""
                    }`}
                  >
                    {portfolioStatus}
                  </span>
                  <img src={Chevron_Down} alt="Chevron_Down" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mt-3 mr-4">
                  {Object.keys(AdminPortfolioStatusMenu).map((item, index) => {
                    const typedItem =
                      item as keyof typeof AdminPortfolioStatusMenu;
                    return (
                      <div key={index}>
                        <DropdownMenuGroup >
                          <DropdownMenuItem
                            onClick={() =>
                              handleStatusChange(
                                AdminPortfolioStatusMenu[typedItem],
                                portfolio._id
                              )
                            }
                            className="gap-3"
                          >
                            {portfolioStatus ===
                            AdminPortfolioStatusMenu[typedItem] ? (
                              <img src={GreenCircleTick} alt="greenTick" />
                            ) : (
                              ""
                            )}
                            <span
                              className={`${
                                AdminPortfolioStatusMenu.READY_FOR_REVIEW ===
                                AdminPortfolioStatusMenu[typedItem]
                                  ? "text-blue-600"
                                  : ""
                              } ${
                                portfolioStatus !==
                                AdminPortfolioStatusMenu[typedItem]
                                  ? "ml-6"
                                  : ""
                              } capitalize`}
                            >
                              {AdminPortfolioStatusMenu[typedItem]}
                            </span>
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                        <DropdownMenuSeparator />
                      </div>
                    );
                  })}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
            {/* commented this code as we might needed it for later use */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <img
                  src={dotmenu}
                  alt="dot-icon"
                  className="cursor-pointer "
                  // onClick={() => handleMenuPopUp()}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-auto mr-[70px] !cursor-pointer">
                <ul
                  className="transition-opacity duration-5000 ease-in opacity-100 p-0"
                  key="menu-items"
                >
                  {contextMenu.map((menu: contextMenuProps) => (
                    <div
                      id={menu._id}
                      key={menu._id}
                      onClick={menu.onclick}
                      className="!cursor-pointer"
                    >
                      <DropdownMenuItem className="!cursor-pointer">
                        <li
                          key={menu._id}
                          className="p-1 px-3 !cursor-pointer flex-start gap-2 text-sm text-gray"
                        >
                          <img
                            src={menu._icon}
                            alt="Icons"
                            width="15px"
                            height="15px"
                          />
                          {menu._name}
                        </li>
                      </DropdownMenuItem>
                    </div>
                  ))}
                </ul>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </CardHeader>
      {isConfigure && (
        <Configure
          portfolioId={portfolio._id}
          isPublished={isPortfolioPublished}
          handleConfigure={handleConfigure}
        />
      )}
      {showDownload && (
        <DownloadComponent handleConfigure={handleDownload} csvList={csvList} />
      )}
    </Card>
  );
};

export default PortfolioCard;
