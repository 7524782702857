import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Edge, Handle, Position, useReactFlow } from "reactflow";
import { Input } from "@/components/ui/Input";
import { toast } from "sonner";
import {
  findNextItemById,
  generateEdges,
  generateSiblingsNodes,
  generateUuid,
  truncatedString,
} from "@/utils/utils";
import { Plus } from "@/assets/icons";
import { AppDispatch, RootState } from "@/state/store";
import { ALLOWED_SPECIAL_CHARS_REG_EXP, LENGTH } from "@/utils/appConstants";
import {
  ATTRIBUTE_CANT_BE_EMPTY,
  ATTRIBUTE_LENGTH_MSG,
  NODE_NAME_REQUIRED_MSG,
  NODE_VALUE_VALIDATION_MSG,
} from "@/utils/messageConstants";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { useDispatch } from "react-redux";
import { resetErrors, setErrors } from "@/state/slices/HierarchySlice";
import { ConstantsItem } from "@/constants/nodes";
type DataProps = {
  label: string;
  id: string;
  pahName: string;
  attributeId: string;
  icon: string;
  disableNodeButtons: boolean;
};
type PropsTypes = {
  id: string;
  data: DataProps;
  isConnectable: boolean;
};
const inputValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
  const enteredValue = e.target.value;
  if (enteredValue.length > LENGTH) {
    toast.dismiss();
    toast.error(ATTRIBUTE_LENGTH_MSG);
    return;
  }
  if (
    enteredValue.trim() &&
    !ALLOWED_SPECIAL_CHARS_REG_EXP.test(enteredValue)
  ) {
    toast.dismiss();
    toast.error(NODE_VALUE_VALIDATION_MSG);
    return;
  }
  else {
    return true;
  }
};
const getUpdatedNodes = (getNodes: any, id: string, nodeName: string) => {
  return getNodes()?.map((node: any) => {
    if (node.id === id) {
      return {
        ...node,
        data: {
          ...node.data,
          pahName: nodeName,
        },
      };
    }
    return node;
  });
};
const handleOnChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  dispatch: AppDispatch,
  setNodeName: Dispatch<SetStateAction<string>>
) => {
  if (inputValidation(e)) {
    setNodeName(e.target.value);
    dispatch(resetErrors());
  }else if(!e.target.value.trim()){
    setNodeName(e.target.value);
    dispatch(setErrors(NODE_NAME_REQUIRED_MSG));
  }
};
const onfocusout = (
  setIsEdit: Dispatch<SetStateAction<boolean>>,
  getNodes: () => any[],
  setNodes: Dispatch<SetStateAction<any>>,
  id: any,
  nodeName: string,
  inputRef: any,
  dispatch: AppDispatch
) => {
  if (!nodeName.trim()) {
    toast.dismiss();
    toast.error(NODE_NAME_REQUIRED_MSG);
    dispatch(setErrors(NODE_NAME_REQUIRED_MSG));
    setIsEdit(true);
    inputRef.current.focus();
    return;
  }
  setIsEdit(false);
  let updateNode = getUpdatedNodes(getNodes, id, nodeName);
  setNodes(updateNode);
  dispatch(resetErrors());
};

const handleKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  setIsEdit: Dispatch<SetStateAction<boolean>>,
  getNodes: () => any[],
  setNodes: Dispatch<SetStateAction<any>>,
  id: any,
  nodeName: string,
  inputRef: any,
  dispatch: AppDispatch
) => {
  // @ts-ignore
  const enteredValue = event.target.value;
  if (event.key === "Enter" || event.key === "Escape") {
    if (!enteredValue.trim()) {
      dispatch(setErrors(NODE_NAME_REQUIRED_MSG));
      toast.dismiss();
      toast.error(NODE_NAME_REQUIRED_MSG);
      setIsEdit(true);
      inputRef.current.focus();
      return;
    } else {
      onfocusout(
        setIsEdit,
        getNodes,
        setNodes,
        id,
        nodeName,
        inputRef,
        dispatch
      );
    }
  }
};
export const BeginningNode = ({ id, data, isConnectable }: PropsTypes) => {
  const dispatch = useDispatch();
  const [nodeName, setNodeName] = useState(
    data?.pahName === "" ? data?.label : data?.pahName
  );
  const { getNode, setEdges, getEdges, addNodes, getNodes, setNodes } =
    useReactFlow();
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleDoubleClick = () => {
    setIsEdit(true);
  };

  // @ts-ignore
  const attributeList = useSelector(
    (state: RootState) => state.attributeList.value
  );

  const createChild = useCallback(() => {
    const allEdges = getEdges();
    const node = getNode(id);

    let childAttribute = findNextItemById(
      attributeList,
      node?.data.attributeId
    );
    const childId = generateUuid();
    addNodes({
      id: childId,
      position: { x: 0, y: 0 },
      data: {
        id: childId,
        label: childAttribute?.data?.attributeName,
        pahName: "",
        attributeId: childAttribute?.data.attributeId,
        icon: childAttribute?.data.icon,
      },
      type: childAttribute?.isFirst
        ? "beginningNode"
        : childAttribute?.isLast
        ? "endingNode"
        : "customDefaultNode",
      // @ts-ignore
      attributeId: childAttribute?.data.attributeId,
    });

    const newEdge = {
      id: generateUuid(),
      source: id,
      target: childId,
      style: {
        strokeWidth: 2,
        stroke: 'black',
      },
    };
    setEdges([...allEdges, newEdge]);
  }, [id]);

  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  useEffect(() => {
    if (nodeName?.trim()) {
      let updateNode = getUpdatedNodes(getNodes, id, nodeName);
      setNodes(updateNode);
      dispatch(resetErrors());
    }
  }, [nodeName]);

  return (
    <div className="w-full ">
      <div
        className="flex flex-row gap-x-4 items-center"
        onDoubleClick={() =>
          data?.disableNodeButtons ? undefined : handleDoubleClick()
        }
      >
        <div className="flex flex-row gap-x-4 cursor-pointer items-center justify-center">
          <img src={data?.icon} alt={nodeName} className="responsive-img" />
          {isEdit ? (
            <Input
              type="text"
              className="responsive-text"
              value={nodeName}
              onBlur={() =>
                onfocusout(
                  setIsEdit,
                  getNodes,
                  setNodes,
                  id,
                  nodeName,
                  inputRef,
                  dispatch
                )
              }
              onChange={(e) => handleOnChange(e, dispatch, setNodeName)}
              ref={inputRef}
              style={{ border: "none", outline: "none" }}
              onKeyDown={(e) =>
                handleKeyDown(
                  e,
                  setIsEdit,
                  getNodes,
                  setNodes,
                  id,
                  nodeName,
                  inputRef,
                  dispatch
                )
              }
            />
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span className="responsive-text">{truncatedString(nodeName)}</span>
                </TooltipTrigger>
                <TooltipContent className="text-gray-600 font-normal text-sm mb-2 responsive-tooltip">
                  <strong>{nodeName}</strong>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </div>

      {!data?.disableNodeButtons && (
        <div className="flex flex-row justify-center relative top-[70px] left-[-7px] mt-[-40px]">
          <div
            onClick={createChild}
            className="flex w-10 h-10  justify-center items-center rounded-full border-dashed border-[2px] border-gray-300 bg-white shadow-xs"
          >
            <img src={Plus} alt="add_node" />
          </div>
        </div>
      )}

      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </div>
  );
};
export const CustomDefaultNode = ({ id, data, isConnectable }: PropsTypes) => {
  const dispatch = useDispatch();
  const [nodeName, setNodeName] = useState(
    data?.pahName === "" ? data?.label : data?.pahName
  );
  const [isEdit, setIsEdit] = useState(false);
  const { getNode, setEdges, getEdges, getNodes, setNodes, addNodes } =
    useReactFlow();
  const inputRef = useRef<HTMLInputElement | null>(null);
  // @ts-ignore
  const attributeSelector = useSelector(
    (state: RootState) => state.attributeList.value
  );
  const [attributeList, setAttributeList] = useState(attributeSelector);
  useEffect(() => {
    setAttributeList(attributeSelector);
  }, [attributeSelector]);

  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const getEdgesByNodeId = (nodeId: string, edges: Edge<any>[]) => {
    const edgesByNodeId = edges.filter((edge) => edge.target === nodeId);
    return edgesByNodeId[0]?.source;
  };
  const getTheSiblings = (nodeId: string, attributeData: ConstantsItem[]) => {
    // attributeList is an array of object get the match the nodeId and get all the next object of in the array
    const matchedIndex = attributeData.findIndex(
      (obj: any) => obj?.attributeId === nodeId
    );
    let newNodes = [];
    if (matchedIndex !== -1) {
      const objectsAfterMatched = attributeData.slice(matchedIndex);
      newNodes = generateSiblingsNodes(objectsAfterMatched);
    }
    return newNodes;
  };
  // Create Siblings
  const createSiblings = useCallback(
    (attributeData: any) => {
      const allNodes = getNodes();
      const allEdges = getEdges();

      const node = getNode(id);
      // getTheSiblings will give you the list of all the children of next siblings
      const siblings = getTheSiblings(node?.data.attributeId, attributeData);
      // set the siblings in the nodes
      setNodes([...allNodes, ...siblings]);
      // you need to generate the edges between the siblings
      const newEdges = generateEdges(siblings);

      const newEdge = [
        {
          id: generateUuid(),
          source: getEdgesByNodeId(id, allEdges),
          target: siblings[0]?.id,
          style: {
            strokeWidth: 2,
            stroke: 'black',
          },
        },
      ];

      setEdges([...allEdges, ...newEdge, ...newEdges]);
    },
    [id, attributeList, data]
  );
  const wrappedSiblings = () => {
    // check if all attribute names are not empty if true through error in toast
    function checkAttributeNames(attributes: ConstantsItem[]) {
      return attributes.some(attribute => attribute.attributeName === "");
    }
    if (checkAttributeNames(attributeList)) {
      toast.dismiss();
      toast.error(ATTRIBUTE_CANT_BE_EMPTY);
      return undefined
    } else {
      createSiblings(attributeList);
    }
  };

  const createChild = useCallback(() => {
    const allEdges = getEdges();
    const node = getNode(id);

    const childAttribute = findNextItemById(
      attributeList,
      node?.data.attributeId
    );
    if (childAttribute?.data?.attributeName?.trim() === "") {
      toast.dismiss();
      toast.error(ATTRIBUTE_CANT_BE_EMPTY);
      return undefined
    } else {
      const childId = generateUuid();
      addNodes({
        id: childId,
        position: { x: 0, y: 0 },
        data: {
          id: childId,
          label: childAttribute?.data?.attributeName,
          pahName: "",
          attributeId: childAttribute?.data.attributeId,
          icon: childAttribute?.data.icon,
        },
        type: childAttribute?.isFirst
          ? "beginningNode"
          : childAttribute?.isLast
            ? "endingNode"
            : "customDefaultNode",
        // @ts-ignore
        attributeId: childAttribute?.data.attributeId,
      });

      const newEdge = {
        id: generateUuid(),
        source: id,
        target: childId,
        style: {
          strokeWidth: 2,
          stroke: 'black',
        },
      };
      setEdges([...allEdges, newEdge]);
    }
  }, [id]);

  const handleDoubleClick = () => {
    setIsEdit(true);
  };

  useEffect(() => {
    if (nodeName?.trim()) {
      const updateNode = getUpdatedNodes(getNodes, id, nodeName);
      setNodes(updateNode);
      dispatch(resetErrors());
    }
  }, [nodeName]);
  
  return (
    <div className="w-full">
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div
        className="flex flex-row gap-x-4 items-center justify-between "
        onDoubleClick={() =>
          data?.disableNodeButtons ? undefined : handleDoubleClick()
        }
      >
        <div className="flex flex-row gap-x-4 cursor-pointer items-center ">
          <img src={data?.icon} alt={nodeName} className="responsive-img"/>
          {isEdit ? (
            <Input
              type="text"
              className="responsive-text"
              value={nodeName}
              onBlur={() =>
                onfocusout(
                  setIsEdit,
                  getNodes,
                  setNodes,
                  id,
                  nodeName,
                  inputRef,
                  dispatch
                )
              }
              ref={inputRef}
              onChange={(e) => handleOnChange(e, dispatch, setNodeName)}
              onKeyDown={(e) =>
                handleKeyDown(
                  e,
                  setIsEdit,
                  getNodes,
                  setNodes,
                  id,
                  nodeName,
                  inputRef,
                  dispatch
                )
              }
            />
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span className="responsive-text">{truncatedString(nodeName)}</span>
                </TooltipTrigger>
                <TooltipContent className="text-gray-600 font-normal text-sm mb-2 responsive-tooltip" >
                  <strong>{nodeName}</strong>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        {!data?.disableNodeButtons && (
          <div
            onClick={wrappedSiblings}
            className="flex w-10 h-10 mr-[-50px] justify-center items-center rounded-full border-dashed border-[2px] border-gray-300 bg-white shadow-xs"
          >
            <img src={Plus} alt="add_node" />
          </div>
        )}
      </div>
      {!data?.disableNodeButtons && (
        <div className="flex flex-row justify-center relative top-[70px] left-[-7px] mt-[-40px]">
          <div
            onClick={createChild}
            className="flex w-10 h-10  justify-center items-center rounded-full border-dashed border-[2px] border-gray-300 bg-white shadow-xs"
          >
            <img src={Plus} alt="add_node" />
          </div>
        </div>
      )}

      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </div>
  );
};
export const EndingNode = ({ id, data, isConnectable }: PropsTypes) => {
  const dispatch = useDispatch();
  const [nodeName, setNodeName] = useState(
    data?.pahName === "" ? data?.label : data?.pahName
  );
  const [isEdit, setIsEdit] = useState(false);
  const { getNode, setEdges, getEdges, getNodes, setNodes } = useReactFlow();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const getEdgesByNodeId = (nodeId: string, allEdges: Edge<any>[]) => {
    const edgesByNodeId = allEdges.filter((edge) => edge.target === nodeId);
    return edgesByNodeId[0]?.source;
  };
  const createSiblings = useCallback(() => {
    const allNodes = getNodes();
    const allEdges = getEdges();

    const node = getNode(id);
    const childId = generateUuid();
    setNodes([
      ...allNodes,
      {
        ...node,
        id: childId,
        // @ts-ignore
        attributeId: id,
        data: { ...node?.data, label: node?.data.label, pahName: "" },
      },
    ]);

    const newEdge = {
      id: generateUuid(),
      source: getEdgesByNodeId(id, allEdges),
      target: childId,
      style: {
        strokeWidth: 2,
        stroke: 'black',
      },
    };
    setEdges([...allEdges, newEdge]);
  }, [id]);
  const handleDoubleClick = () => {
    setIsEdit(true);
  };

  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  useEffect(() => {
    if (nodeName?.trim()) {
      let updateNode = getUpdatedNodes(getNodes, id, nodeName);
      setNodes(updateNode);
      dispatch(resetErrors());
    }
  }, [nodeName]);
  return (
    <div className="w-full">
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div
        className="flex flex-row gap-x-4 items-center justify-between"
        onDoubleClick={() =>
          data?.disableNodeButtons ? undefined : handleDoubleClick()
        }
      >
        <div className="flex flex-row gap-x-4 cursor-pointer items-center">
          <img src={data?.icon} alt={nodeName} className="responsive-img"/>
          {isEdit ? (
            <Input
              type="text"
              className="responsive-text"
              value={nodeName}
              onBlur={() =>
                onfocusout(
                  setIsEdit,
                  getNodes,
                  setNodes,
                  id,
                  nodeName,
                  inputRef,
                  dispatch
                )
              }
              ref={inputRef}
              onChange={(e) => handleOnChange(e, dispatch, setNodeName)}
              onKeyDown={(e) =>
                handleKeyDown(
                  e,
                  setIsEdit,
                  getNodes,
                  setNodes,
                  id,
                  nodeName,
                  inputRef,
                  dispatch
                )
              }
              // style={{ border: "none", outline: "none" }}
            />
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span className="responsive-text">{truncatedString(nodeName)}</span>
                </TooltipTrigger>
                <TooltipContent className="text-gray-600 font-normal text-sm mb-2 responsive-tooltip">
                  <strong>{nodeName}</strong>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        {!data?.disableNodeButtons && (
          <div
            onClick={createSiblings}
            className="flex w-10 h-10 mr-[-50px] justify-center items-center rounded-full border-dashed border-[2px] border-gray-300 bg-white shadow-xs"
          >
            <img src={Plus} alt="add_node" />
          </div>
        )}
      </div>
    </div>
  );
};
