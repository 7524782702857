import instance from "@/utils/axiosConfig";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const API_PATH = import.meta.env.VITE_API_CORE_PATH;
type initialStateProps = {
  data: any;
  loading: boolean;
  error: string | null | undefined;
};
const initialState: initialStateProps = {
  data: {},
  loading: false,
  error: null,
};

export const getCSVOnPortfolioId = createAsyncThunk(
  "/admin/portfolio/{id}/file-uploads",
  async ({ id }: { id: string }) => {
    try {
      const response = await instance.get(
        `${API_PATH}/admin/portfolio/${id}/file-uploads`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
const DownloadCSVSlice = createSlice({
  name: "DownloadCSV",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCSVOnPortfolioId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCSVOnPortfolioId.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCSVOnPortfolioId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default DownloadCSVSlice.reducer;
// export const DownloadCSVSliceSelector = (state:RootState)=> state.
