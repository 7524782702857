import useAutoLayout from "@/hooks/useAutoLayout";
import {
  defaultLayoutHierarchy,
  defaultLayoutHierarchyOnEdit,
  editModeUpdatePah,
  generatePahGraphUsingElk,
  proOptions,
} from "@/utils/utils";
import { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  useReactFlow,
  MarkerType,
  getOutgoers,
  getIncomers,
  getConnectedEdges,
} from "reactflow";
import "reactflow/dist/style.css";
import {
  BeginningNode,
  CustomDefaultNode,
  EndingNode,
} from "@/components/custom-nodes/CustomNodes";
import ContextMenu from "@/components/createportfolio/ContextMenu";
import { EDIT_MODE, VIEW_MODE } from "@/utils/appConstants";
import { useDispatch } from "react-redux";
import { updateHierarchy } from "@/state/slices/HierarchySlice";
import { useHierarchyData } from "./useHierarchyDataHook";
import Loader from "@/components/common/Loader";
import useToast from "@/hooks/useToast";
// Types
export type FitViewOptions = {
  padding?: number;
  includeHiddenNodes?: boolean;
  minZoom?: number;
  maxZoom?: number;
  duration?: number;
  nodes?: [];
};
type EdgeAttribute = {
  id: string;
  source: string;
  target: string;
};
type NodeAttribute = {
  id: string;
  position: { x: number; y: number };
  data: {
    label: string;
    id: string;
    pahName: string;
    attributeId: string;
    icon: string;
  };
  type: string;
};
type HierarchyChartProps = {
  id: string;
  nodesData?: NodeAttribute[];
  edgesData?: EdgeAttribute[];
  mode: string;
  isQuickStart: boolean;
};
// type of nodes
const nodeTypes = {
  beginningNode: BeginningNode,
  customDefaultNode: CustomDefaultNode,
  endingNode: EndingNode,
};

const defaultEdgeOptions = {
  type: "smoothstep",
  
  markerEnd: { type: MarkerType.ArrowClosed,color:'black' },
  pathOptions: { offset: 5 },
  // floating: FloatingEdge
};

const HierarchyChart = ({ id, mode, isQuickStart }: HierarchyChartProps) => {
  const { fitView } = useReactFlow();
  const dispatch = useDispatch();
  const [tabId, setTabId] = useState<string>(id);
  const { nodesData, edgesData, hierarchyName } = useHierarchyData(id, mode);
  const [nodes, , onNodesChange] = useNodesState(nodesData || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(edgesData || []);
  const [isTabIdChanged, setIsTabIdChanged] = useState<boolean>(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const [nodeId, setNodeId] = useState("");
  const onConnect = useCallback(
    (params: any) => setEdges((eds: any) => addEdge(params, eds)),
    [setEdges]
  );
  const { error } = useToast();
  // to modify the masked stroke of minimap
  const [nodesLength, setNodesLength] = useState(5);

  const fitViewOption: FitViewOptions = {
    minZoom: 0.001,
    maxZoom: 2,
    duration: 600, //600 ms to load the hierarchy
  };
  const { setNodes, getEdges, getNodes, getNode, deleteElements } =
    useReactFlow();

  const onLoad = () => {
    fitView(fitViewOption);
  };
  const [isFitView, setIsFitView] = useState(isQuickStart);

  useEffect(() => {
    setTimeout(() => {
      onLoad();
      setIsFitView(true);
    }, 1000);
  }, [isFitView === false && fitViewOption]);

  async function reRenderOnEdit() {
    // enable edit nodes
    const enableEditNodes = editModeUpdatePah(nodes);
    // pass the same data to re-render the hierarchy
    const { nextNodes, nextEdges } = await generatePahGraphUsingElk(
      enableEditNodes,
      edges,
      defaultLayoutHierarchyOnEdit
    );
    setNodes(nextNodes);
    setEdges(nextEdges);
  }

  useEffect(() => {
    (mode == "" || mode === EDIT_MODE) &&
      dispatch(
        updateHierarchy({
          hierarchyId: tabId,
          nodes: nodes,
          edges: edges,
          hierarchyName: hierarchyName,
        })
      );
    // useEffect to check the nodes length
    switch (true) {
      case nodes.length <= 50:
        setNodesLength(10);
        break;
      case nodes.length <= 100:
        setNodesLength(20);
        break;
      case nodes.length <= 200:
        setNodesLength(30);
        break;
      case nodes.length >= 201:
        setNodesLength(75);
        break;
      default:
        setNodesLength(5);
        break;
    }
  }, [nodes]);

  useEffect(() => {
    if (mode === EDIT_MODE) {
      reRenderOnEdit();
    }
  }, [mode]);

  useEffect(() => {
    setTimeout(() => {
      setIsTabIdChanged(false);
    }, 800);
  }, [tabId]);
  const onNodeContextMenu = (e: any, node: any) => {
    setNodeId(node.id);
    e.preventDefault();
    setPosition({ x: e.clientX, y: e.clientY });
    setIsOpen(true);
  };

  // this function will delete only the selected node
  const deleteNode = useCallback(
    (id: string) => {
      const currentNode: any = getNode(id);
      if (currentNode.type === "beginningNode") {
        error("The root node cannot be deleted");
        return undefined;
      } else {
        deleteElements({ nodes: [{ id }] });
        const allNodes = getNodes();
        const allEdges = getEdges();

        const incomers = getIncomers(currentNode, allNodes, allEdges);
        const outgoers = getOutgoers(currentNode, allNodes, allEdges);
        const connectedEdges = getConnectedEdges([currentNode], allEdges);
        const remainingEdges = allEdges.filter(
          (edge) => !connectedEdges.includes(edge)
        );

        const createdEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }: { id: string }) => ({
            id: `${source}->${target}`,
            source,
            target,
          }))
        );

        setEdges([...remainingEdges, ...createdEdges]);
        setNodeId("");
        setIsOpen(false);
      }
    },
    [id, deleteElements]
  );

  useAutoLayout(defaultLayoutHierarchy);

  return isTabIdChanged ? (
    <Loader />
  ) : (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onNodesChange={onNodesChange}
        onConnect={onConnect}
        nodesDraggable={false}
        defaultEdgeOptions={defaultEdgeOptions}
        zoomOnDoubleClick={mode === VIEW_MODE ? true : false}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        minZoom={0.25}
        onNodeContextMenu={mode !== VIEW_MODE ? onNodeContextMenu : undefined}
        deleteKeyCode={null}
      >
        <Controls
          position="bottom-left"
          showInteractive={false}
          onFitView={onLoad}
        />
        <ContextMenu
          isOpen={isOpen}
          // anchorEle={anchorEle}
          action={[{ label: "Delete", nodeId: nodeId, effect: deleteNode }]}
          position={position}
          onMouseLeave={() =>
            setTimeout(() => {
              setIsOpen(false);
            }, 500)
          }
        />
        <MiniMap
          zoomable
          pannable
          zoomStep={3}
          nodeColor="#007bff"
          maskStrokeColor="#3F5EFF"
          maskStrokeWidth={nodesLength}
        />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
    </div>
  );
};
export default HierarchyChart;
