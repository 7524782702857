import React, { useState } from "react";
import useToast from "@/hooks/useToast";
import { deleteRedIcon} from "@/assets/icons";
import { Button } from "@/components/ui/Button";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
  } from "@/components/ui/table";
import { deleteCsvFile } from "@/state/slices/CsvSlice";
import { convertDateTime } from "@/utils/utils";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/state/store";
import DialogBox from "@/components/common/DialogBox";
type UploadsProps = {
    csvList: any;
    setCsvList: any;
    setIsRequestResolved:any;
    setLoaderTextState:any;
    handleUploadMore:any
  };
const Uploads: React.FC<UploadsProps> = ({
    csvList,
    setCsvList,
    setIsRequestResolved,
    setLoaderTextState,
    handleUploadMore
  }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { success, error} = useToast();
    const [isDialogBox, setIsDialogBox] = useState(false);
    const [dialogBoxMsg,setDialogBoxMsg] = useState('');
    const handleDeleteCsv = (e:any,_id: string) => {
      e.preventDefault();
      setIsRequestResolved(true);
      setLoaderTextState("delete")
        //@ts-ignore
        dispatch(deleteCsvFile({ id: _id })).then((action) => {
          if (deleteCsvFile.fulfilled.match(action)) {
            setTimeout(() => {
              success("File deleted successfully");
            }, 500);
            const updatedCsvList = csvList.filter((file: any) => file._id !== _id);
            setCsvList(updatedCsvList); // Update the state with the filtered list
          }
          if (deleteCsvFile.rejected.match(action)) {
            error("Error while deleting the file");
          }
        }).catch((err:any)=>{
          console.log(err);
        }).finally(()=>{
          setIsRequestResolved(false);
          setLoaderTextState("")
        })
      };

    const callDialogBox = (desc:string) => {
      setDialogBoxMsg(desc);
      setIsDialogBox(true);
    }
    return (
      <div className="uploadedData mb-3 h-[317px] rounded-md flex justify-center items-center overflow-y-auto scrollbar-custom">
        <div className="w-full h-full sm:p-3 sm:pt-0">
          <div className="rounded-md sm:border">
            <Table>
              <TableBody className="border z-51 border-stone-300">
                {Array.isArray(csvList) &&
                  csvList.length > 0 &&
                  csvList.map((file: any, index: any) => (
                    <TableRow
                      key={index}
                      className={`hover:bg-stone-300 border border-stone-300 ${
                        index == 0 ? "bg-stone-200 " : ""
                      } `}
                    >
                      <TableCell className="text-left">
                        {file.fileName}
                        <br />
                        <p className="text-xs not-italic font-medium text-gray-500">
                          Uploaded On {convertDateTime(file.updatedAt)}
                        </p>
                      </TableCell>
                      <TableCell className="text-left">
                        {file?.fileComments?.length > 50 ? (
                          <span
                            onClick={() => callDialogBox(file.fileComments)}
                            className="cursor-pointer pl-1"
                          >
                            {file.fileComments.slice(0, 50)}
                            ...
                          </span>
                        ) : (
                          file.fileComments
                        )}
                      </TableCell>
                      <TableCell className="text-right">
                        <Button
                          variant={"text"}
                          onClick={()=>handleUploadMore(true,file.fileName.trim())}
                          className="text-blue-500 mr-1 hover:bg-blue-500 hover:text-white border border-blue-500"
                        >
                          Update
                        </Button>
                        {csvList?.length > 1 && (
                          <Button
                            variant="text"
                            onClick={(e: any) => handleDeleteCsv(e, file._id)}
                            className="text-[#FF3F3F] mt-1"
                          >
                            <img
                              src={deleteRedIcon}
                              alt="Delete"
                              className="mr-2"
                            />
                            Delete
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
        {isDialogBox && (
          <DialogBox
            headerText={"File Comment"}
            DialogBoxMsg={dialogBoxMsg}
            setIsDialogBox={setIsDialogBox}
            setDialogBoxMsg={setDialogBoxMsg}
          />
        )}
      </div>
    );
  }
  export default Uploads;