import { Navigate, Outlet } from "react-router-dom";
import { getTokenFromLocalStorage, removeTokenFromLocalStorage } from "./AuthService"; // Import authentication service
import { useEffect, useState } from "react";
import { ACCESS_TOKEN, SYSTEM_ADMIN, USER_ROLES } from "@/utils/appConstants";
const isAuthenticated = () => {
  const token = getTokenFromLocalStorage(ACCESS_TOKEN);
  const user_roles = getTokenFromLocalStorage(USER_ROLES);
  const userRole = JSON.parse(user_roles ?? '[]');
  if (!userRole?.includes(SYSTEM_ADMIN)) {
    return false;
  }
  if (!token || token === "undefined" || token === null) {
    return false;
  } else {
    return true;
  }
};
function AdminRoute() {
  const [isValidToken, setIsValidToken] = useState(isAuthenticated());

  useEffect(() => {
    setIsValidToken(isAuthenticated());
    return ()=>{
      removeTokenFromLocalStorage([USER_ROLES])
    }
  }, [isValidToken]);
  if (isValidToken) {
    return <Outlet />;
  }
  return <Navigate to="/dashboard" replace />;
}

export default AdminRoute;
