import { RequestStatus } from "@/utils/appConstants";
import { Label } from "@/components/ui/Label";
import { Input } from "@/components/ui/Input";
import { Textarea } from "@/components/ui/Textarea";
import { close, url } from "@/assets/icons/index";
import React, { useState } from "react";
type SubscriberDialogProps = {
  setIsSubscribing?: any;
  methodCall?: any;
  subscriptionType?: string | null;
};

export default function SubscriberDialog({
  setIsSubscribing,
  methodCall,
  subscriptionType,
  
}: SubscriberDialogProps) {
  const yearlyPlansDetails = [
    { id: 1, planName: "1 year plan", plan: "1 Year" },
    { id: 2, planName: "3 years plan (3% discounted)", plan: "3 Years" },
  ];
  const [selectedOption, setSelectedOption] = useState(
    yearlyPlansDetails[1]?.plan
  );
  const [billingFrequency, setBillingFrequency] = useState("");
  const [reason, setReason] = useState("");
  const onClose = () => {
    setIsSubscribing(false);
  };

  const handleOnChange = (e: any, plan: any) => {
    setSelectedOption(e.target.value);
    setBillingFrequency(plan);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    } else if (event.key === "Escape") {
      setIsSubscribing(false);
    }
  };

  const handleSubmit = (e: any) => {
    let billingFrequencyPlan: any;
    if (!billingFrequency) {
      billingFrequencyPlan = selectedOption;
    } else {
      billingFrequencyPlan = billingFrequency;
    }
    methodCall(billingFrequencyPlan, reason);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50 z-20">
      <div className="bg-white rounded-lg p-6 sm:w-1/3 w-1/3 l:w-1/3 w-1/3">
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-lg font-semibold">
            Choose billing frequency for
            <span className="text-blue-600 font-semibold ml-1">
              {subscriptionType}
            </span>
          </h2>
          <button className="text-gray-500" onClick={onClose}>
            <img src={close} className="w-6 h-6 text-gray-600" alt="close" />
          </button>
        </div>
        <div className="p-4">
          {yearlyPlansDetails.map((item: any) => (
            <div className="mb-4" key={item.id}>
              <input
                type="radio"
                id={item.id}
                className="px-3 py-2 cursor-pointer"
                value={item.plan}
                checked={selectedOption === item.plan}
                onChange={(e) => handleOnChange(e, item.plan)}
                onKeyDown={handleKeyDown}
              />
              <span className="text-sm font-medium ml-3 ">{item.planName}</span>
            </div>
          ))}
        </div>
        <div>
          <Label htmlFor="reason">
            Reason for subscribing {/* <sup className="text-red-500">*</sup> */}
          </Label>
          <Textarea
            className="mt-1"
            id="reason"
            placeholder="Please share your reason(s) for subscribing (Optional)"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
        <div className="flex justify-center  mt-3 ">
          <button
            className="mr-2 px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2  bg-blue-500 text-white rounded-md hover:bg-blue-600"
            onClick={(e) => handleSubmit(e)}
            disabled={false}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
