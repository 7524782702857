import React, { useState } from 'react';
type EmailModalProps = {
  onClose: () => void;
  isOpen: boolean;
};
const EmailModal: React.FC<EmailModalProps> = ({ onClose,isOpen }) => {
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const sendEmail = () => {
    const templateParams = {
      to_email: to,
      subject: subject,
      body: body,
    };

    // Email.send({
    //     Host : "smtp.elasticemail.com",
    //     Username : "username",
    //     Password : "password",
    //     To : 'them@website.com',
    //     From : "you@isp.com",
    //     Subject : "This is the subject",
    //     Body : "And this is the body"
    // }).then(
    //   message => alert(message)
    // );
    
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 sm:w-1/2 w-2/3">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Send Email</h2>
          <button className="text-gray-500" onClick={onClose}>X</button>
        </div>
        <div className='p-4'>
          <div className="mb-4">
            <label htmlFor="to" className="block mb-1">To:</label>
            <input
              type="email"
              id="to"
              className="w-full border border-gray-300 rounded-md px-3 py-2"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="subject" className="block mb-1">Subject:</label>
            <input
              type="text"
              id="subject"
              className="w-full border border-gray-300 rounded-md px-3 py-2"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="body" className="block mb-1">Body:</label>
            <textarea
              id="body"
              className="w-full border border-gray-300 rounded-md px-3 py-2"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button className="mr-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={sendEmail}>Send</button>
          <button className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EmailModal;
