import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "../src/style/theme.css";
import { Provider } from "react-redux";
import { store } from "@/state/store.ts";
import { Toaster } from "sonner";
import { BrowserRouter as Router } from "react-router-dom";
import { StytchB2BProvider } from "@stytch/react/b2b";
import { StytchB2BUIClient } from "@stytch/vanilla-js/b2b";
import * as Sentry from "@sentry/react";
//import { BrowserTracing } from '@sentry/tracing';
const STYTCH_PUBLIC_TOKEN = import.meta.env.VITE_STYTCH_PUBLIC_TOKEN;

const API_CORE_PATH = import.meta.env.VITE_API_CORE_PATH;
const BACK_END_CORE_URL = import.meta.env.VITE_BASE_URL;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SENTRY_FRONT_END_URL = import.meta.env.VITE_SENTRY_FRONT_END_URL;
const APP_NAME = import.meta.env.VITE_APP_NAME;
const SENTRY_APP_VERSION = import.meta.env.VITE_APP_VERSION;
const stytch = new StytchB2BUIClient(STYTCH_PUBLIC_TOKEN);
Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    //Sentry.replayIntegration(),
  ],
  environment: APP_NAME || "unknown-env",
  release: `${APP_NAME}-${SENTRY_APP_VERSION}` || "unknown-release",
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //tracePropagationTargets: ["localhost", `${AUTH_URL}${AUTH_API_PATH}`],
  tracePropagationTargets: [
    //"localhost",
    `${SENTRY_FRONT_END_URL}`, // Front End API
    `${BACK_END_CORE_URL}${API_CORE_PATH}`, // Backend API
  ],
  beforeSend(event) {
    if (event.request && event.request.headers) {
      // Remove sensitive data
      delete event.request?.headers["Authorization"];
    }
    return event;
  },
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StytchB2BProvider stytch={stytch}>
    <Router>
      <Provider store={store}>
        <Sentry.ErrorBoundary fallback={<p>Something went wrong. Please refresh the page.</p>}>
          <Toaster richColors position="bottom-right" />
          <App />
        </Sentry.ErrorBoundary>
      </Provider>
    </Router>
  </StytchB2BProvider>
);
