import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "@/utils/axiosConfig";
import { RootState } from "@/state/store";
const BASE_URL = import.meta.env.VITE_BASE_URL;
const API_PATH = import.meta.env.VITE_API_CORE_PATH;
const AUTH_URL = import.meta.env.VITE_BASE_AUTH_URL;
const AUTH_API_PATH = import.meta.env.VITE_API_AUTH_PATH;
const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const loginUser = createAsyncThunk("User Login", async (data: any) => {
  try {
    const response = await instance.post(
      `${AUTH_URL}${AUTH_API_PATH}/login`,
      data
    );
    return response.data;
  } catch (error: any) {
    throw error?.response ? error.response?.data?.message[0] : error.message;
  }
});

export const getUserDetails = createAsyncThunk("User Details", async () => {
  try {
    const response = await instance.get(
      `${BASE_URL}${API_PATH}/userDetails`
    );
    return response.data;
  } catch (error: any) {
    throw error?.response ? error.response?.data?.message[0] : error.message;
  }
});

const LoginSlice = createSlice({
  name: "Login_Transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(loginUser.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getUserDetails.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default LoginSlice.reducer;
