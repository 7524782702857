// import useToast from "@/hooks/useToast";
import { getQlikContentFoPortfolio } from "@/state/slices/AdminPageSlice";
import { AppDispatch } from "@/state/store";
import instance from "@/utils/axiosConfig";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
const API_CORE = import.meta.env.VITE_API_CORE_PATH;
const BASE_CORE_URL = import.meta.env.VITE_BASE_URL;
const QLIK_HOST = import.meta.env.VITE_API_QLIK_HOST;
const QLIK_CLIENT_ID = import.meta.env.VITE_API_QLIK_CLIENT_ID;
const AiAnalytics = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const { error } = useToast();
  const { id, planType, dashboardType } = useParams<{id:string,planType:string, dashboardType:string}>();
  const [qlikData, setQlikData] = useState<any>([]);
  useEffect(() => {
    dispatch(getQlikContentFoPortfolio({ id , planType, dashboardType } as {id:string,planType:string, dashboardType:string}))
      .then((res: any) => {
        if (dashboardType === 'draft') {
          setQlikData(res.payload.data.draftDashboard);
        } else {
           setQlikData(res.payload.data.dashboard);
        }
      })
      .catch((err: any) => {
        // error("Error while fetching Dashboard data.");
        console.log(err);
      });
  }, []);
  useEffect(() => {
    // Function to dynamically load the script
    const loadScript = (
      src: string,
      dataAttributes: Record<string, string>
    ) => {
      if (!document.querySelector(`script[src="${src}"]`)) {
        const script = document.createElement("script");
        script.src = src;
        script.crossOrigin = "anonymous";
        script.type = "application/javascript";

        Object.entries(dataAttributes).forEach(([key, value]) => {
          script.setAttribute(`data-${key}`, value);
        });

        document.head.appendChild(script);
      }
    };

    // Load the Qlik script if not already loaded
    loadScript(
      "https://cdn.jsdelivr.net/npm/@qlik/embed-web-components@1/dist/index.min.js",
      {
        host: `${QLIK_HOST}`,
        "client-id": `${QLIK_CLIENT_ID}`,
        "get-access-token": "getAccessToken",
        "auth-type": "Oauth2",
      }
    );

    // Define the getAccessToken function globally if not already defined
    (window as any).getAccessToken = async () => {
      try {
        const response = await instance.post(
          `${BASE_CORE_URL}${API_CORE}/portfolios/qlik-token-exchange`
        );
        return response.data.data;
      } catch (error: any) {
        throw error.response ? error.response.data : error.message;
      }
    };

    return () => {
      // Clean up the script when the component unmounts
      const script = document.querySelector(
        `script[src="https://cdn.jsdelivr.net/npm/@qlik/embed-web-components@1/dist/index.min.js"]`
      );
      if (script) {
        document.head.removeChild(script);
      }
      delete (window as any).getAccessToken;
    };
  }, []);
  return (
    <div style={{ width: "98vw", height: "100vh" }}>
      <qlik-embed
        ui="classic/app"
        app-id={qlikData.appId}
        sheet-id={qlikData.sheet != "" ? qlikData.sheet : null}
      ></qlik-embed>
    </div>
  );
};
export default AiAnalytics;
