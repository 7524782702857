import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "@/utils/axiosConfig";
const AUTH_URL = import.meta.env.VITE_BASE_AUTH_URL;

interface PyramidConfig {
  data: any | null;
  loading: boolean;
  error: string | null | undefined;
}

const initialState: PyramidConfig = {
  data: null,
  loading: false,
  error: null,
};

export const LogoutCall = createAsyncThunk("/logout", async () => {
  try {
    const response = await instance.post(`${AUTH_URL}/api/auth/logout`);
    console.log(response);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
});

const LogoutSlice = createSlice({
  name: "logout-slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(LogoutCall.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(LogoutCall.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(LogoutCall.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default LogoutSlice.reducer;
