import {
  Accelerate_blu,
  Accelerate_Grey,
  AI_Icon_blu,
  Elite_blu,
  Pending_Approval_blu,
  Pending_Approval_Icon,
  Rapid_blu,
  Rapid_Grey,
} from "@/assets/icons";
import { BlueOutlinedClock, BlueOutlinedWork, circleBlue, work } from "@/assets/icons-color";
import {
  APPLICATION_SUBSCRIPTIONS,
  _PORTFOLIOS,
  SideNavBarTabs,
} from "@/utils/appConstants";

export const HomepageSideBar = [
  {
    _id: 1,
    _name: SideNavBarTabs.MY_SUBSCRIPTIONS,
    children: [
      {
        _id: 1,
        _name: SideNavBarTabs.RAPID,
        _icon: Rapid_blu,
        _icon_color: Rapid_Grey,
        children: [
          {
            _id: 1,
            _name: _PORTFOLIOS,
            _icon: work,
            _icon_color: AI_Icon_blu,
            children: [],
            isCollapsible: false,
            path: APPLICATION_SUBSCRIPTIONS.RAPID_PORTFOLIOS,
            selectedIcon: circleBlue,
            _counter: 0,
          },
          {
            _id: 2,
            _name: SideNavBarTabs.PENDING_APPROVALS,
            _icon: Pending_Approval_Icon,
            _icon_color: AI_Icon_blu,
            children: [],
            isCollapsible: false,
            path: APPLICATION_SUBSCRIPTIONS.RAPID_PENDING_APPROVALS,
            selectedIcon: Pending_Approval_blu,
            _counter: 0,
          },
        ],
        isCollapsible: false,
        path: SideNavBarTabs.RAPID,
      },
      {
        _id: 2,
        _name: SideNavBarTabs.ACCELERATE,
        _icon: Accelerate_blu,
        _icon_color: Accelerate_Grey,
        children: [
          {
            _id: 1,
            _name: _PORTFOLIOS,
            _icon: work,
            _icon_color: AI_Icon_blu,
            children: [],
            isCollapsible: false,
            path: APPLICATION_SUBSCRIPTIONS.ACCELERATE_PORTFOLIOS,
            selectedIcon: circleBlue,
            _counter: 0,
          },
          {
            _id: 2,
            _name: SideNavBarTabs.PENDING_APPROVALS,
            _icon: Pending_Approval_Icon,
            _icon_color: AI_Icon_blu,
            children: [],
            isCollapsible: false,
            path: APPLICATION_SUBSCRIPTIONS.ACCELERATE_PENDING_APPROVALS,
            selectedIcon: Pending_Approval_blu,
            _counter: 0,
          },
        ],
        isCollapsible: false,
        path: SideNavBarTabs.ACCELERATE,
      },
      {
        _id: 3,
        _name: SideNavBarTabs.ELITE,
        children: [
          {
            _id: 1,
            _name: _PORTFOLIOS,
            _icon: work,
            children: [],
            isCollapsible: false,
            path: APPLICATION_SUBSCRIPTIONS.ELITE_PORTFOLIOS,
            selectedIcon: circleBlue,
            _counter: 0,
          },
          {
            _id: 2,
            _name: SideNavBarTabs.PENDING_APPROVALS,
            _icon: Pending_Approval_Icon,
            children: [],
            isCollapsible: false,
            path: APPLICATION_SUBSCRIPTIONS.ELITE_PENDING_APPROVALS,
            selectedIcon: Pending_Approval_blu,
            _counter: 0,
          },
        ],
        _icon: Elite_blu,
        isCollapsible: false,
        path: "Portfolios",
      },
    ],
    onClick: "",
    isCollapsible: false,
    path: SideNavBarTabs.MY_SUBSCRIPTIONS,
  },
  {
    _id: 3,
    _name: SideNavBarTabs._MY_PORTFOLIOS,
    children: [
      {
        _id: 1,
        _name: SideNavBarTabs.PUBLISHED,
        _icon: circleBlue,
        _icon_color: BlueOutlinedWork, // work,
        children: [],
        isCollapsible: false,
        path: SideNavBarTabs.PUBLISHED,
        _counter: 0,
      },
      {
        _id: 2,
        _name: SideNavBarTabs._UN_PUBLISHED,
        _icon: Pending_Approval_blu,
        _icon_color: BlueOutlinedClock, //Pending_Approval_Icon,
        children: [],
        isCollapsible: false,
        path: SideNavBarTabs._UN_PUBLISHED,
        _counter: 0,
      },
    ],
    onClick: "",
    isCollapsible: false,
    path: "my-portfolio",
  },
];
