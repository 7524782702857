import { lemmLogo } from "@/assets/icons-color";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "@/state/slices/LoginSlice";
import { useNavigate, Link } from "react-router-dom";
import {
  saveTokenToLocalStorage,
  getTokenFromLocalStorage,
} from "@/components/common/AuthService";
import {
  ACCESS_TOKEN,
  EMAIL_REG_EXP,
  REFRESH_TOKEN,
  SYSTEM_ADMIN,
  USER_EMAIL,
  USER_NAME,
  USER_ROLES,
} from "@/utils/appConstants";
import instance from "@/utils/axiosConfig";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { AppDispatch } from "@/state/store";
import { getUserRoles } from "@/utils/utils";
const SLUG = import.meta.env.VITE_STYTCH_SLUG;
const BASE_URL = import.meta.env.VITE_BASE_URL;
const API_PATH = import.meta.env.VITE_API_CORE_PATH;
const IS_SSO_ENABLED = (import.meta.env.VITE_IS_ENABLE_SSO === "true");
interface myJwtDecodeProps extends JwtPayload {
  roles:string[]
}

const LemmataLoginPage = () => {
  const dispatch:AppDispatch = useDispatch();
  const navigate = useNavigate();
  // State to manage input values
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const savedAcccessToken = getTokenFromLocalStorage(ACCESS_TOKEN);
  const [isValidUser, setIsValidUser] = useState<string | null>(savedAcccessToken);
  // Checking Access token saved or not.
  useEffect(() => {
    if (isValidUser) {
      const userRoles = getUserRoles();
      if (userRoles.includes(SYSTEM_ADMIN)) {
        navigate("/admin");
      } else {
        navigate("/dashboard");
      }
    } 
  }, [isValidUser]);

  // Function to handle form submission
  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Reset errors
    setUsernameError("");
    setPasswordError("");

    // Validation logic
    if (!username.trim()) {
      setUsernameError("Please enter username");
      return;
    } else if (!EMAIL_REG_EXP.test(username.trim())) {
      setUsernameError("Please enter a valid username");
      return;
    }
    if (!password.trim()) {
      setPasswordError("Please enter password");
      return;
    }

    
    dispatch(loginUser({ email: username, password: password })).then(
      async (action: any) => {
        if (loginUser.fulfilled.match(action)) {
          const decoded = jwtDecode<myJwtDecodeProps>(
            action.payload.data.accessToken
          );
          saveTokenToLocalStorage(USER_ROLES, JSON.stringify(decoded.roles));
          saveTokenToLocalStorage(
            ACCESS_TOKEN,
            action.payload.data.accessToken
          );
          saveTokenToLocalStorage(
            REFRESH_TOKEN,
            action.payload.data.refreshToken
          );
          // Saving User Details in local storage.
          try {
            const response = await instance.get(
              `${BASE_URL}${API_PATH}/userDetails`
            );
            saveTokenToLocalStorage(
              USER_NAME,
              response.data.data.firstName + " " + response.data.data.lastName
            );
            saveTokenToLocalStorage(USER_EMAIL, response.data.data.email);
            decoded.roles.includes("SYSTEM_ADMIN")
              ? navigate("/admin")
              : navigate("/dashboard");
          } catch (error) {
            console.error("Error fetching user details:", error);
            setError("Error occurred while fetching user details.");
          }
        } else if (loginUser.rejected.match(action)) {
          if (action?.error?.message) {
            setError("Invalid username or password");
          } else {
            setError("Error occurred while authenticating.");
          }
        }
      }
    );
    // Clear input fields after submission
    setUsername("");
    setPassword("");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div
        className="bg-white shadow-md border rounded px-8 pt-6 pb-8 w-full max-w-sm"
        style={{ maxHeight: "calc(100vh - 2rem)" }}
      >
        <div className="text-center mb-4">
          <img
            src={lemmLogo}
            className="h-6 w-auto mx-auto mt-4"
            alt="Lemmata Logo"
          />
        </div>
       {/*  <Link to={IS_SSO_ENABLED ? `/${SLUG}` : "#"}>
          <button
            className={`mt-5 mb-5 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ${
              IS_SSO_ENABLED
                ? "bg-blue-500 hover:bg-blue-700"
                : "bg-[#CBD4EF] hover:bg-[#CBD4EF] cursor-default"
            }`}
            type="button"
          >
            Login with SSO
          </button>
        </Link>
        <div className="flex items-center justify-center mb-4">
          <div className="border-b border-gray-300 flex-grow"></div>
          <span className="text-sm font-semibold bg-white px-4 relative -mt-2 text-gray-400">
            OR
          </span>
          <div className="border-b border-gray-300 flex-grow"></div>
        </div> */}
        <h2 className="text-xl font-semibold mb-4">Login</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {error && <p className="text-red-500 text-xs italic">{error}</p>}
          <div>
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Username:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="username"
              value={username}
              onChange={(e) => {
                setUsernameError("");
                setError("");
                setUsername(e.target.value);
              }}
            />
            {usernameError && (
              <p className="text-red-500 text-xs italic">{usernameError}</p>
            )}
          </div>
          <div>
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPasswordError("");
                setError("");
                setPassword(e.target.value);
              }}
            />
            {passwordError && (
              <p className="text-red-500 text-xs italic">{passwordError}</p>
            )}
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            type="submit"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LemmataLoginPage;
