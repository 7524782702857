import React, { useEffect, useState } from "react";
import {
  closeSmallIcon,
  dragIndicatorIcon,
  newAttributeIcon,
  plusIcon,
} from "@/assets/icons/createportfolio";
import {
  ATTRIBUTE_EMPTY,
  ATTRIBUTES_ALLOWED_SPECIAL_CHARS_REG_EXP,
  EDIT_MODE,
  LENGTH,
  VIEW_MODE,
} from "@/utils/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { updateAttributes } from "@/state/slices/AttributeListSlice";
import { RootState } from "@/state/store";
import {
  truncatedString,
  checkArraysAreEqual,
  checkAttributesName,
  generateUuid,
  checkAttributeIsEmpty,
} from "@/utils/utils";
import {
  ATTRIBUTE_LENGTH_AND_SPECIAL_CHAR_MSG,
  ATTRIBUTE_LENGTH_MSG,
  ATTRIBUTE_NAME_EXIST_MSG,
  ATTRIBUTE_NAME_REQUIRED_MSG,
  ATTRIBUTE_CANT_BE_EMPTY
} from "@/utils/messageConstants";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";

import useToast from "@/hooks/useToast";
import { Help } from "../ui/Help";
import { sidebarHeaderHelpImg } from "@/assets/icons";
import { EditGray } from "@/assets/icons-color";
interface Props {
  className: any;
  attributeArray: Array<any>;
  deleteAttribute: any;
  setAttribute: any;
  isSaved?: boolean;
  isEdit?: boolean | string;
  setUndoStack?: any;
  attributeErrors?: any;
  setEmptyErrors?: any;
  setAttributeSaveButton: any;
  setIsSaved?: (isSaved: boolean) => void;
  mode?: string;
}

export const Attribute = ({
  className,
  attributeArray,
  deleteAttribute,
  setAttribute,
  isSaved,
  isEdit,
  setUndoStack,
  attributeErrors,
  setEmptyErrors,
  setAttributeSaveButton,
  setIsSaved,
  mode,
}: Props): JSX.Element => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const dispatch = useDispatch();
  const { error } = useToast();
  const [draggedItem, setDraggedItem] = useState<any>(null);
  const [customAttributes, setCustomAttributes] = useState("");
  const attributeListRedux = useSelector(
    (state: RootState) => state.attributeList.value
  );
  useEffect(() => {
    if (errors) {
      const hasNonEmptyError = Object.values(errors).some(error => error !== "");
      setAttributeSaveButton(hasNonEmptyError);
    }
  }, [errors]);
  // @ts-ignore
  const { value } =
    useSelector((state: RootState) => state.attributeList) || [];

  //@ts-ignore
  const reduxTabsData = useSelector((state) => state.hierarchy.data);
  const handleKeyDown = (event: any, index: any) => {
    if (event.target.value.length > LENGTH) {
      return;
    }
    if (event.key !== "Enter") {
      return;
    }
    const enteredValue = event.target.value;
    const editAttributeId = event.target.id;
    if (!enteredValue.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [editAttributeId]: ATTRIBUTE_NAME_REQUIRED_MSG,
      }));
      return;
    }
    if (
      enteredValue.trim() &&
      !ATTRIBUTES_ALLOWED_SPECIAL_CHARS_REG_EXP.test(enteredValue)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [editAttributeId]: ATTRIBUTE_LENGTH_AND_SPECIAL_CHAR_MSG,
      }));
      return;
    }
    const attributesList = Array.isArray(attributeArray) ? attributeArray : [];
    const attributeIndex = attributesList.findIndex(
      (attr) => attr.attributeId === editAttributeId
    );

    //checking for duplicate attribute name
    if (checkAttributesName(attributeListRedux, enteredValue,index)) {
      if(enteredValue?.trim() === "")
      {
        error(ATTRIBUTE_CANT_BE_EMPTY);
      }else{
      error(ATTRIBUTE_NAME_EXIST_MSG);}
      setAttributeSaveButton(true)
      return;
    }
    // If attribute exists, update it
    const updatedAttributes = [...attributesList];
    updatedAttributes[attributeIndex] = {
      ...updatedAttributes[attributeIndex],
      attributeName: event.target.value,
      icon: attributesList[attributeIndex]["icon"],
      description: "",
    };
    if (attributeIndex >= 0) {
      if (updatedAttributes[attributeIndex].Edit === true) {
        const updatedAttribute = { ...updatedAttributes[attributeIndex] };
        delete updatedAttribute.Edit; // Remove the Edit key
        updatedAttributes[attributeIndex] = updatedAttribute;
      }
      customAttributes && setCustomAttributes("");
      setAttribute(updatedAttributes);
      // @ts-ignore
      dispatch(updateAttributes(updatedAttributes));
    }
    setUndoStack((prevStack: any) => [...prevStack, updatedAttributes]); // Setting the undo stack after Rename the attribute
  };

  const handleOnchange = (event: any, index: number) => {
    setErrors((prevErrors) => ({ ...prevErrors, [editAttributeId]: "" }));
    const editAttributeId = event.target.id;
    const enteredValue = event.target.value;
    if (event.target.value.length > LENGTH) {
      error(ATTRIBUTE_LENGTH_MSG);
      return;
    }
    if (enteredValue.trim()) {
      setEmptyErrors((prevErrors: any) => ({
        ...prevErrors,
        [editAttributeId]: "",
      }));
    }
    if (
      enteredValue.trim() &&
      !ATTRIBUTES_ALLOWED_SPECIAL_CHARS_REG_EXP.test(enteredValue)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [editAttributeId]: ATTRIBUTE_LENGTH_AND_SPECIAL_CHAR_MSG,
      }));
      return;
    }
  };

  const hanldeRenameAttributes = (event: any) => {
    const editAttributeId = event.attributeId;
    const enteredValue = event.attributeName;
    const attributesList = Array.isArray(attributeArray) ? attributeArray : [];
    const attributeIndex = attributesList.findIndex(
      (attr) => attr.attributeId === editAttributeId
    );
    if (attributeIndex >= 0) {
      const updatedAttributes = [...attributesList];
      updatedAttributes[attributeIndex] = {
        ...updatedAttributes[attributeIndex],
        attributeName: enteredValue,
        icon: updatedAttributes[attributeIndex]["icon"],
        description: updatedAttributes[attributeIndex]["description"],
        Edit: true,
      };
      setAttribute(updatedAttributes);
    }
  };

  /* Drag and Drop Functionality */

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    setDraggedItem(attributeArray[index]);
    event.currentTarget.style.opacity = "1";
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.preventDefault();
    setDraggedItem(null);
    setUndoStack((prevStack: Array<any>) => {
      // Get the last array from prevStack
      const lastArray = prevStack[prevStack.length - 1];
      // Check if prevStack is not empty and its last array is equal to attributeArray
      if (
        prevStack.length > 0 &&
        checkArraysAreEqual(lastArray, attributeArray)
      ) {
        return [...prevStack];
      } else {
        return [...prevStack, attributeArray];
      }
    });
  };

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.opacity = "1";
  };
  const handleDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.preventDefault();
    const draggedOverItem = attributeArray[index];
    if (draggedItem === draggedOverItem) return;
    const newAttributeArray = attributeArray.filter(
      (item) => item !== draggedItem
    );

    if (index === attributeArray.length - 1) {
      newAttributeArray.push(draggedItem);
    } else {
      newAttributeArray.splice(index, 0, draggedItem);
    }
   
    setAttribute(newAttributeArray);
    // @ts-ignore
    dispatch(updateAttributes(newAttributeArray));
    event.currentTarget.classList.add("drag-over");
  };

  const handleOnBlur  = (e:any,item:any, index:number,attributesArray:any, attributesSave=false, customAttributes?:any,)=>{
    e.preventDefault();
    if (!errors[item.attributeId]) {
      if (
        checkAttributesName(
          attributeListRedux,
          e.target.value.trim(),
          index
        )
      ) {
        if(e.target.value.trim() ===""){
error(ATTRIBUTE_CANT_BE_EMPTY)
        }else{
        error(ATTRIBUTE_NAME_EXIST_MSG);}
        attributesSave && setAttributeSaveButton(true); // need to add condition.
        return;
      }
      const updatedAttributes = [...attributesArray];
      updatedAttributes[index].attributeName = e.target.value.trim();
      delete updatedAttributes[index].Edit;
      customAttributes && setCustomAttributes(""); // need to add condition.
      setAttribute(updatedAttributes);
      // @ts-ignore
      dispatch(updateAttributes(updatedAttributes));
      setUndoStack((prevStack: any) => [...prevStack, updatedAttributes]);
    }
  };
  const addAttribute = () => {
    const attributeLength = attributeArray.length;
    if (attributeLength >= 0) {
      //check if any of the attributeList item is empty or not
      if (attributeArray.map((attr: any) => attr.attributeName).includes("")) {
        error(ATTRIBUTE_EMPTY);
        return;
      }
      const updatedAttributes = [...attributeArray];
      updatedAttributes[attributeLength] = {
        ...updatedAttributes[attributeLength],
        attributeId: generateUuid(),
        attributeName: "",
        icon: newAttributeIcon,
      };
      setAttribute(updatedAttributes);
    }
  };
  return (
    <div className="w-[310px]">
      <div
        className={`flex h-[56px] items-center gap-[8px] pt-[var(--base-spacing-2)] pr-[var(--base-spacing-2)] pb-[var(--base-spacing-2)] pl-[var(--base-spacing-4)] relative bg-[color:var(--system-background-default)] ${className}`}
      >
        <div className="flex items-center gap-[8px] relative flex-1 grow">
          <div className="relative w-fit mt-[-1.00px] font-semibold font-title-medium text-black whitespace-nowrap ">
            Business Attributes
          </div>
          <Help
            className="!h-[16px] !relative !left-[unset] !w-[16px] !top-[unset]"
            help={sidebarHeaderHelpImg}
          />
        </div>
        <div className="inline-flex cursor-pointer items-center relative   flex-[0_0_auto] ">
          {!isSaved
            ? attributeArray.length < 20 && (
                <div
                  className="flex-start font-semibold text-[#6B6F80] text-md "
                  onClick={() => addAttribute()}
                >
                  <img src={plusIcon} alt="Add Attribute" className=" " />
                  <small className="text-[#6B6F80] text-md cursor-pointer ">
                    Add new
                  </small>
                </div>
              )
            : mode !== VIEW_MODE && (
                <button
                  className="bg-transparent  text-[#6B6F80] text-md font-semibold py-2 gap-2 px-3 rounded-sm flex items-center"
                  onClick={() => setIsSaved && setIsSaved(!isSaved)}
                >
                  <img src={EditGray} alt="edit" className="" />
                  <small>Edit</small>
                </button>
              )}
        </div>
      </div>
      <div className="max-h-[73vh] overflow-y-auto scrollbar-custom">
        {attributeArray?.map((item: any, index) => (
          <>
            {errors[item.attributeId] && (
              <span className="text-red-500 text-sm">
                {errors[item.attributeId]}
              </span>
            )}
            {attributeErrors[item.attributeId] && (
              <span className="text-red-500 text-sm">
                {attributeErrors[item.attributeId]}
              </span>
            )}
            <div
              key={index}
              className="outerdiv flex mb-2 px-2 mx-3 gap-[8px] h-[46px] bg-[#FFF] rounded-[8px] border-[1px] border-[solid] border-[var(--Border-Default,rgba(3,6,18,0.10))] items-center justify-center self-stretch"
              draggable={
                !isSaved && isEdit !== VIEW_MODE && isEdit !== EDIT_MODE
                  ? true
                  : false
              }
              onDragStart={(event) => handleDragStart(event, index)}
              onDragOver={(event) => {
                event.preventDefault(); // Prevent default behavior
                handleDragOver(event, index);
              }}
              onDragEnd={(event) => handleDragEnd(event)}
              onDrop={(event) => handleDrop(event, index)}
            >
              {!isSaved && isEdit !== EDIT_MODE && (
                <div className="cursor-move">
                  <img src={dragIndicatorIcon} alt="Dragicon" />
                </div>
              )}
              <div
                className="flex text-center gap-[8px] w-[70%]"
                onDoubleClick={() => {
                  if (isEdit !== VIEW_MODE && !item.Edit && !isSaved) {
                    if (checkAttributeIsEmpty(attributeArray)) {
                      hanldeRenameAttributes(item)
                    } else {
                      error(ATTRIBUTE_EMPTY);
                     return undefined
                    }
                  }
                  else {return undefined }
                }
                }
              >
                <img src={item.icon} alt="navicon" />

                {item.attributeName ? (
                  item.Edit ? (
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id={item.attributeId}
                      type="text"
                      value={item.attributeName}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      autoFocus
                      onChange={(e) => {
                        handleOnchange(e, index);
                        if (e.target.value.length <= LENGTH) {
                          const updatedAttributes = [...attributeArray];
                          updatedAttributes[index].attributeName =
                            e.target.value.trim();
                          setAttribute(updatedAttributes);
                        } else {
                          error(ATTRIBUTE_LENGTH_MSG);
                          return;
                        }
                      }}
                      onBlur={(e) =>
                        handleOnBlur(e, item, index, attributeArray)
                      }
                    />
                  ) : (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <span>{truncatedString(item.attributeName)}</span>
                        </TooltipTrigger>
                        <TooltipContent className="text-gray-600 font-normal text-sm mb-2">
                          <strong>{item.attributeName}</strong>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )
                ) : (
                  <div>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id={item.attributeId}
                      type="text"
                      placeholder={"Attribute Name"}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      value={customAttributes}
                      onChange={(e) => {
                        if (e.target.value.length > LENGTH) {
                          error(ATTRIBUTE_LENGTH_MSG);
                          return;
                        } else {
                          handleOnchange(e, index);
                          setCustomAttributes(e.target.value);
                        }
                      }}
                      autoFocus
                      onBlur={(e) =>
                        handleOnBlur(
                          e,
                          item,
                          index,
                          attributeArray,
                          true,
                          customAttributes
                        )
                      }
                    />
                  </div>
                )}
              </div>

              {deleteAttribute && (
                <div
                  className="cursor-pointer "
                  onClick={() => deleteAttribute(item.attributeId)}
                >
                  {!isSaved &&
                    isEdit !== EDIT_MODE &&
                    reduxTabsData[0].nodes.length === 0 && (
                      <span>
                        <img src={closeSmallIcon} alt="Close" />
                      </span>
                    )}
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
