import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "@/utils/axiosConfig";
import { RootState } from "@/state/store";
const API_PATH = import.meta.env.VITE_API_CORE_PATH;

type AdminDataProps = {
  portfolios: any[];
  portfolio_qlik_content: any[];
  statusUpdate: string;
  all_qlik_content: any[];
  allPortfolios: { data: Record<string, any>[] };
};
interface AdminPageProps {
  data: AdminDataProps;
  loading: boolean;
  error: string | null | undefined;
}

const initialState: AdminPageProps = {
  data: {
    portfolios: [],
    portfolio_qlik_content: [],
    statusUpdate: "",
    all_qlik_content: [],
    allPortfolios: { data: [] },
  },
  loading: false,
  error: null,
};
type updateStatusOfPortfolioProps = {
  id: string;
  data: {
    "status": string
  }
}
export const getProcessingPortfolios = createAsyncThunk(
  "/admin/processing",
  async () => {
    try {
      const response = await instance.get(`${API_PATH}/admin/processing`);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
export const updateStatusOfPortfolio = createAsyncThunk(
  "/core/admin/:id/status",
  async ({ id, data }: updateStatusOfPortfolioProps) => {
    try {
      const response = await instance.patch(
        `${API_PATH}/admin/${id}/status`,
        data
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
export const addAiConfiguration = createAsyncThunk(
  "/admin-qlik-integration/portfolioID",
  async ({ id, dashboardType, data }: { id: string; dashboardType:string, data: any }) => {
    try {
      let dType="";
      if (dashboardType=="Live") {
        dType = "/live"
      }
      if (dashboardType=="Draft") {
        dType = "/draft"
      }
      const response = await instance.post(
        `${API_PATH}/admin/${id}/configure-dashboard${dType}`,
         data
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
// get the qlik_content_for one portfolio based on portfolio_id and plan type
export const getQlikContentFoPortfolio = createAsyncThunk(
  "/admin/qlik-content",
  async ({ id, planType }: { id: string; planType: string }) => {
    try {
      const response = await instance.post(
        `${API_PATH}/portfolios/${id}/qlik-content/${planType}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
// admin page
export const getAdminPageQlikData = createAsyncThunk(
  "/admin/portfolioId/qlik-content",
  async ({ id }: { id: string }) => {
    try {
      const response = await instance.get(
        `${API_PATH}/admin/${id}/qlik-content`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
// admin page all portfolios
export const getAdminAllPortfolios = createAsyncThunk(
  "core/admin/portfolios",
  async () => {
    try {
      const response = await instance.get(`${API_PATH}/admin/portfolios`);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

const AdminPageSlice = createSlice({
  name: "pyramid/configuration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProcessingPortfolios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProcessingPortfolios.fulfilled, (state, action) => {
        state.loading = false;
        state.data.portfolios = action.payload.data;
      })
      .addCase(getProcessingPortfolios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateStatusOfPortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStatusOfPortfolio.fulfilled, (state, action) => {
        state.loading = false;
        state.data.statusUpdate = action.payload;
      })
      .addCase(updateStatusOfPortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
      // all portfolios builders
      builder
      .addCase(getAdminAllPortfolios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminAllPortfolios.fulfilled, (state, action) => {
        state.loading = false;
        state.data.allPortfolios = action.payload;
      })
      .addCase(getAdminAllPortfolios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
      // AI Configuration builders
    builder
      .addCase(addAiConfiguration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAiConfiguration.fulfilled, (state, action) => {
        state.loading = false;
        state.data.statusUpdate = action.payload;
      })
      .addCase(addAiConfiguration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getQlikContentFoPortfolio.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getQlikContentFoPortfolio.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getQlikContentFoPortfolio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getAdminPageQlikData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminPageQlikData.fulfilled, (state, action) => {
        state.loading = false;
        state.data.all_qlik_content = action.payload;
      })
      .addCase(getAdminPageQlikData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
      
  },
});

export default AdminPageSlice.reducer;
export const AdminPageSelector = (state: RootState) => state.adminPage.data;
export const AdminQlikSelector = (state: RootState) => state.adminPage.data;
export const AllPortfolios = (state: RootState) =>
  state.adminPage.data.allPortfolios;
