import {
  Accelerate_blu,
  Elite_blu,
  Rapid_blu,
  groupPlusBlue,
  infoInfo,
} from "@/assets/icons";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { Card, CardHeader } from "@/components/ui/Card";
import {
  submitApproval,
  getSubscribeList,
} from "@/state/slices/SubscriptionSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getUpdateStatus,
  groupSubscriptionByProducts,
  SubscriptionListProps,
} from "@/utils/utils";
import useToast from "@/hooks/useToast";
import { pendingPortfolios } from "@/state/slices/PortfolioSliceUpdate";
import { AppDispatch } from "@/state/store";
import EmptyScreen from "@/components/common/EmptyScreen";
import {
  SideNavBarTabs,
  APPROVAL_REQUEST_TYPE,
  SUBSCRIBED,
  SUBSCRIBE,
  LemmataProducts,
} from "@/utils/appConstants";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/Dropdown-menu";
import SubscriberDialog from "@/components/subscribe/SubscriberDialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/Accordion";
import TabPillStatus from "@/utils/TabPillStatus";
import { LemmataAILogo } from "@/assets/icons-color";
import Loader from "@/components/common/Loader";

const NewSubscription = ({ setIsLoading, isLoading, updateCounter }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const { success, error } = useToast();
  const [subscriptionList, setSubscriptionList] = useState<any>([]);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedPlanType, setSelectedPlanType] = useState<string>("");
  const menuItemStyle = "cursor-pointer gap-3 py-5";
  const [sortedBasedOnProduct, setSortedBasedOnProduct] = useState<
    Record<string, SubscriptionListProps[]>
  >({});
  const subscriptionTypesMunu = [
    {
      _id: 1,
      name: "Rapid ",
      styles: menuItemStyle,
      icon: Rapid_blu,
      imgAlt: "Rapid ",
      onClick: (item: any) => {
        handleSubscribe(item, SideNavBarTabs.RAPID);
        setIsSubscribing(true);
      },
      tooltipData: "Use your as-is data for quick analytics",
    },
    {
      _id: 2,
      name: "Accelerate ",
      styles: menuItemStyle,
      icon: Accelerate_blu,
      imgAlt: "Accelerate ",
      onClick: (item: any) => {
        handleSubscribe(item, SideNavBarTabs.ACCELERATE);
        setIsSubscribing(true);
      },
      tooltipData:
        "Everything in Rapid, plus AI insights and Business forecasting",
    },
    {
      _id: 3,
      name: "Elite",
      value: "/portfolio",
      styles: menuItemStyle,
      icon: Elite_blu,
      imgAlt: "Elite ",
      onClick: (item: any) => {
        handleSubscribe(item, SideNavBarTabs.ELITE);
        setIsSubscribing(true);
      },
      tooltipData:
        "Everything in Accelerate, plus building custom roadmaps and Business Modeling",
    },
  ];

  const handleSubscribe = async (item: any, subscriptionType: string) => {
    setSelectedPlanType(subscriptionType);
    setSelectedItem(item);
    setIsSubscribing(true);
  };

  const sendSubscribeRequest = async (
    billingFrequencyPlan: string,
    reason: string
  ) => {
    if (!selectedItem) return;

    const submitForApproval = {
      portfolioId: selectedItem._id,
      planType: selectedPlanType,
      requestType: APPROVAL_REQUEST_TYPE.SUBSCRIBE_PORTFOLIO,
      billingFrequency: billingFrequencyPlan,
      reason: reason,
    };

    try {
      const action = await dispatch(
        submitApproval({ payload: submitForApproval })
      );
      if (action.meta.requestStatus === "fulfilled") {
        setSubscriptionList((prevData: any[]) =>
          prevData.map((item) =>
            item._id === selectedItem._id
              ? { ...item, isSubmittedForApproval: true }
              : item
          )
        );
        success("Subscription request submitted successfully");
        // Updating the counter after successfully subscribing to a porfolio .
        updateCounter();
      } else {
        throw new Error("Request failed");
      }
    } catch {
      error("Internal server error while submitting request");
    } finally {
      dispatch(pendingPortfolios(selectedPlanType));
      setIsSubscribing(false);
    }
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const action = await dispatch(getSubscribeList());
      if (getSubscribeList.fulfilled.match(action)) {
        setSubscriptionList(action.payload.data || []);
        setIsLoading(false);
      }
    };

    fetchSubscriptions();
  }, [dispatch]);

  useEffect(() => {
    const sortedSubscriptionData = groupSubscriptionByProducts(subscriptionList)
    setSortedBasedOnProduct(sortedSubscriptionData)
  }, [subscriptionList])
  return (
    <div className="ml-4 mr-4 align-middle">
      {!isLoading ? (
        Object.entries(sortedBasedOnProduct).length > 0 ? (
          <Card className="w-full p-4 mr-5 overflow-y-auto">
            <Accordion
              type="multiple"
              className="w-full"
              defaultValue={["SalesAccelerator"]}
            >
              {LemmataProducts.map((product) => {
                return (
                  <AccordionItem
                    value={product.productName}
                    key={product.productName}
                    className="border-none"
                  >
                    <AccordionTrigger className="ml-3">
                      <div className="flex flex-row">
                        <div className="flex">
                          <Badge className="border border-blue-500 bg-white text-blue-500 hover:bg-transparent">
                            <span className="font-semibold text-lg">
                              {product.productName}
                            </span>
                          </Badge>
                        </div>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      {sortedBasedOnProduct[product.productName]?.length > 0 ? (
                        sortedBasedOnProduct[product.productName]?.map(
                          (item) => {
                            return (
                              <Card
                                className="w-full mb-4 overflow-y-auto"
                                key={item._id}
                              >
                                <CardHeader className="p-4">
                                  <div className="wrapper flex-between">
                                    <div className="flex-between gap-3">
                                      <div>
                                        <img
                                          src={LemmataAILogo}
                                          alt="avatar"
                                          className="w-[48px] h-[48px]"
                                        />
                                      </div>
                                      <div className="flex flex-col ">
                                        <div className="flex-start gap-2 p-1">
                                          <span className="text-black font-semibold text-base">
                                            {item.portfolioName}
                                          </span>
                                          {item.owners.map(
                                            (owner: any, index: number) => (
                                              <span
                                                className="text-gray text-xs"
                                                key={index}
                                              >
                                                {owner.firstName}&nbsp;
                                                {owner.lastName}
                                              </span>
                                            )
                                          )}
                                          {item.subscriptionPlanType && (
                                            <TabPillStatus
                                              status={item.subscriptionPlanType}
                                            />
                                          )}
                                        </div>
                                        <div className="flex-start gap-2">
                                          <span className="text-gray text-xs">
                                            {getUpdateStatus(item.updatedAt)}
                                          </span>
                                          <div className="flex-start gap-2"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-between">
                                      <div className="flex-start">
                                        <div>
                                          {item?.isSubscribed ||
                                          item?.isSubmittedForApproval ? (
                                            <TooltipProvider>
                                              <Tooltip>
                                                <TooltipTrigger>
                                                  <small className="text-green-500 font-bold">
                                                    {SUBSCRIBED}
                                                  </small>
                                                </TooltipTrigger>
                                                <TooltipContent className="text-gray-600 font-normal text-sm mb-2">
                                                  <strong>{SUBSCRIBED}</strong>
                                                </TooltipContent>
                                              </Tooltip>
                                            </TooltipProvider>
                                          ) : (
                                            <DropdownMenu>
                                              <DropdownMenuTrigger className="flex">
                                                <TooltipProvider>
                                                  <Tooltip>
                                                    <TooltipTrigger>
                                                      <img
                                                        src={groupPlusBlue}
                                                        className="cursor-pointer"
                                                        alt="subcriber_plus"
                                                      />
                                                    </TooltipTrigger>
                                                    <TooltipContent className="text-gray-600 font-normal text-sm mb-2">
                                                      <strong>
                                                        {SUBSCRIBE}
                                                      </strong>
                                                    </TooltipContent>
                                                  </Tooltip>
                                                </TooltipProvider>
                                              </DropdownMenuTrigger>
                                              <DropdownMenuContent className="mt-0 mr-14 w-[200px]">
                                                {subscriptionTypesMunu.map(
                                                  ({
                                                    _id,
                                                    name,
                                                    onClick,
                                                    icon,
                                                    imgAlt,
                                                    styles,
                                                    tooltipData,
                                                  }) => (
                                                    <DropdownMenuItem
                                                      onClick={(_e) =>
                                                        onClick(item)
                                                      }
                                                      key={_id}
                                                      className={styles}
                                                    >
                                                      <img
                                                        src={icon}
                                                        alt={imgAlt}
                                                      />
                                                      <span>{name}</span>
                                                      <TooltipProvider>
                                                        <Tooltip>
                                                          <TooltipTrigger>
                                                            <img
                                                              src={infoInfo}
                                                              alt="info"
                                                            />
                                                          </TooltipTrigger>
                                                          <TooltipContent className="text-gray-600 font-normal text-sm mb-2">
                                                            <span>
                                                              {tooltipData}
                                                            </span>
                                                          </TooltipContent>
                                                        </Tooltip>
                                                      </TooltipProvider>
                                                    </DropdownMenuItem>
                                                  )
                                                )}
                                              </DropdownMenuContent>
                                            </DropdownMenu>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CardHeader>
                              </Card>
                            );
                          }
                        )
                      ) : (
                        <span className="ml-3 pl-2 text-md font-semibold">
                          No portfolios to subscribe.
                        </span>
                      )}
                    </AccordionContent>
                    <hr></hr>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Card>
        ) : (
          <div className="flex justify-center items-center min-h-[70vh]">
            <EmptyScreen incomingRequest={SideNavBarTabs.SUBSCRIBE} />
          </div>
        )
      ) : (
        <div className="flex justify-center items-center min-h-[70vh]">
          <Loader />
        </div>
      )}

      {isSubscribing && (
        <SubscriberDialog
          subscriptionType={selectedPlanType}
          methodCall={sendSubscribeRequest}
          setIsSubscribing={setIsSubscribing}
        />
      )}
    </div>
  );
};

export default NewSubscription;
