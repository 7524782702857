import React from "react";
import { Card, CardHeader } from "@/components/ui/Card";
import { LemmataAILogo, subcriber } from "@/assets/icons-color/index";
import { getOwnerNames, getUpdateStatus } from "@/utils/utils";
import { APPROVAL_REQUEST_TYPE, getProductDisplayName, SUBSCRIPTION_MSG } from "@/utils/appConstants";
import { Badge } from "../ui/badge";
type PortfolioItem = {
  portfolioName: string;
  owners: any;
  requestedDate?: string;
  requestType?: string;
  subscribers: any;
  productCode: string;
};

type PortfolioCardProps = {
  portfolioItem: PortfolioItem;
};
const PendingSubscriptionsCard: React.FC<PortfolioCardProps> = ({
  portfolioItem,
}) => {
  const {
    portfolioName,
    owners,
    requestedDate,
    requestType,
    subscribers,
    productCode
  } = portfolioItem;

  return (
    <Card className="w-full mb-4 overflow-y-auto">
      {/* portfolio section */}
      <CardHeader className="p-4">
        <div className="wrapper flex-between">
          {/* Left section */}
          <div className="flex-between gap-3">
            <div>
              <img
                src={
                  LemmataAILogo
                }
                alt="avatar"
                className="w-[48px] h-[48px]"
              />
            </div>

            {/* Portfolio name owners name updated */}
            <div className="flex flex-col ">
              <div className="flex-start gap-2 p-1">{productCode ? (
                <Badge className="border border-blue-500 bg-white text-blue-500 bg-opacity-85 hover:bg-transparent">
                  <small className="text-sm">{getProductDisplayName(productCode) ?? ''}</small>
                </Badge>
              ) : null}
                <h4 className="font-semibold">
                  {portfolioName ?? ''}
                </h4>
                <span className="text-gray opacity-90 text-sm">
                  by&nbsp;<span>{getOwnerNames(owners)}</span>
                </span>

              </div>

              <div className="flex-start gap-2 ml-3">
                <span className="text-gray text-xs">
                  Request Type:
                  {requestType == APPROVAL_REQUEST_TYPE.UPGRADE_PORTFOLIO_SUBSCRIPTION
                    ? SUBSCRIPTION_MSG.UPGRADE_PORTFOLIO_SUBSCRIPTION
                    : SUBSCRIPTION_MSG.SUBSCRIBE_PORTFOLIO}
                </span>
              </div>
              <div className="flex-start gap-2 ml-3">
                <span className="text-gray text-xs">
                  {getUpdateStatus(requestedDate).replace(
                    "Updated",
                    "Requested"
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="flex-between">
            {subscribers?.length >= 0 && (
              <div className="flex-start gap-2">
                <img src={subcriber} alt="subscriber" />
                <span className="text-gray text-xs">
                  {subscribers.length} subscribers
                </span>
              </div>
            )}
          </div>
        </div>
      </CardHeader>
    </Card>
  );
};

export default PendingSubscriptionsCard;
