import { Navigate, Outlet } from "react-router-dom";
import { getTokenFromLocalStorage } from "./AuthService"; // Import authentication service
import { useEffect, useState } from "react";

const isAuthenticated = () => {
  const token = getTokenFromLocalStorage("accessToken");
  if (!token || token === "undefined" || token === null) {
    return false;
  } else {
    return true;
  }
};
function PrivateRoute() {
  const [isValidToken, setIsValidToken] = useState(isAuthenticated());

  useEffect(() => {
    setIsValidToken(isAuthenticated());
  }, [isValidToken]);

  if (isValidToken) {
    return <Outlet />;
  }
  return <Navigate to="/" replace />;
}

export default PrivateRoute;
