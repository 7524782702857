import React, { useEffect, useLayoutEffect, useState } from "react";
import PortfolioCard from "../components/homepage/PortfolioCard";
import {
  PortfolioProps,
  sortPortfolios,
  StatusTagType,
} from "@/utils/utils";
import PendingSubscriptionsCard from "@/components/homepage/PendingSubscriptionsCard";
import ManageUploadsModal from "@/components/custom-components/upload-csv/ManageUploadsModal";
import {
  APPLICATION_SUBSCRIPTIONS,
  CONFIRMBOX_MSG,
  CONFIRMBOX_TYPE,
  SORTINGDATA,
  UN_SUBSCRIBER,
  UNPUBLISHED,
} from "@/utils/appConstants";
import ConfirmationBox from "@/components/common/ConfirmationBox";
import UpgradeDialog from "@/components/subscribe/UpgradeDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/Dropdown-menu";
import { Button } from "@/components/ui/Button";
import { SortArrows } from "@/assets/icons";
import {
  Sort_a_z,
  Sort_z_a,
  SortCalendarDown,
  SortCalendarUp,
} from "@/assets/icons/sortIcons";
type Owner = {
  _id?: string;
  firstName?: string;
  lastName?: string;
};
export type PortfolioItem = {
  _id: string;
  portfolioName?: string;
  dashboardName?: string;
  createdBy: string;
  updatedAt: string;
  subscribers: number;
  status: StatusTagType;
  isPublished?: boolean;
  embedHtml?: string;
  permissions?: any;
  owners: Owner[];
  requestedDate?: string;
  requestType?: string;
  productCode: string;
  createdAt: string;
  portfolioCreator:boolean;
  isLiveContentAvailable:boolean;
  isDraftContentAvailable:boolean
  qlik:any;
  permiumInsigths: {
    _id: string;
    logo: string;
    insideName: string;
    createdBy: string;
    updatedAt: string;
    status: StatusTagType;
  }[];
};

type Props = {
  portfolio: PortfolioItem[];
  requestName: string | null;
  setSelectedMenuItem: () => void;
  updateCounter: () => void;
};
export type PortfolioSortItemProps = {
  id: number;
  name: string;
  asc_icon: string;
  des_icon: string;
  asc_onClick: (btnName: string) => void;
  des_onClick: (btnName: string) => void;
  incomingRequestData?:string
};

const PortfolioPage: React.FC<Props> = ({
  portfolio,
  requestName,
  setSelectedMenuItem,
  updateCounter,
  incomingRequestData
}:any) => {
  const [isManageUploadModalOpen, setIsManageUploadModalOpen] =
    React.useState(false);
  const [portfolioData, setPortfolioData] = useState<any>();
  const [portfolioIdForContextMenu, setPortfolioIdForContextMenu] =
    useState("");
  const [isUnsubscribeConfirmModelOpen, setIsUnsubscribeConfirmModelOpen] =
    useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [requestType, setRequestType] = useState("");
  // select highlight sort
  const [selectedSort, setSelectedSort] = useState<string>("");
  const [sortButton, setSortButton] = useState<string>("");

  /* Removing y-scrolling while Manage Manual Uploads popup open */
  useLayoutEffect(() => {
    const body = document.body;
    body.style.overflow = isManageUploadModalOpen ? "hidden" : "visible";
  }, [isManageUploadModalOpen]);

  useEffect(() => {
    if (portfolio.length > 0) {
      portfolioSubsciberList();
    }
  }, [portfolio]);

  const portfolioSubsciberList = async () => {
    setPortfolioData(portfolio);
  };
  const portfolioSortList = [
    {
      id: 1,
      name: "Portfolio Name",
      asc_icon: Sort_a_z,
      des_icon: Sort_z_a,
      asc_onClick: (btnName: string) => {
        setPortfolioData(sortPortfolios(portfolio, SORTINGDATA.portfolio.asc));
        setSelectedSort(SORTINGDATA.portfolio.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName: string) => {
        setPortfolioData(sortPortfolios(portfolio, SORTINGDATA.portfolio.desc));
        setSelectedSort(SORTINGDATA.portfolio.name);
        setSortButton(btnName);
      },
    },
    {
      id: 2,
      name: "Product Name",
      asc_icon: Sort_a_z,
      des_icon: Sort_z_a,
      asc_onClick: (btnName:string) => {
        setPortfolioData(
          sortPortfolios(portfolio, SORTINGDATA.productNames.asc)
        );
        setSelectedSort(SORTINGDATA.productNames.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName:string) => {
        setPortfolioData(
          sortPortfolios(portfolio, SORTINGDATA.productNames.desc)
        );
        setSelectedSort(SORTINGDATA.productNames.name);
        setSortButton(btnName);
      },
    }
  ]
   if(requestName != APPLICATION_SUBSCRIPTIONS.RAPID_PENDING_APPROVALS && requestName != APPLICATION_SUBSCRIPTIONS.ACCELERATE_PENDING_APPROVALS && requestName != APPLICATION_SUBSCRIPTIONS.ELITE_PENDING_APPROVALS 
   ) {portfolioSortList.push({
      id: 5,
      name: "Created Date",
      asc_icon: SortCalendarUp,
      des_icon: SortCalendarDown,
      asc_onClick: (btnName:string) => {
        setPortfolioData(
          sortPortfolios(portfolio, SORTINGDATA.createdDate.asc)
        );
        setSelectedSort(SORTINGDATA.createdDate.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName:string) => {
        setPortfolioData(
          sortPortfolios(portfolio, SORTINGDATA.createdDate.desc)
        );
        setSelectedSort(SORTINGDATA.createdDate.name);
        setSortButton(btnName);
      },
    },
    {
      id: 6,
      name: "Updated Date",
      asc_icon: SortCalendarUp,
      des_icon: SortCalendarDown,
      asc_onClick: (btnName:string) => {
        setPortfolioData(
          sortPortfolios(portfolio, SORTINGDATA.updatedDate.asc)
        );
        setSelectedSort(SORTINGDATA.updatedDate.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName:string) => {
        setPortfolioData(
          sortPortfolios(portfolio, SORTINGDATA.updatedDate.desc)
        );
        setSelectedSort(SORTINGDATA.updatedDate.name);
        setSortButton(btnName);
      },
    })}
  if (requestName == UNPUBLISHED) {
    portfolioSortList.push({
      id: 4,
      name: "Status",
      asc_icon: Sort_a_z,
      des_icon: Sort_z_a,
      asc_onClick: (btnName:string) => {
        setPortfolioData(sortPortfolios(portfolio, SORTINGDATA.status.asc));
        setSelectedSort(SORTINGDATA.status.name);
        setSortButton(btnName);
      },
      des_onClick: (btnName:string) => {
        setPortfolioData(sortPortfolios(portfolio, SORTINGDATA.status.desc));
        setSelectedSort(SORTINGDATA.status.name);
        setSortButton(btnName);
      },
    });
  }
  return (
    <div
      className="p-4 max-h-[calc(100vh-74px)] mb-5 overflow-y-auto scrollbar-custom"
      key={`portfolio-section-${requestName}`}
    >
      <div className="mb-5 flex flex-row justify-end gap-3">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              <img
                src={SortArrows}
                alt="sort"
                width="20px"
                height="20px"
                style={{ transform: "rotate(180deg)" }}
              />
              &nbsp; Sort
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="p-3">
            <DropdownMenuGroup>
              {portfolioSortList.map((item: PortfolioSortItemProps) => {
                return (
                  <div
                    className={`flex flex-row items-center justify-between gap-4 px-1 ${
                      selectedSort == item.name
                        ? " text-blue-600 font-bold"
                        : ""
                    }`}
                    key={item.name}
                  >
                    <span>{item.name}</span>
                    <div className='gap-2'>
                      <Button
                        name={item.name + "_asc"}
                        onClick={() => item.asc_onClick(item.name + "_asc")}
                        className={`border-0 bg-transparent items-center mr-1 hover:bg-blue-50 ${
                          sortButton === item.name + "_asc" ? "bg-blue-100" : ""
                        }`}
                      >
                        <img
                          src={item.asc_icon}
                          alt="sort"
                          width="20px"
                          height="20px"
                        />
                      </Button>
                      <Button
                        name={item.name + "_desc"}
                        onClick={() => item.des_onClick(item.name + "_desc")}
                        className={`border-0 bg-transparent items-center hover:bg-blue-50 ${
                          sortButton === item.name + "_desc"
                            ? "bg-blue-100"
                            : ""
                        }`}
                      >
                        <img
                          src={item.des_icon}
                          alt="sort"
                          width="20px"
                          height="20px"
                        />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {portfolioData &&
        portfolioData?.map((item: PortfolioProps) => {
          return requestName ==
            `${APPLICATION_SUBSCRIPTIONS.RAPID_PENDING_APPROVALS}` ||
            requestName ==
              `${APPLICATION_SUBSCRIPTIONS.ACCELERATE_PENDING_APPROVALS}` ||
            requestName ==
              `${APPLICATION_SUBSCRIPTIONS.ELITE_PENDING_APPROVALS}` ? (
            <PendingSubscriptionsCard key={item._id} portfolioItem={item} />
          ) : (
            <PortfolioCard
              key={item._id}
              portfolioItem={item}
              setIsManageUploadModalOpen={setIsManageUploadModalOpen}
              permissionsArray={item.permissions}
              requestName={requestName}
              setPortfolioIdForContextMenu={setPortfolioIdForContextMenu}
              setSelectedMenuItem={setSelectedMenuItem}
              setIsUpgrading={setIsUpgrading}
              setRequestType={setRequestType}
              incomingRequestData={incomingRequestData}
            />
          );
        })}
      <ManageUploadsModal
        isOpen={isManageUploadModalOpen}
        portfolioId={portfolioIdForContextMenu}
        onClose={() => setIsManageUploadModalOpen(!isManageUploadModalOpen)}
      />
      <ConfirmationBox
        isConfirmModelOpen={isUnsubscribeConfirmModelOpen}
        portfolioId={portfolioIdForContextMenu}
        setIsUnsubscribeConfirmModelOpen={setIsUnsubscribeConfirmModelOpen}
        requestType={CONFIRMBOX_TYPE.UNSUBSCRIBE}
        headerText={UN_SUBSCRIBER}
        confirmBoxMsg={CONFIRMBOX_MSG.UNSUBSCRIBE}
      />
      {isUpgrading && (
        <UpgradeDialog
          setIsUpgrading={setIsUpgrading}
          portfolioId={portfolioIdForContextMenu}
          requestType={requestType}
          updateCounter={updateCounter}
        />
      )}
    </div>
  );
};

export default PortfolioPage;
