import { useEffect } from 'react';
import { toast } from 'sonner';

const useToast = () => {
  // Define the functions for different types of toasts and promise-based notifications
  const showToast = {
    success: (message: string) => {
      toast.dismiss();
      toast.success(message);
    },
    error: (message: string) => {
      toast.dismiss();
      toast.error(message);
    },
    info: (message: string) => {
      toast.dismiss();
      toast.info(message);
    },
    promise: (promise: () => Promise<any>, options: any) => {
      toast.dismiss();
      toast.promise(promise, options);
    },
    warning:  (message: string) => {
      toast.dismiss();
      toast.warning(message);
    },
  };

  useEffect(() => {
    // No need to return anything from useEffect
    // Just keep it to ensure this effect runs only once
  }, []);

  // Return the object with functions to show different types of toasts
  return showToast;
};

export default useToast;
