// src/components/InactivityWarning.tsx
import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/Button";

interface InactivityWarningProps {
  isOpen: boolean;
  countdown: number;
  onStayLoggedIn: () => void;
  handleLogout: () => void;
  setIsWarningOpen: any;
}

export const InactivityWarning: React.FC<InactivityWarningProps> = ({
  isOpen,
  countdown,
  onStayLoggedIn,
  handleLogout,
  setIsWarningOpen,
}) => {
  const [timeoutCounter, setTimeoutCounter] = useState(countdown);
  useEffect(() => {
    if (isOpen) {
      setTimeoutCounter(countdown);
    }
  }, [isOpen, countdown]);

  useEffect(() => {
    let handleTimeout: NodeJS.Timeout;
    if (isOpen && timeoutCounter > 0) {
      handleTimeout = setInterval(() => {
        setTimeoutCounter((prev) => {
          if (prev <= 1) {
            clearInterval(handleTimeout);
            setIsWarningOpen(false);
            setTimeout(() => {
              handleLogout();
            }, 200);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(handleTimeout);
    };
  }, [isOpen, timeoutCounter]); 

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent onEscapeKeyDown={(e) => e.preventDefault()}>
        <AlertDialogHeader>
          <AlertDialogTitle>Session Timeout Warning</AlertDialogTitle>
          <AlertDialogDescription className="space-y-2">
            <p>You have been inactive for 15 minutes.</p>
            <p className="font-medium text-destructive">
              For your security, you will be logged out in {timeoutCounter}{" "}
              seconds.
            </p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button
              variant="default"
              onClick={onStayLoggedIn}
              className="bg-primary hover:bg-primary/90"
            >
              Stay Logged In
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
