import { formatToLocalDate, functionToDownloadFile, truncatedString } from "@/utils/utils"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/Tooltip";
import { DownloadBlue } from "@/assets/icons";
import instance from "@/utils/axiosConfig";
import useToast from "@/hooks/useToast";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Button } from "../ui/Button";
import { useState } from "react";
import Loader from "../common/Loader";
import DialogBox from "../common/DialogBox";

const API_PATH = import.meta.env.VITE_API_CORE_PATH;

type fileTypeProps = {
    fileName: string,
    updatedAt: string,
    _id: string,
    fileComments: string
}
type DownloadComponentProps = {
    handleConfigure: (check:boolean) => void,
    csvList: fileTypeProps[]
}
const DownloadComponent = ({ handleConfigure, csvList }: DownloadComponentProps) => {
    const {  error } = useToast();
    const [isDownloading, setIsDownloading] = useState(false)
    const [isDialogBox, setIsDialogBox] = useState(false);
    const [dialogBoxMsg, setDialogBoxMsg] = useState('');
    const callDialogBox = (desc: string) => {
        setDialogBoxMsg(desc);
        setIsDialogBox(true);
    }

    const handleDownload = async (fileId: string) => {
        setIsDownloading(true);
        try {
            const response:any = await instance.get(`${API_PATH}/admin/download/data-file/${fileId}`, { responseType: 'blob' }); // Ensure binary data is handled
            const contentDisposition =  response?.headers?.get('Content-Disposition');
            let filename = 'downloaded_file';
    
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+?)"/);
                if (match && match[1]) {
                    filename = match[1];
                }
            }
    
            functionToDownloadFile(response.data, filename);
        } catch (err:any) {
            error("The file could not be downloaded due to an unexpected issue. Please try again later.");
        } finally {
            setIsDownloading(false);
        }
    };
    
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20 ">
            {isDownloading ? (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-999">
                    <Loader message="File downloading in progress... Please do not refresh the page!" />
                </div>
            ) : null}
            <div className="bg-white p-8 rounded shadow-lg w-3/4 h-3/4">
                <h2 className="text-xl mb-4 ">Download Files</h2>
                <div className="border border-gray-200 p-3 mb-3 overflow-y-auto scrollbar-custom h-3/4  rounded-lg">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="w-[220px]">Uploaded Date</TableHead>
                                <TableHead>File Name</TableHead>
                                <TableHead>Comments</TableHead>
                                <TableHead className="text-right">Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {csvList.length > 0 ? csvList.map((item: fileTypeProps) => {
                                return (
                                    <TableRow key={item._id}>
                                        <TableCell className="text-left">
                                            {formatToLocalDate(item.updatedAt)}
                                        </TableCell>
                                        <TableCell className="text-left">
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger>
                                                        {<span>{truncatedString(item.fileName, 45)}</span>}
                                                    </TooltipTrigger>
                                                    <TooltipContent className="text-gray-600 font-normal text-sm mb-2">
                                                        <strong>{item.fileName}</strong>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </TableCell>
                                        <TableCell className="text-left">
                                            {item?.fileComments?.length > 50 ? (
                                                <span
                                                    onClick={() => callDialogBox(item.fileComments)}
                                                    className="cursor-pointer pl-1">
                                                    {item.fileComments.slice(0, 50)}
                                                    ...
                                                </span>
                                            ) : (
                                                item.fileComments
                                            )}
                                        </TableCell>
                                        <TableCell className="text-right">
                                            <Button
                                                onClick={() => handleDownload(item._id)}
                                                className="border-0 bg-transparent hover:bg-blue-100"
                                            >
                                                <img
                                                    src={DownloadBlue}
                                                    alt="Download"
                                                    height='20px'
                                                    width='20px'
                                                />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            }) : <TableRow key='No Data'><TableCell><span> No Data </span></TableCell></TableRow>}
                        </TableBody>

                    </Table>
                </div>
                <div className="flex justify-end ">
                    <button
                        className="px-4 py-2 bg-gray-500 text-white rounded"
                        onClick={() => handleConfigure(false)}
                    >
                        Close
                    </button>

                </div>
            </div>
            {isDialogBox && (
                <DialogBox
                    headerText={"File Comment"}
                    DialogBoxMsg={dialogBoxMsg}
                    setIsDialogBox={setIsDialogBox}
                    setDialogBoxMsg={setDialogBoxMsg}
                />
            )}
        </div>
    )
}

export default DownloadComponent