// login.jsx
import { useEffect } from 'react';
import { StytchB2B } from '@stytch/react/b2b';
import {saveTokenToLocalStorage, getTokenFromLocalStorage} from '@/components/common/AuthService'
import { useNavigate} from 'react-router-dom';
import { ACCESS_TOKEN, REFRESH_TOKEN, SYSTEM_ADMIN, USER_EMAIL, USER_NAME } from '@/utils/appConstants';

import { useStytchB2BClient, useStytchMemberSession } from '@stytch/react/b2b';
import instance from '@/utils/axiosConfig';
import { getUserRoles } from '@/utils/utils';
const STYTCH_PRODUCTS_VALUE = import.meta.env.VITE_STYTCH_CONFIG_PRODUCTS;

// Check if it contains a comma
const MULTIPLE_PRODUCTS = STYTCH_PRODUCTS_VALUE.includes(',');

// Split the value if it contains a comma, otherwise use it as is
const STYTCH_PRODUCTS = MULTIPLE_PRODUCTS ? STYTCH_PRODUCTS_VALUE.split(',') : [STYTCH_PRODUCTS_VALUE];
const BASE_AUTH_URL = import.meta.env.VITE_BASE_AUTH_URL;
const API_AUTH_PATH = import.meta.env.VITE_API_AUTH_PATH;
const SSO_LOGIN_REDIRECT_URL = import.meta.env.VITE_STYTCH_CONFIG_SSO_OPTIONS_LOGIN_REDIRECT_URL;
const BASE_URL = import.meta.env.VITE_BASE_URL;
const API_PATH = import.meta.env.VITE_API_CORE_PATH;

const StytchLogin = (props:any) => {
    /* When user try to login second time checking meber session */
    const stytchClient = useStytchB2BClient();
    const { session } = useStytchMemberSession();
   
    useEffect(() => {
        if (session) {
            navigate('/dashboard');
        }
    }, [stytchClient, session]);
    /* SSO Login Second Time End */
    const config = {
        "products": STYTCH_PRODUCTS,
        "authFlowType": "Organization",
        "sessionOptions": {
            "sessionDurationMinutes": 60
        },
       /*  "emailMagicLinksOptions": {
            "loginRedirectURL": EMAILMAGIC_LOGIN_REDIRECT_URL,
        }, */
        "ssoOptions": {
            "loginRedirectURL": SSO_LOGIN_REDIRECT_URL,
          }
    };
    const styles = {
        "container": {
            "backgroundColor": "#FFFFFF",
            "borderColor": "#ADBCC5",
            "borderRadius": "8px",
            "width": "400px"
        },
        "colors": {
            "primary": "#19303D",
            "secondary": "#5C727D",
            "success": "#0C5A56",
            "error": "#8B1214"
        },
        "buttons": {
            "primary": {
            "backgroundColor": "#19303D",
            "textColor": "#FFFFFF",
            "borderColor": "#19303D",
            "borderRadius": "4px"
            },
            "secondary": {
            "backgroundColor": "#FFFFFF",
            "textColor": "#19303D",
            "borderColor": "#19303D",
            "borderRadius": "4px"
            }
        },
        "inputs": {
            "backgroundColor": "#FFFFFF00",
            "borderColor": "#19303D",
            "borderRadius": "4px",
            "placeholderColor": "#8296A1",
            "textColor": "#19303D"
        },
        "fontFamily": "Arial",
        "hideHeaderText": false,
        "logo": {
            "logoImageUrl": ""
        }   
    }
    useEffect(() => {
        const accessToken = getTokenFromLocalStorage(ACCESS_TOKEN)
        if(accessToken){
            navigate('/dashboard');
        }
    }, []);

    const navigate = useNavigate();
    const callbacks = {
        onEvent: async ({ type, data }: { type: string ; data: any  }) => {
            const sesstionToken = data.session_token;
            const jwdToken = data.session_jwt;
            // // TODO : Here we are passing JWT token as access token. We need to modify it in LEM-596
            if ( jwdToken && sesstionToken ) {
               //console.log('Jwt Token =>',jwdToken, 'Session Token =>',sesstionToken );
               try {
                props.setLoading(true);
                const response = await instance.post(
                  `${BASE_AUTH_URL}${API_AUTH_PATH}/token-exchange`,
                  { session_token: sesstionToken }
                );
                saveTokenToLocalStorage(
                    ACCESS_TOKEN,
                    response.data.data.accessToken
                );
                saveTokenToLocalStorage(
                    REFRESH_TOKEN,
                    response.data.data.refreshToken
                );
                // Saving User Details in local storage.
                try {
                    const response = await instance.get(
                    `${BASE_URL}${API_PATH}/userDetails`
                    );
                    saveTokenToLocalStorage(
                        USER_NAME,
                        response.data.data.firstName + ' ' + response.data.data.lastName
                      );
                      saveTokenToLocalStorage(
                        USER_EMAIL,
                        response.data.data.email
                      );
                    // const a token = getTokenFromLocalStorage('access_token');
                    props.setLoading(false);
                    const userRoles = getUserRoles();
                    userRoles.includes(SYSTEM_ADMIN)
                      ? navigate("/admin")
                      : navigate("/dashboard"); 
                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
              } catch (error: any) {
                props.setLoading(false);
                throw error?.response ? error.response?.data?.message[0] : error.message;
              }
            }
        },
        onError: (data: any) => {
            console.log('Stytch Error:', data);
        },
    };
   
  return (
    <div className="flex items-center justify-center h-screen">        
        <StytchB2B config={config} styles={styles} callbacks={callbacks} />
    </div>
  )
  
}

export default StytchLogin;