import { Accelerate_blu, Elite_blu, Rapid_blu } from "@/assets/icons";

export const ATTRIBUTES_ALLOWED_SPECIAL_CHARS_REG_EXP =
  /^[a-zA-Z0-9~&?^#$\/|\\%*()_\-+:. '"\s]+$/;
export const ALLOWED_SPECIAL_CHARS_REG_EXP =
  /^[a-zA-Z0-9~&?^#$\/|\\%*()_\-+:. '"\s]+$/;
export const EMAIL_REG_EXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const EMPTY = "empty";
export const DEFAULT_ATTRIBUTES = "defaultAttributes";
export const CUSTOM_ATTRIBUTES = "customAttributes";
export const SAVED = "saved";
export const PENDING_APPROVAL = "pending-approval";
export const ALL_PORTFOLIO = "all-portfolios";
export const VIEW_PORTFOLIO = "view-portfolio";
export const CREATE_PORTFOLIO_SIDE_HEADER = "Attributes";
export const DRAFT_STATUS = "Draft";
export const PROCESSING_STATUS = "Processing";
export const READY_FOR_REVIEW_STATUS = "Ready for review";
export const BUILDING_VISUALIZATIONS = "Building Visualizations";
export const READY_FOR_MAPPING = "Ready for mapping";
export const ANALYZING_DATA = "Analyzing Data";
export const PUBLISHED = "Published";
export const VIEW_MODE = "view";
export const EDIT_MODE = "edit";
export const RENAME_TAB = "Rename Tab";
export const DELETE_TAB = "Delete Tab";
export const PORTFOLIOS = "portfolios";
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const SUBSCRIBER = "Subscribe";
export const UN_SUBSCRIBER = "Unsubscribe";
export const CREATE_NEW = "Create Portfolio";
export const LENGTH = 50;
export const ServerError = "Server Error";
export const USER_ROLES = "Roles";
export const SYSTEM_ADMIN = "SYSTEM_ADMIN";
export const UNPUBLISHED = "Unpublished";
export const PLATFORM_SUBSCRIPTION = "Platform Subscription";
export const GLOBAL_NO_SUBSCRIPTION = "GLOBAL_NO_SUBSCRIPTION";
export const ENABLED_TWO_FA = 'Enabled2fa'
export const AUTH_MTHOD = 'authMethod'
export const VIEW_DASHBOARD ='View Dashboard'
export const VIEW_DRAFT_DASHBOARD='View Draft Dashboard'
export const CLEAR_FILTER = "Clear Filter";
export const RequestStatus = {
  APPROVED_STATUS: "Approved",
  DENY_STATUS: "Denied",
};
export const _PORTFOLIOS = "Portfolios";
export const SideNavBarTabs = {
  INCOMINGREQUESTS: "Subscription Approvals",
  SUBSCRIBE: "Subscribe",
  PORTFOLIOS: "all-portfolios",
  SAVEDPORTFOLIOS: "saved",
  PENDINGAPPROVALS: "pending-approval",
  AI_ANALYTICS: "ai-analytics",
  MY_PORTFOLIOS: "my-portfolios",
  UN_PUBLISHED: "un-published",
  RAPID: "Rapid",
  ACCELERATE: "Accelerate",
  My_DASHBOARD: "My Dashboard",
  PENDING_SUBSCRIPTIONS: "Pending Subscriptions",
  _MY_PORTFOLIOS: "My Portfolios",
  ELITE: "Elite",
  PUBLISHED: "Published",
  _UN_PUBLISHED: "Unpublished",
  MY_SUBSCRIPTIONS: "My Subscriptions",
  PENDING_APPROVALS: "Pending Approvals",
};
export const ADMIN_PAGE_NAVBAR = {
  ADMIN_PAGE: "Admin Page",
  PROCESSING: "Processing",
  PORTFOLIOS: "Portfolios",
};
export const USER_NAME = "userName";
export const USER_EMAIL = "userEmail";
export const APPROVER = "APPROVER"; // This value checking with db values.

export const PAGES_NAME = {
  CREATE_PORTFOLIO: "create-portfolio",
  DASHBOARD: "dashboard",
};
export const PORTFOLIO_PERMISSIONS = {
  EIDT: "EDIT_PORTFOLIO",
  MANAGE: "MANAGE_UPLOADS",
  VIEW_PORTFOLIO: "VIEW_PORTFOLIO",
  VIEW_DASHBOARD: "VIEW_DASHBOARD",
  PUBLISH: "PUBLISH_PORTFOLIO",
  DELETE: "DELETE_PORTFOLIO",
};

export const DASHBOARD_PERMISSIONS = {
  PUBLISH_DASHBOARD: "PUBLISH_DASHBOARD",
};

export const APPLICATION_SUBSCRIPTIONS = {
  // My Subscription
  MY_SUBSCRIPTION: SideNavBarTabs.MY_SUBSCRIPTIONS,
  // RAPID BLOCK
  RAPID_PORTFOLIOS: `${SideNavBarTabs.RAPID}-${PORTFOLIOS}`,
  RAPID_PENDING_APPROVALS: `${SideNavBarTabs.RAPID}-${SideNavBarTabs.PENDING_APPROVALS}`,
  // ACCELERATE BLOCK
  ACCELERATE_PORTFOLIOS: `${SideNavBarTabs.ACCELERATE}-${PORTFOLIOS}`,
  ACCELERATE_PENDING_APPROVALS: `${SideNavBarTabs.ACCELERATE}-${SideNavBarTabs.PENDING_APPROVALS}`,
  // ELITE BLOCK
  ELITE_PORTFOLIOS: `${SideNavBarTabs.ELITE}-${PORTFOLIOS}`,
  ELITE_PENDING_APPROVALS: `${SideNavBarTabs.ELITE}-${SideNavBarTabs.PENDING_APPROVALS}`,
  // My PORTFOLIO BLOCK
  PUBLISHED: `${SideNavBarTabs.PUBLISHED}`,
  UN_PUBLISHED: `${SideNavBarTabs._UN_PUBLISHED}`,
};
export const EMPTY_SCREENS_NAME = [
  // Rapid Block
  {
    name: APPLICATION_SUBSCRIPTIONS.RAPID_PORTFOLIOS,
    header: "You haven't published or subscribed to any dashboards yet",
    sub_header:
      "Check your unpublished dashboards or subscribe to dashboards in your organization",
  },
  {
    name: APPLICATION_SUBSCRIPTIONS.RAPID_PENDING_APPROVALS,
    header: "You don’t have any pending subscriptions",
    sub_header: `You can subscribe to dashboards inside your organization using the <b>Subscribe</b> page`,
  },

  // Accelerate Block
  {
    name: APPLICATION_SUBSCRIPTIONS.ACCELERATE_PORTFOLIOS,
    header: "You haven't published or subscribed to any dashboards yet",
    sub_header:
      "Check your unpublished dashboards or subscribe to dashboards in your organization",
  },
  {
    name: APPLICATION_SUBSCRIPTIONS.ACCELERATE_PENDING_APPROVALS,
    header: "You don’t have any pending subscriptions",
    sub_header: `You can subscribe to dashboards inside your organization using the <b>Subscribe</b> page`,
  },
  // Elite Block
  {
    name: APPLICATION_SUBSCRIPTIONS.ELITE_PORTFOLIOS,
    header: "You haven't published or subscribed to any portfolios yet",
    sub_header:
      "Check your unpublished portfolios or subscribe to portfolios in your organization",
  },
  {
    name: APPLICATION_SUBSCRIPTIONS.ELITE_PENDING_APPROVALS,
    header: "You don’t have any unpublished portfolios at this time",
    sub_header: `Click on <b>Create</b> to begin building your portfolio`,
  },
  // POrtfolio Block
  {
    name: APPLICATION_SUBSCRIPTIONS.PUBLISHED,
    header: "You haven't published or subscribed to any portfolios yet",
    sub_header:
      "Check your unpublished portfolios or subscribe to portfolios in your organization",
  },
  {
    name: APPLICATION_SUBSCRIPTIONS.UN_PUBLISHED,
    header: "You don’t have any unpublished portfolios at this time",
    sub_header: `Click on <b>Create</b> to begin building your portfolio`,
  },
  {
    name: SideNavBarTabs.MY_SUBSCRIPTIONS,
    header: "You don’t have any subscribed portfolios at this time.",
    sub_header: "",
  },
  // Other Pages Block
  {
    name: SideNavBarTabs.SUBSCRIBE,
    header: "There aren't any portfolios to subscribe to at this time",
    sub_header: "Please check this page again at a later time",
  },
  {
    name: SideNavBarTabs.INCOMINGREQUESTS,
    header: "You don’t have any incoming requests at this time",
    sub_header:
      "Users can subscribe to portfolios and insights inside your organization using the Subscribe tab",
  },
  // Admin Page
  {
    name: ADMIN_PAGE_NAVBAR.ADMIN_PAGE,
    header: "You don't have any Portfolios for review at this time",
    sub_header:
      "Users can subscribe to portfolios and insights inside your organization using the Subscribe tab",
  },
  // Platform subscription
  {
    name: PLATFORM_SUBSCRIPTION,
    header: "Platform Subscription Inactive",
    sub_header: "Please contact the finance approver",
  },
  // Global no portfolio message
  {
    name: GLOBAL_NO_SUBSCRIPTION,
    header: "",
    sub_header: "Click on Create Portfolio to begin building your portfolio",
  },
];

export const CONFIRMBOX_TYPE = {
  UNSUBSCRIBE: "UNSUBSCRIBE",
};
export const CONFIRMBOX_MSG = {
  UNSUBSCRIBE: "Are you sure you want to unsubscribe?",
};

export const SUBSCRIPTION_PLAN_TYPE = {
  RAPID: "rapid",
  ACCELERATE: "accelerate",
  ELITE: "elite",
};
export const APPROVAL_REQUEST_TYPE = {
  SUBSCRIBE_DASHBOARD: "subscribe_dashboard",
  SUBSCRIBE_PORTFOLIO: "subscribe_portfolio",
  UPGRADE_PORTFOLIO_SUBSCRIPTION: "upgrade_portfolio_subscription",
};

export const REASONBOX_TYPE = {
  SUBSCRIBE: "SUBSCRIBE",
  DENY: "DENY",
};
export const REASONBOX_MSG = {
  HEADER_TEXT: "Subscription Reason",
  SUBSCRIBE_MSG: "",
  DENY_MSG: "DENY",
};
export const embedUrlScript =
  "https://32sznd88naoz5qn.us.qlikcloud.com/single/?appid=e314bf92-322b-431e-bf52-cc8422e708c2&sheet=VpPpAY&theme=horizon&opt=ctxmenu,currsel";
export const SUBSCRIBED = "Subscribed";
export const SUBSCRIBE = "Subscribe";

export const SUBSCRIPTION_MSG = {
  UPGRADE_PORTFOLIO_SUBSCRIPTION: "Upgrade Portfolio Subscription",
  SUBSCRIBE_PORTFOLIO: "Subscribe Portfolio",
};
export const yearlyPlansDetails = [
  {
    id: 1,
    planName: "1 Year - $45,000 USD",
    plan: "Platform-License-USD-Yearly",
  },
  {
    id: 2,
    planName: "3 Years (3% off annual billing amount) - $43,650 USD",
    plan: "Platform-License-3-years-term-USD-Yearly",
  },
];
export const platformSubscriptionStatus = {
  ACTIVE: "active",
  IN_TRIAL: "in_trial",
  NON_RENEWING: "non_renewing",
  PAUSED: "paused",
  CANCELLED: "cancelled",
};
export const productNames = {
  StrategyBoost: "StrategyBoost",
  SpendWise: "SpendWise",
  WorkforceBuilder: "WorkforceBuilder",
  RevShield: "RevShield",
  SalesAccelerator: "SalesAccelerator" // 
};
export const LemmataProducts = [
  {
    _id: "product_5",
    productName: productNames.SalesAccelerator,
    displayName: "SalesAccelerator",
  },
  {
    _id: "product_4",
    productName: productNames.RevShield,
    displayName: "RevShield",
  },
  {
    _id: "product_2",
    productName: productNames.SpendWise,
    displayName: "SpendWise",
  },
  {
    _id: "product_1",
    productName: productNames.StrategyBoost,
    displayName: "StrategyBoost",
  },

  {
    _id: "product_3",
    productName: productNames.WorkforceBuilder,
    displayName: "WorkforceBuilder",
  },
];
// Utility function to get product display name
export const getProductDisplayName = (productName: string): string | null => {
  // Find the product by productName
  const product = LemmataProducts.find((p) => p.productName === productName);
  // Return the displayName if found, otherwise return null
  return product ? product.displayName : "";
};
export const ATTRIBUTE_EMPTY = "Attribute name is empty";

// Sorting const values
export const SORTINGDATA = {
  portfolio: {
    name: "Portfolio Name",
    asc: "asc_portfolio_name",
    desc: "desc_portfolio_name",
  },
  productNames: {
    name: "Product Name",
    asc: "asc_product_name",
    desc: "desc_product_name",
  },
  creator: {
    name: "Creator",
    asc: "asc_creator",
    desc: "desc_creator",
  },
  createdDate: {
    name: "Created Date",
    asc: "asc_created_date",
    desc: "desc_created_date",
  },
  updatedDate: {
    name: "Updated Date",
    asc: "asc_updated_date",
    desc: "desc_updated_date",
  },
  status: {
    name: "Status",
    asc: "asc_status",
    desc: "desc_status",
  },
};
export const DashboardsConst = [
  {
    name: SUBSCRIPTION_PLAN_TYPE.RAPID,
    _icons: Rapid_blu
  },
  {
    name: SUBSCRIPTION_PLAN_TYPE.ACCELERATE,
    _icons: Accelerate_blu
  },
  {
    name: SUBSCRIPTION_PLAN_TYPE.ELITE,
    _icons: Elite_blu
  }
]