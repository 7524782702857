import React, { useEffect, useState } from "react";
import { close } from "@/assets/icons/index";
import { LemmataProducts, PAGES_NAME } from "@/utils/appConstants";
import { Label } from "@/components/ui/Label";
import { Input } from "@/components/ui/Input";
import { Textarea } from "../ui/Textarea";
import DropArea from "../custom-components/upload-csv/DropArea";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/Select";
import Loader from "../common/Loader";

interface UploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (e: any, _url: FormData | string) => void;
  page: string;
  planType: string;
  APIError?: FormData | undefined;
  isRequestResolved: boolean | null;
  setApiError: (data: any) => void;
}

interface ProductType {
  _id: string;
  productName: string;
  displayName: string;
}

export interface FormData {
  portfolioName: string | undefined;
  description: string | undefined;
  file: File | null | string;
  selectProduct: string | undefined;
  dataConnector: boolean | string | undefined;
  comments: string | undefined;
  fileUploadCheck: boolean | string | undefined;
}
const initialFormData: FormData = {
  portfolioName: "",
  description: "",
  file: null,
  selectProduct: "",
  dataConnector: false,
  comments: "",
  fileUploadCheck: false,
};

function mapNameBasedOnFields(field: string) {
  switch (field) {
    case "portfolioName":
      return "Portfolio Name";
    case "description":
      return "Description";
    case "file":
      return "CSV File";
    case "selectProduct":
      return "Select Product";
    case "comments":
      return "Comments";
    default:
      return "Field";
  }
}
const UploadDialog: React.FC<UploadDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  page,
  planType,
  APIError,
  isRequestResolved,
  setApiError,
}) => {
  const [formData, setFormData] = useState({
    data: initialFormData,
    error: initialFormData,
  });

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedFileUpload, setIsCheckedFileUpload] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  useEffect(() => {
    setIsCheckedFileUpload(false);
    setIsChecked(false);
  }, [isOpen]);
  useEffect(() => {
    if (JSON.stringify(APIError) === JSON.stringify(initialFormData)) {
      setFormData({ data: initialFormData, error: initialFormData });
      setFile(null);
    } else if (APIError !== undefined) {
      // Check if APIError is not undefined
      setFormData({ data: formData.data, error: APIError });
    }
  }, [isOpen, APIError]);
  const handleClose = () => {
    setFormData({ data: initialFormData, error: initialFormData });
    setApiError({
      portfolioName: "",
      description: "",
      file: null,
      selectProduct: "",
      dataConnector: false,
      comments: "",
      fileUploadCheck: false,
    });
    onClose();
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      data: { ...prevData.data, planType: planType, file: file },
    }));
  }, [file, planType]);

  const handlePublish = (e: any) => {
    const newErrors = {
      portfolioName:
        formData.data.portfolioName?.trim() === ""
          ? "Portfolio name is required."
          : "",
      description:
        formData.data.description?.trim() === ""
          ? "Description is required."
          : "",
      file:
        formData.data.fileUploadCheck === "true" && formData.data.file === null ? "CSV File is required" : "",
      selectProduct:
        formData.data.selectProduct?.trim() === ""
          ? "Product selection is required."
          : "",
      comments:
        formData.data.dataConnector === "true" &&
        formData.data.comments?.trim() === ""
          ? "Comments is required."
          : "",
    };

    setFormData((prevPaData:any) => ({
      ...prevPaData,
      error: newErrors,
    }));
    const hasErrors = Object.values(newErrors).some((error) => error !== "");

    if (!hasErrors) {
      const myCallBack = () => {
        setFormData({
          data: initialFormData,
          error: initialFormData,
        });
        setFile(null);
      };
      page === PAGES_NAME.DASHBOARD && onSubmit(e, formData?.data, myCallBack);
    } else {
      console.log(Object.values(newErrors));
    }
  };

  if (!isOpen) return null;
  const updateFormError = (field: keyof FormData, errorMessage: string) => {
    setFormData((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [field]: errorMessage,
      },
    }));
  };

  const updateFormData = (field: keyof FormData, value: string | File) => {
    if (field === "dataConnector") {
      setFormData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [field]: !isChecked ? "true" : "false",
        },
      }));
    } else if(field === "fileUploadCheck") {
      setFormData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [field]: !isCheckedFileUpload ? "true" : "false",
        },
      }));
    }else {
      setFormData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [field]: value,
        },
      }));
    }
  };

  const handleChange =
    (field: keyof FormData) =>
    (value: string | React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = typeof value === "string" ? value : value.target.value;
      if ("dataConnector" === field) {
        setIsChecked(!isChecked);
      }
      if (field === "fileUploadCheck") {
        setIsCheckedFileUpload(!isCheckedFileUpload);
      }
      if (field === "description" && inputValue.trim().length > 100) {
        updateFormError(
          field,
          `${mapNameBasedOnFields(field)} must be less than 100 characters.`
        );
        return;
      }
      if (field === "comments" && inputValue.trim().length > 500) {
        updateFormError(
          field,
          `${mapNameBasedOnFields(field)} must be less than 500 characters.`
        );
        return;
      }

      updateFormData(field, inputValue);
      updateFormError(
        field,
        inputValue.trim() === "" || inputValue == null
          ? `${mapNameBasedOnFields(field)} is required`
          : ""
      );
    };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 p-2 flex justify-center items-center bg-gray-900 bg-opacity-50 z-10">
      {isRequestResolved ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-999">
          <Loader message="File upload in progress... Please do not refresh the page!" />
        </div>
      ) : null}
      <div className="bg-white max-w-[650px] min-w-[650px] p-5 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-bold text-grey text-[18px] p-1">
            {page === PAGES_NAME.CREATE_PORTFOLIO &&
              "Please specify the final dashboard URL"}
            {page === PAGES_NAME.DASHBOARD && "Create Portfolio"}
          </h2>
          <button onClick={handleClose}>
            <img src={close} className="w-6 h-6 text-gray-600" alt="close" />
          </button>
        </div>

        {page === PAGES_NAME.DASHBOARD && (
          <div className="grid gap-4 mb-4">
            <div className="flex gap-4 ">
              <div className="w-1/2">
                <Label htmlFor="selectProduct">
                  Select Product<sup className="text-red-500">*</sup>
                </Label>
                <Select
                  onValueChange={(value) =>
                    handleChange("selectProduct")(value)
                  }
                >
                  <SelectTrigger
                    className={`my-1 ${
                      formData.error.selectProduct && "border-red-500"
                    }`}
                  >
                    <SelectValue placeholder="Select a Product" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Select a Product</SelectLabel>
                      {LemmataProducts.map((item: ProductType) => (
                        <SelectItem key={item._id} value={item.productName}>
                          {item.displayName}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                {formData.error.selectProduct && (
                  <small className="text-red-500">
                    {formData.error.selectProduct}
                  </small>
                )}
              </div>
              <div className="w-1/2">
                <Label htmlFor="portfolioName">
                  Portfolio Name<sup className="text-red-500">*</sup>
                </Label>
                <Input
                  id="portfolioName"
                  type="text"
                  placeholder="Give your Portfolio a name"
                  value={formData.data?.portfolioName}
                  onChange={handleChange("portfolioName")}
                  className={`my-1 ${
                    formData.error.portfolioName && "border-red-500"
                  }`}
                />
                {formData.error.portfolioName && (
                  <small className="text-red-500">
                    {formData.error.portfolioName}
                  </small>
                )}
              </div>
            </div>
            <div>
              <Label htmlFor="description">
                Description <sup className="text-red-500">*</sup>
              </Label>
              <Textarea
                id="description"
                placeholder="Tell us about your Portfolio"
                value={formData.data?.description}
                // @ts-ignore
                onChange={handleChange("description")}
                className={`my-1 ${
                  formData.error.description && "border-red-500"
                }`}
              />
              {formData.error.description && (
                <small className="text-red-500">
                  {formData.error.description}
                </small>
              )}
            </div>
            <div>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange("dataConnector")}
                id="checkbox_data_connector"
                className="mt-3"
              />
              <Label htmlFor="checkbox_data_connector">
                &nbsp;&nbsp;Add Data Connector Request
              </Label>
              {isChecked && (
                <>
                  <p className="text-sm italic text-gray-500 mt-2"> Lemmata Support will be in touch with you once you have created a portfolio.</p>
                  <Textarea
                    id="reason"
                    placeholder="Describe your data connections here. e.g. Salesforce, Netsuite, Hadoop, etc."
                    value={formData.data?.comments}
                    // @ts-ignore
                    onChange={handleChange("comments")}
                    //className="mt-2"
                    className={`my-1 ${
                      formData.error.comments && "border-red-500"
                    }`}
                  />
                </>
              )}
              {formData.error.comments && (
                <small className="text-red-500">
                  {formData.error.comments}
                </small>
              )}
            </div>
            <div>
              <input
                type="checkbox"
                checked={isCheckedFileUpload}
                onChange={handleChange("fileUploadCheck")}
                className="mt-3"
                id="fileUploadCheck"
              />
              <Label htmlFor="fileUploadCheck">&nbsp;&nbsp;File Upload</Label>

              {isCheckedFileUpload && (
                <DropArea
                  id="fileUpload"
                  file={file}
                  setFile={setFile}
                  height="180px"
                  className={`mt-2 ${
                    formData.error.file && "border border-lg border-red-500"
                  }`}
                  setFormData={setFormData}
                />
              )}
              {formData.error.file && isCheckedFileUpload && (
                <small className="text-red-500">{formData?.error?.file}</small>
              )}
            </div>
          </div>
        )}
        <div className="flex justify-end">
          <button
            onClick={handleClose}
            className="mr-2 px-4 py-2 border text-sm border-gray-300 rounded-md text-gray-600 hover:bg-gray-100 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={handlePublish}
            className="px-4 py-2 bg-blue-500 text-sm text-white rounded-md hover:bg-blue-600 focus:outline-none"
          >
            {isRequestResolved ? "Uploading..." : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadDialog;
