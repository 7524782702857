import React, { useEffect, useRef } from "react";
import { moveMessagePortToContext } from "worker_threads";

type MenuItemProps = {
  isModalOpen: boolean;
  handleMenuPopUp: () => void;
};

import {
  upload,
//   uploadfile,
  treeviewportfolio,
  publish,
  shareWindows,
  deletePortfolio,
} from "@/assets/icons-color/index";

//create an array of object where each object will have menu name ,icon and onclick function

const menuItems = [
  {
    _id: 1,
    _icon: treeviewportfolio,
    _name: "View Portfolio",

    onclick: () => {},
  },
  {
    _id: 2,
    _icon: upload,
    _name: "Upload data",

    onclick: () => {},
  },
  {
    _id: 3,
    _icon: shareWindows,
    _name: "Manage upload",

    onclick: () => {},
  },
  {
    _id: 4,
    _icon: shareWindows,
    _name: "Share",

    onclick: () => {},
  },
  {
    _id: 5,
    _icon: publish,
    _name: "Publish",
    onclick: () => {},
  },
  {
    _id: 6,
    _icon: deletePortfolio,
    _name: "Delete",
    onclick: () => {},
  },
];

const PopupMenu: React.FC<MenuItemProps> = ({
  isModalOpen,
  handleMenuPopUp,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const handleMouseLeave = () => {
    if (isModalOpen) {
      handleMenuPopUp();
    }
  };
  return (
    <>
      {isModalOpen && (
        <div
          ref={menuRef}
          className="absolute right-0 top-10 bg-white z-999  rounded-md transition-opacity duration-300 ease-out"
          onMouseLeave={handleMouseLeave}
        >
          <ul className="transition-opacity duration-300 ease-in opacity-100 p-3">
            {menuItems.map((item) => (
              <li key={item._id} className="p-2 flex-start gap-2 text-sm text-gray" onClick={() => item.onclick()}>
                <img src={item._icon} alt="Icons" />{item._name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default PopupMenu;
