import { APPROVAL_REQUEST_TYPE, SideNavBarTabs } from "@/utils/appConstants";
import { Label } from "@/components/ui/Label";
import { Textarea } from "@/components/ui/Textarea";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/state/store";
import { submitApproval } from "@/state/slices/SubscriptionSlice";
import useToast from "@/hooks/useToast";
import { pendingPortfolios } from "@/state/slices/PortfolioSliceUpdate";

type UpgradeDialogProps = {
  setIsUpgrading?: any;
  portfolioId?: string;
  requestType?: string;
  updateCounter?: () => void;
};

export default function UpgradeDialog({
  setIsUpgrading,
  portfolioId,
  requestType,
  updateCounter,
}: UpgradeDialogProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { success, error, warning } = useToast();

  const [reason, setReason] = useState("");
  const [upgradeSubscriptions, setUpgradeSubscriptions] = useState<string[]>(
    []
  );
  const [pendingPortfoliosObject, setPendingPortfoliosObject] = useState({
    Accelerate: [],
    Elite: [],
  });
  const [upgreadeRequestSent, setUpgreadeRequestSent] = useState(false);
  const [selectedOptionType, setSelectedOptionType] = useState(
    SideNavBarTabs.ELITE
  );

  const onClose = () => {
    setIsUpgrading(false);
    setUpgreadeRequestSent(false);
  };

  const handleOnChangeSubscription = (e: any) => {
    setSelectedOptionType(e.target.value);
  };

  useEffect(() => {
    if (requestType === SideNavBarTabs.RAPID) {
      setUpgradeSubscriptions([
        SideNavBarTabs.ACCELERATE,
        SideNavBarTabs.ELITE,
      ]);
    } else if (requestType === SideNavBarTabs.ACCELERATE) {
      setUpgradeSubscriptions([SideNavBarTabs.ELITE]);
    }

    const fetchPortfolios = async (subscriptionPlanType: any) => {
      try {
        const action = await dispatch(pendingPortfolios(subscriptionPlanType));
        if (pendingPortfolios.fulfilled.match(action)) {
          setPendingPortfoliosObject((prevPortfolios) => ({
            ...prevPortfolios,
            [subscriptionPlanType]: action.payload.portfolios,
          }));
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchPortfolios(SideNavBarTabs.ACCELERATE);
    fetchPortfolios(SideNavBarTabs.ELITE);
  }, [requestType]);

  const isPortfolioIdExists = (id: string) => {
    return Object.values(pendingPortfoliosObject).some((portfolios) =>
      portfolios.some((portfolio: any) => portfolio.portfolioId === id)
    );
  };

  useEffect(() => {
    if (portfolioId && isPortfolioIdExists(portfolioId)) {
      setUpgreadeRequestSent(isPortfolioIdExists(portfolioId));
      warning("Upgrade Subscription Request Pending.");
      setIsUpgrading(false);
    }
  }, [pendingPortfoliosObject, portfolioId]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    } else if (event.key === "Escape") {
      setIsUpgrading(false);
    }
  };

  const handleSubmit = async (e: any) => {
    if (!selectedOptionType) return;

    const submitForApproval = {
      portfolioId: portfolioId,
      planType: selectedOptionType,
      requestType: APPROVAL_REQUEST_TYPE.UPGRADE_PORTFOLIO_SUBSCRIPTION,
      reason: reason,
    };

    try {
      const action = await dispatch(
        submitApproval({ payload: submitForApproval })
      );
      if (action.meta.requestStatus === "fulfilled") {
        updateCounter?.();
        success("Submitted portfolio subscription request");
      } else {
        throw new Error("Request failed");
      }
    } catch {
      error("Internal server error while submitting request");
    } finally {
      setIsUpgrading(false);
    }
  };

  return (
    <>
      {!upgreadeRequestSent && (
        <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50 z-20">
          <div className="bg-white rounded-lg p-6 sm:w-1/3 w-1/3 l:w-1/3 w-1/3">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-lg font-semibold">Upgrade Subscription</h2>
            </div>
            <div>
              <div className="p-4">
                <h2 className="text-sm font-semibold">
                  Choose your subscription plan
                </h2>
                {upgradeSubscriptions.map((item: any, key: number) => (
                  <div className="mb-1" key={key}>
                    <input
                      type="radio"
                      id={item}
                      className="px-3 py-2 cursor-pointer"
                      value={item}
                      checked={selectedOptionType === item}
                      onChange={(e) => handleOnChangeSubscription(e)}
                      onKeyDown={handleKeyDown}
                    />
                    <span className="text-sm font-medium ml-3 ">{item}</span>
                  </div>
                ))}
              </div>
              <div className="p-4">
                <Label htmlFor="reason">Reason for subscribing</Label>
                <Textarea
                  className="mt-1"
                  id="reason"
                  placeholder="Tell us about your reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
              <div className="flex justify-center mt-3 ">
                <button
                  className="mr-2 px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                  onClick={(e) => handleSubmit(e)}
                  disabled={false}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
