import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AttributeConstants, ConstantsItem } from "@/constants/nodes";
import { addAttributeLevel } from "@/utils/utils";

type InitialState = { value: ConstantsItem[] };

const initialState: InitialState = {
  value: AttributeConstants,
};

const AttributeListSlice = createSlice({
  name: "AttributeList",
  initialState,
  reducers: {
    getAllAttributes: () => initialState,
    updateAttributes: (state, action) => {
      //want to replace the value with action.payload array
      const sanitizedData = addAttributeLevel(action.payload);
      return {value : sanitizedData as ConstantsItem[]}
    },
  },
});

export default AttributeListSlice.reducer;
export const { getAllAttributes, updateAttributes } =
  AttributeListSlice.actions;
// export const AttributeListSelector = (state: {
//   AttributeListSlice: InitialState;
// }) => state?.AttributeListSlice?.value;
