import { getTokenFromLocalStorage, removeTokenFromLocalStorage } from "@/components/common/AuthService";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_EMAIL, USER_NAME, USER_ROLES } from "./appConstants";
const accessToken = "accessToken";
const instance: AxiosInstance = axios.create({
  // baseURL: "http://localhost:3002",
  // baseURL: import.meta.env.VITE_BASE_URL + import.meta.env.VITE_API_PATH,
  baseURL: import.meta.env.VITE_BASE_URL,
});

instance.interceptors.request.use(
  //@ts-ignore
  (config: AxiosRequestConfig) => {
    if (config?.data instanceof FormData) {
      console.log("Content-Type multipart/form-data")
      config.headers = {
        ...config.headers,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, PUT, POST, DELETE, PATCH, OPTIONS",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getTokenFromLocalStorage(accessToken)}`,
      };
    } else {
      config.headers = {
        ...config.headers,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, PUT, POST, DELETE, PATCH, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenFromLocalStorage(accessToken)}`
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response: AxiosResponse) => {
  
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      if (error?.response?.data?.endpoint == "/api/auth/login") {
        return Promise.reject(error);
      }
      removeTokenFromLocalStorage([
        ACCESS_TOKEN,
        USER_NAME,
        USER_EMAIL,
        REFRESH_TOKEN,
        USER_ROLES,
      ]);
      window.location.href = "/";
      return Promise.reject(error);
    }else if(error?.response?.status === 404 || error?.response?.status === 500){
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default instance;
