import { circlecheckblu } from "@/assets/icons-color/index";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/Accordion";
import { SideNavBarTabs, ADMIN_PAGE_NAVBAR } from "@/utils/appConstants";
import MenuCard from "./MenuCard";
import { useEffect, useState } from "react";
import { personRaisedHand } from "@/assets/icons";
import Logout from "./Logout";
import SideNavBarHeader from "./SideNaveBarHeader";
import { HomepageSideBar } from "./NavbarData";
import { useNavigate } from "react-router-dom";
import {
  PortfolioCountsProps,
  updateHomepageSideBarCounters,
} from "@/utils/utils";
import { useSelector } from "react-redux";
import { PortfolioSliceUpdateSelector } from "@/state/slices/PortfolioSliceUpdate";

interface NavbarProps {
  isApprover: boolean;
  setSelectedMenuItem: any;
  selectedMenuItem: string;
  isAdmin: boolean;
  portfolioCounts: PortfolioCountsProps;
  isPlatformSubscribed: boolean | null;
  getFinantialArroverData: any;
}
const Navbar = ({
  isApprover,
  setSelectedMenuItem,
  selectedMenuItem,
  isAdmin,
  portfolioCounts,
  isPlatformSubscribed,
  getFinantialArroverData,
}: NavbarProps) => {
  const navigate = useNavigate();
  const PortfolioCount = useSelector(PortfolioSliceUpdateSelector);
  const [selectedIncomingRequest, setSelectedIncomingRequest] = useState(
    "hover:font-bold hover:text-blue-500"
  );
  const handleMenuItemClick = (menuItem: string) => {
    setSelectedMenuItem(menuItem);
    setSelectedIncomingRequest("hover:font-bold hover:text-blue-500");
    if (menuItem === SideNavBarTabs.INCOMINGREQUESTS) {
      setSelectedIncomingRequest("!font-bold !text-blue-600");
    }
  };

  const handleSubMenuClick = (_name: string, path: string) => {
    handleMenuItemClick(path);
    setSelectedMenuItem(path);
  };

  const handleIncomingRequests = () => {
    handleMenuItemClick(SideNavBarTabs.INCOMINGREQUESTS);
    setSelectedMenuItem(SideNavBarTabs.INCOMINGREQUESTS);
  };
  const handleMySubscription = () => {
    handleMenuItemClick(SideNavBarTabs.MY_SUBSCRIPTIONS);
    setSelectedMenuItem(SideNavBarTabs.MY_SUBSCRIPTIONS);
  };
  useEffect(() => {
    updateHomepageSideBarCounters(HomepageSideBar, portfolioCounts);
    if (selectedMenuItem === SideNavBarTabs.INCOMINGREQUESTS) {
      handleMenuItemClick(SideNavBarTabs.INCOMINGREQUESTS);
    }
  }, [portfolioCounts, selectedMenuItem]);

  useEffect(() => {
    isPlatformSubscribed && getFinantialArroverData();
  }, [PortfolioCount]);

  return (
    <div className="min-w-[340px] font-base p-4   overflow-y-auto scrollbar-custom ">
      <div className="">
        {/* Lemmata icon */}
        <SideNavBarHeader />
        {/* Sidebar content */}
        <div>
          {/* Navigation */}
          <div>
            {HomepageSideBar.map((item: any) => {
              return (
                <div key={item._id} className="mt-3">
                  <hr className="my-5  border-t-4" />
                  <div
                    key={item._id}
                    className={`p-4 text-gray flex flex-row justify-between  ${selectedMenuItem === item.path
                      ? "!font-bold !text-blue-600"
                      : "hover:font-bold  hover:text-blue-500"
                      } ${isPlatformSubscribed &&
                        item._name === SideNavBarTabs.MY_SUBSCRIPTIONS
                        ? "cursor-pointer"
                        : "cursor-no-drop"
                      }`}
                    onClick={
                      isPlatformSubscribed &&
                        item._name === SideNavBarTabs.MY_SUBSCRIPTIONS
                        ? handleMySubscription
                        : undefined
                    }
                  >
                    <span className="flex flex-start gap-2">
                      <img src={circlecheckblu} alt="menuIcon" />
                      <span className="text-blue-500">{item._name}</span>
                    </span>
                  </div>
                  {item._name === SideNavBarTabs.MY_SUBSCRIPTIONS &&
                    item.children.length !== 0 && (
                      <>
                        {item.children.map((subChild: any, index: any) => (
                          <Accordion
                            // defaultValue={["item-1"]}
                            type="multiple"
                            key={`Accordion-${index + 1}`}
                          >
                            <AccordionItem
                              value={`item-${index + 1}`}
                              className="border-none"
                            >
                              <AccordionTrigger
                                className={` border-none ${`${selectedMenuItem}-${item._name}` ===
                                  subChild.path
                                  ? "font-bold !text-blue-600 p-0 w-full"
                                  : "hover:font-bold pl-0 pt-0 pr-4 pb-0 w-full"
                                  }`}
                              >
                                <span className={` hover:font-bold hover:text-blue-500 py-3 pl-8 pr-4 text-gray flex-end gap-2 cursor-pointer`}>
                                  <img
                                    src={subChild._icon}
                                    alt={subChild._name}
                                  />
                                  {subChild._name}
                                </span>
                              </AccordionTrigger>

                              <AccordionContent>
                                {subChild.children.length !== 0 && (
                                  <>
                                    {subChild.children.map(
                                      (subChild_2: any) => (
                                        <div
                                          className={`ml-1 pl-7 text-gray  ${isPlatformSubscribed
                                            ? "cursor-pointer"
                                            : "cursor-no-drop"
                                            } 
                                               ${selectedMenuItem === subChild_2.path
                                              ? "!font-bold !text-blue-600"
                                              : "hover:font-bold  hover:text-blue-500"
                                            }
                                              `
                                          }
                                          key={`${subChild_2._id}_parent`}
                                        >
                                          <MenuCard
                                            key={subChild_2._id}
                                            _id={subChild_2._id}
                                            _icon={subChild_2._icon}
                                            _name={subChild_2._name}
                                            _counter={subChild_2._counter}
                                            _path={subChild_2.path}
                                            onclick={handleSubMenuClick}
                                            selectedMenuItem={selectedMenuItem}
                                            selectedIcon={
                                              subChild_2.selectedIcon
                                            }
                                            isPlatformSubscribed={
                                              isPlatformSubscribed
                                            }
                                          />
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        ))}
                      </>
                    )}
                  {item._name === SideNavBarTabs._MY_PORTFOLIOS &&
                    item.children.length !== 0 && (
                      <>
                        {item.children.map((subChild: any) => (
                          <div className={`ml-1 pl-7 text-gray  ${isPlatformSubscribed
                            ? "cursor-pointer"
                            : "cursor-no-drop"
                            }  ${selectedMenuItem === subChild.path
                              ? "!font-bold !text-blue-600"
                              : "hover:font-bold  hover:text-blue-500"
                            }`}
                            key={`${subChild._id}_child`}>

                            <MenuCard
                              key={subChild._id}
                              _id={subChild._id}
                              _icon={subChild._icon_color}
                              _name={subChild._name}
                              _counter={subChild._counter}
                              _path={subChild.path}
                              onclick={handleSubMenuClick}
                              selectedMenuItem={selectedMenuItem}
                              selectedIcon={subChild._icon}
                              isPlatformSubscribed={isPlatformSubscribed}
                            />
                          </div>
                        ))}
                      </>
                    )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Fixed to the bottom */}
      <div className="fixed bottom-[-20px] mb-2 min-w-[340px] font-base pr-8">
        {/* Financial approver screen */}
        {isApprover && (
          <div
            className={`mt-4 px-4 py-2 flex justify-between gap-2 ${
              portfolioCounts?.subscriptionApproval > 0
                ? "cursor-pointer"
                : "cursor-no-drop"
            }  text-blue-500 ${selectedIncomingRequest}  bg-white`}
            onClick={
              portfolioCounts?.subscriptionApproval > 0
                ? handleIncomingRequests
                : undefined
            }
            role="button"
          >
            <div className="flex">
              <img src={personRaisedHand} alt="menuIcon" />
              <span className="text-nowrap">
                &nbsp;&nbsp;{SideNavBarTabs.INCOMINGREQUESTS}
              </span>
            </div>
            <span>{portfolioCounts?.subscriptionApproval ?? 0}</span>
          </div>
        )}
        {isAdmin && (
          <div
            className={` px-4 mb-4 py-2 flex-start gap-2 cursor-pointer text-blue-500 ${selectedIncomingRequest}`}
            onClick={() => navigate("/admin")}
          >
            <img src={personRaisedHand} alt="menuIcon" />
            <span>{ADMIN_PAGE_NAVBAR.ADMIN_PAGE}</span>
          </div>
        )}

        <Logout />
      </div>
    </div>
  );
};

export default Navbar;
