import { DELETE_TAB, RENAME_TAB } from "@/utils/appConstants";
import React, { useEffect, useState } from "react";

type Props = {
  hierarchyId: string;
  hierarchyName?: string;
  handleRename?: (hierarchyId: string, newName: string) => void;
  handleDelete?: (hierarchyId: string) => void;
  isRenameModelOpen?: boolean;
  handleRenameModel?: () => void;
  isDeleteModelOpen?: boolean;
  handleDeleteModel?: () => void;
};

const TabMenuPopUp = ({
  hierarchyId,
  hierarchyName,
  handleRename,
  handleDelete,
  isRenameModelOpen,
  handleRenameModel,
  isDeleteModelOpen,
  handleDeleteModel,
}: Props) => {
  const [name, setName] = useState("");

  useEffect(() => {
    hierarchyName && setName(hierarchyName);
    return () => {
      setName("");
    };
  }, [hierarchyName, hierarchyId]);
  const onClose = () => {
    setName("");
    if (handleRenameModel) {
      // Add null check here
      handleRenameModel();
    }
  };

  const handleSave = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    if (handleRename && name && hierarchyId) {
      handleRename(hierarchyId, name);
    }
    onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSave(event);
    } else if (event.key === "Escape") {
      onClose();
    }
  };

  return (
    <>
      {isRenameModelOpen && (
        <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 sm:w-1/3 l:w-1/3 w-1/3">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-lg font-semibold">{RENAME_TAB}</h2>
              <button className="text-gray-500" onClick={onClose}>
                X
              </button>
            </div>
            <div className="p-4">
              <div className="mb-4">
                <label htmlFor="tabName" className="block mb-1">
                  Tab Name
                </label>
                <input
                  type="text"
                  id="tabName"
                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div className="flex justify-center items-center gap-4">
              <button
                className="mr-2 px-4 py-2 border border-gray-300 rounded-md  flex-1 max-w-24  hover:bg-gray-100"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md  flex-1 max-w-24  hover:bg-blue-600"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {isDeleteModelOpen && (
        <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 sm:w-1/3 w-1/3 l:w-1/3 w-1/3">
            <h2 className="text-lg font-semibold ml-4 mb-4">{DELETE_TAB}</h2>
            <div className="p-4">
              <div className="mb-4 text-center">
                <p>Are you sure you want to delete this tab?</p>
              </div>
              <div className="flex justify-center items-center gap-4">
                <button
                  className="mr-2 px-4 py-2 border border-gray-300 rounded-md flex-1 max-w-24 mt-5 hover:bg-gray-100"
                  onClick={handleDeleteModel}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded-md  flex-1 max-w-24 mt-5 hover:bg-red-600"
                  onClick={() => handleDelete && handleDelete(hierarchyId)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TabMenuPopUp;
