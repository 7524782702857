import React, { MouseEvent, useEffect, useState } from "react";
import { Card, CardHeader } from "@/components/ui/Card";
import {
  viewdashboardicon,
  viewportfolioicon,
  dotmenu,
  manageUpload,
  treeviewportfolio,
  publish,
  upgrade,
  LemmataAILogo,
} from "@/assets/icons-color/index";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/Dropdown-menu";
const API_CORE = import.meta.env.VITE_API_CORE_PATH;
const BASE_CORE_URL = import.meta.env.VITE_BASE_URL;
const APP_RELOAD = import.meta.env.VITE_ENABLE_PORTFOLIO_RELOAD;

import { Button } from "@/components/ui/Button";
import TagComponent from "./StatusTag";
import PopupMenu from "../common/PopupMenu";
import { Link, useNavigate } from "react-router-dom";
import { publishDraftDashboard, publishPortfolio } from "@/state/slices/PortfolioSlice";
import { useDispatch } from "react-redux";
import { getOwnerNames, getUpdateStatus, StatusTagType } from "@/utils/utils";
import {
  PUBLISHED,
  PORTFOLIO_PERMISSIONS,
  SideNavBarTabs,
  APPLICATION_SUBSCRIPTIONS,
  READY_FOR_REVIEW_STATUS,
  getProductDisplayName,
} from "@/utils/appConstants";
import useToast from "@/hooks/useToast";

import { AppDispatch } from "@/state/store";
import { Badge } from "../ui/badge";
import TabPillStatus from "@/utils/TabPillStatus";
import {
  CalendarSync,
  DataConnector,
} from "@/assets/icons";
import CommentBox from "../common/CommentBox";
import instance from "@/utils/axiosConfig";
import DataReloadSchedule from "../common/DataReloadSchedule";

type PortfolioItem = {
  _id: string | number;
  portfolioName?: string;
  dashboardName?: string;
  owners: any;
  updatedAt: string;
  // subscribers: number;
  subscriber?: string;
  subscribers: any;
  status: StatusTagType;
  isPublished?: boolean;
  embedHtml?: string;
  permissions?: any;
  planType?: string;
  productCode: string;
  createdAt: string;
  portfolioCreator:boolean;
  qlik: {
    reloadSchedules: {
        timesPerDay: string,
        runSchedule: string,
        daysOfMonth: [],
        daysOfWeek: [],
        status: string
    },
    contents: [
          {
            appId: string,
            sheet: string,
            theme: string,
            opt: string,
            planType: string
          }, 
    ],
    draftContents: [
      {
        appId: string,
        sheet: string,
        theme: string,
        opt: string,
        planType: string
      },
    ]
  },
  permiumInsigths: {
    _id: string;
    logo: string;
    insideName: string;
    createdBy: string;
    updatedAt: string;
    status: StatusTagType;
  }[];
};

type PortfolioCardProps = {
  portfolioItem: PortfolioItem;
  setIsManageUploadModalOpen: any;
  permissionsArray: any;
  requestName: any;
  setPortfolioIdForContextMenu: any;
  setSelectedMenuItem: any;
  setIsUpgrading: any;
  setRequestType: any;
  incomingRequestData?:string;
};
type MenuItem = {
  _id: number; // Numeric identifier
  _icon: string; // Name or path to the icon, ensure it's string type if it's a variable like `manageUpload`
  _name: string; // Display name for the menu item
  onclick: (id?: string) => void; // Callback function triggered on click, optionally takes an ID as parameter
};

const PortfolioCard: React.FC<PortfolioCardProps> = ({
  portfolioItem,
  setIsManageUploadModalOpen,
  requestName,
  setPortfolioIdForContextMenu,
  setSelectedMenuItem,
  setIsUpgrading,
  setRequestType,
  incomingRequestData
}) => {
  const {
    _id,
    portfolioName,
    dashboardName,
    updatedAt,
    subscribers,
    status,
    owners,
    isPublished,
    permissions,
    planType,
    productCode,
    createdAt,
    qlik,
    portfolioCreator
  } = portfolioItem;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { success, error } = useToast();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [portfolioId, setPortfolioId] = React.useState<string>(`${_id}`);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [dataConnectorRequest, setDataConnectorRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewDraftDashBoard, setIsViewDraftDashBoard] = useState<boolean>(true);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const handleMenuPopUp = () => {
    setIsModalOpen(!isModalOpen);
  };
  useEffect(() => {
    //@ts-ignore
    setPortfolioId(_id);
    return () => {
      setPortfolioId("");
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, [isLoading]);
  const handlePublish = async (payload: { id: string }) => {
    dispatch(publishPortfolio(payload))
      .then((action: any) => {
        if (publishPortfolio.fulfilled.match(action)) {
          success(action.payload.data.success);
          setSelectedMenuItem(APPLICATION_SUBSCRIPTIONS.PUBLISHED);
        }
      })
      .catch((err: any) => {
        console.log(err);
        error("Error while publishing the portfolio");
      });
  };
  const handleDataReloadSchedule = (type: string) => {
    switch (type) {
      case "toggle":
        setDataConnectorRequest(!dataConnectorRequest);
        break;

      default:
        break;
    }
  };
  const isManageUploads =
    permissions?.includes(PORTFOLIO_PERMISSIONS.MANAGE) ||
    (dashboardName && !isPublished);
  const isPublishPortfolio = status === READY_FOR_REVIEW_STATUS;


  const validateDashboards =
    requestName === APPLICATION_SUBSCRIPTIONS.RAPID_PORTFOLIOS ||
    requestName === APPLICATION_SUBSCRIPTIONS.ACCELERATE_PORTFOLIOS ||
    requestName === APPLICATION_SUBSCRIPTIONS.ELITE_PORTFOLIOS ||
    requestName === SideNavBarTabs.MY_SUBSCRIPTIONS;

  
  useEffect(() => {
    const newMenuItems: MenuItem[] = [];

    // Manage Manual Uploads condition
    if (isManageUploads) {
      newMenuItems.push(
        {
          _id: 3,
          _icon: manageUpload,
          _name: "Manage Manual Uploads",
          onclick: (id?: string) => {
            setIsManageUploadModalOpen(true);
            setPortfolioIdForContextMenu(id);
          },
        },
        {
          _id: 8,
          _icon: DataConnector,
          _name: "Data Connector Request",
          onclick: (id?: string) => {
            handleCommentBox(true);
            setPortfolioIdForContextMenu(id);
          },
        }
      );
    }

    // Reload Schedule condition
    if (APP_RELOAD === 'true' && incomingRequestData === APPLICATION_SUBSCRIPTIONS.PUBLISHED) {
      newMenuItems.push({
        _id: 9,
        _icon: CalendarSync,
        _name: "Reload Schedule",
        onclick: (id?: string) => {
          handleDataReloadSchedule("toggle");
          setPortfolioIdForContextMenu(id);
          setIsLoading(true);
        },
      });
    }

    // Publish Portfolio condition
    if (isPublishPortfolio) {
      newMenuItems.push({
        _id: 5,
        _icon: publish,
        _name: "Publish",
        onclick: () => {
          const payload = { id: portfolioId };
          handlePublish(payload);
        },
      });
    }
    if (validateDashboards) {
      newMenuItems.push({
        _id: 24,
        _icon: treeviewportfolio,
        _name: "View Portfolio",
        onclick: () => navigate(`/portfolio/${_id}`),
      });
    }

    // Upgrade condition
    if (
      requestName === APPLICATION_SUBSCRIPTIONS.RAPID_PORTFOLIOS ||
      requestName === APPLICATION_SUBSCRIPTIONS.ACCELERATE_PORTFOLIOS ||
      (requestName === SideNavBarTabs.MY_SUBSCRIPTIONS &&
        planType !== SideNavBarTabs.ELITE)
    ) {
      let _requestType =
        requestName === APPLICATION_SUBSCRIPTIONS.RAPID_PORTFOLIOS
          ? SideNavBarTabs.RAPID
          : SideNavBarTabs.ACCELERATE;
      if (requestName === SideNavBarTabs.MY_SUBSCRIPTIONS && planType) {
        _requestType = planType;
      }
      newMenuItems.push({
        _id: 6,
        _icon: upgrade,
        _name: "Upgrade",
        onclick: (id?: string) => {
          setIsUpgrading(true);
          setPortfolioIdForContextMenu(id);
          setRequestType(_requestType);
        },
      });
    }

    // Publish Draft Dashboard condition
    if (qlik?.draftContents?.length > 0 && portfolioCreator) {
      newMenuItems.push({
        _id: 7,
        _icon: publish,
        _name: "Publish Draft Dashboard",
        onclick: (portfolioId?: string) => {
          handlePublishDraftDashboard(portfolioId);
        },
      });
    }

    // Update state with new menu items
    setMenuItems(newMenuItems);
   }, [
    isManageUploads,
    APP_RELOAD,
    incomingRequestData,
    isPublishPortfolio,
    requestName,
    planType,
    qlik?.draftContents?.length,
    portfolioCreator,
    portfolioId,
  ]);

   const handlePublishDraftDashboard = async (portfolioId?: string) => {
     try {
        console.log("Portfolio Id =>", portfolioId)
       if (portfolioId) {
         const payload = { id: portfolioId };
         const action = await dispatch(publishDraftDashboard(payload));
         if (publishDraftDashboard.fulfilled.match(action)) {
          setIsViewDraftDashBoard(false);           
          setMenuItems((prevMenu) =>
            prevMenu.filter((item) => item._id !== 7)
          );
          success("Draft Dashboard Published Successfully");
         } else {
           error("Error while publishing the Draft Dashboard");
         }
       }
     } catch (err) {
       console.error(err);
       error("Error while publishing the Draft Dashboard");
     }
   };
 
  const commentBoxSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    handleCommentBox(false);
    const payload = { comments: e };
    try {
      const response = await instance.post(
        `${BASE_CORE_URL}${API_CORE}/portfolios/${portfolioId}/data-connector-requests`,
        payload
      );
      success("Your data connector request has been sent.");
      return response.data.data;
    } catch (error: any) {
      error("Sending data connector request failed.");
      throw error.response ? error.response.data : error.message;
    }
  };
  const handleCommentBox = (status: boolean) => {
    setIsCommentModalOpen(status);
  };
  return (
    <Card className="w-full mb-4 overflow-y-auto">
      {/* portfolio section */}
      <CardHeader className="p-4">
        <div className="wrapper flex-between">
          {/* Left section */}
          <div className="flex-between gap-2">
            {/* user/portfolio/owner Image */}
            <div>
              <img
                src={LemmataAILogo}
                alt="avatar"
                className="w-[60px] h-[60px]"
              />
            </div>

            {/* Portfolio name ,owner name  ,updated status ,sub count */}
            <div className="flex flex-col ">
              <div className="flex-start gap-2">
                {productCode ? (
                  <Badge className=" border border-blue-500 bg-white text-blue-500  hover:bg-transparent">
                    <small className="text-sm">
                      {getProductDisplayName(productCode)}
                    </small>
                  </Badge>
                ) : null}
                <h4 className="font-semibold">{portfolioName}</h4>

                {status && status === PUBLISHED && (
                  <span className="text-gray opacity-90 text-sm">
                    {Object.keys(owners?.[0] || {}).length !== 0
                      ? `by ${getOwnerNames(owners)}`
                      : ""}
                  </span>
                )}
                {requestName === SideNavBarTabs.MY_SUBSCRIPTIONS &&
                  planType && <TabPillStatus status={planType} />}
              </div>
              {/* update section */}
              <div className="flex-start gap-2 ml-3 mt-3">
                {status && status !== PUBLISHED && (
                  <TagComponent status={status} />
                )}
                <span className="text-gray text-xs">
                  {getUpdateStatus(createdAt,'Created on ')}
                </span> &nbsp;
                <span className="text-gray text-xs">
                  {getUpdateStatus(updatedAt)}
                </span>
              </div>
            </div>
          </div>

          {/* Right section */}
          <div className="flex-between">
            <div className="flex-start">
              {validateDashboards ? (
                <>
                  {qlik?.contents && (
                    <>
                      <img
                        src={isPublished ? viewdashboardicon : viewportfolioicon}
                        alt={status}
                        className="cursor-pointer"
                      />
                      <Link
                        className="button-blue !p-2"
                        to={`/ai-analytics/${portfolioId}/${planType}/live`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Dashboard
                      </Link>
                    </>
                  )}
                  {((qlik?.draftContents?.length > 0 && portfolioCreator)) && (
                    isViewDraftDashBoard && (
                    <>
                      <img
                        src={isPublished ? viewdashboardicon : viewportfolioicon}
                        alt={status}
                        className="cursor-pointer"
                      />
                      <Link
                        className="button-blue !p-2"
                        to={`/ai-analytics/${portfolioId}/${planType}/draft`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Draft Dashboard
                      </Link>
                    </>
                    )
                  )}
                </>
              ) : (
                <></>
              )}
              {(requestName === APPLICATION_SUBSCRIPTIONS.UN_PUBLISHED ||
                requestName === APPLICATION_SUBSCRIPTIONS.PUBLISHED) && (
                <Button
                  onClick={() => navigate(`/portfolio/${_id}`)}
                  className="button-blue !p-2"
                >
                  View Portfolio
                </Button>
              )}
            </div>
            {/* Shad/cn dropdown menu filtered based on status of portfolio */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  className="bg-transparent hover:bg-transparent"
                  onClick={() => handleMenuPopUp()}
                >
                  <img
                    src={dotmenu}
                    alt="dot-icon"
                    className="cursor-pointer"
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-auto mr-[70px]">
                <ul
                  className="transition-opacity duration-5000 ease-in opacity-100 p-0"
                  key="menu-items"
                >
                  {menuItems?.map((item: MenuItem) => (
                    <DropdownMenuItem
                      key={item._id}
                      onClick={() => item.onclick(portfolioId)}
                    >
                      <li
                        key={item._id}
                        className="p-1 px-3 cursor-pointer flex-start gap-2 text-sm text-gray"
                      >
                        <img
                          src={item._icon}
                          alt="Icons"
                          className="w-[20px] h-[20px]"
                        />
                        {item._name}
                      </li>
                    </DropdownMenuItem>
                  ))}
                </ul>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </CardHeader>

      <PopupMenu isModalOpen={isModalOpen} handleMenuPopUp={handleMenuPopUp} />
      {isCommentModalOpen && (
        <CommentBox
          heading="Data Connector Request"
          onClose={(status: boolean) => handleCommentBox(status)}
          onSubmit={(e: any) => commentBoxSubmit(e)}
          placeholder="Please describe the data source that you would like to connect to."
        />
      )}

      {dataConnectorRequest && (
        <DataReloadSchedule
          setDataConnectorRequest={setDataConnectorRequest}
          portfolioId={_id}
        />
      )}
    </Card>
  );
};

export default PortfolioCard;
