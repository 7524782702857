import SideNavBarHeader from "../homepage/SideNaveBarHeader";
import Logout from "../homepage/Logout";
import { AdminPageSideBar } from "./NavbarData";
import MenuCard from "../homepage/MenuCard";
interface AdminNavbarProps {
  setSelectedMenuItem: any;
  selectedMenuItem: string;
}

const Navbar = ({
  selectedMenuItem,
  setSelectedMenuItem,
}: AdminNavbarProps) => {
  const handleMenuItemClick = (menuItem: string) => {
    setSelectedMenuItem(menuItem);
  };

  const handleSubMenuClick = (_name: string, path: string) => {
    handleMenuItemClick(path);
    setSelectedMenuItem(path);
  };
  return (
    <div className="min-w-[340px] font-base p-4 mt-3 overflow-y-auto scrollbar-custom ">
      <div className="">
        {/* Lemmata icon */}
        <SideNavBarHeader />
        {/* Sidebar content */}
        <div>
          {/* Navigation */}
          <div>
            <hr className="mb-3" />
            {AdminPageSideBar.map((item: any) => {
              return (
                <div
                  key={item._id}
                  className={`mt-3 text-gray cursor-pointer
                               ${
                                 selectedMenuItem === item.path
                                   ? "!font-bold  !text-blue-600"
                                   : "hover:font-bold hover:text-blue-500"
                               }`}
                >
                  <MenuCard
                    key={item._id}
                    _id={item._id}
                    _icon={item._icon}
                    _name={item._name}
                    _counter={item.counter}
                    _path={item.path}
                    onclick={handleSubMenuClick}
                    selectedMenuItem={selectedMenuItem}
                    selectedIcon={item.selectedIcon}
                    isPlatformSubscribed={true}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Fixed to the bottom */}
      <div className="fixed bottom-[-20px] mb-2 min-w-[340px] font-base pr-8">
        <Logout />
      </div>
    </div>
  );
};

export default Navbar;
