import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "@/utils/axiosConfig";
import { PORTFOLIOS } from "@/utils/appConstants";

const API_PATH = import.meta.env.VITE_API_CORE_PATH;
const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getCsvList = createAsyncThunk(
  "Get All Csv List",
  async ({ id }: { id: any }) => {
    try {
      const response = await instance.get(`${API_PATH}/${PORTFOLIOS}/${id}/file-uploads`);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
export const deleteCsvFile = createAsyncThunk(
  "Delete Csv",
  async ({ id }: { id: any }) => {
    try {
      const response = await instance.delete(
        `${API_PATH}/${PORTFOLIOS}/file-uploads/${id}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const uploadCsv = createAsyncThunk(
  "Upload Csv",
  async ({ pid, file, fileComments }: { pid: string; file: any,fileComments:string }) => {
    const formData = new FormData();
    // need to change this append or file , as now we have multiple files for upload in Manage Manual Uploads modal.
    // formData.append("files", file, file?.name); old implementation, not sure where we are using file.name!
    await formData.append("fileComments", fileComments);
    // Handle file uploads
    if (file && Array.isArray(file)) {
      await  file.forEach((file: File) => {
        formData.append("files", file);
      });
    }
    console.log("formData", formData.values());
    try {
      const response = await instance.post(
        `${API_PATH}/${PORTFOLIOS}/${pid}/multi-file-uploads`,
        formData
      );
      console.log("formData", formData);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.message : error.response.data;
    }
  }
);

export const uploadSingleFile = createAsyncThunk(
  "uploadSingleFile",
  async ({
    pid,
    file,
    fileComments,
    overwriteExistingFile
  }: {
    pid: string;
    file: any;
    fileComments: string;
    overwriteExistingFile:string
  }) => {
    const formData = new FormData();
    await formData.append("fileComments", fileComments);
    await formData.append("file", file);
    await formData.append("overwriteExistingFile", overwriteExistingFile);
    try {
      const response = await instance.post(
        `${API_PATH}/${PORTFOLIOS}/${pid}/file-uploads`,
        formData
      );
      console.log("formData", formData);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.message : error.response.data;
    }
  }
);

export const changeStatus = createAsyncThunk(
  "Change Status",
  async ({ id, data }: { id: any; data: any }) => {
    try {
      //URL: http://localhost:3002/api/portfolios/id/status
      const response = await instance.patch(
        `${API_PATH}/${PORTFOLIOS}/${id}/status`,
        data
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.message : error.response.data;
    }
  }
);

const CsvSlice = createSlice({
  name: "Csv_Transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadCsv.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadCsv.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(uploadCsv.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getCsvList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCsvList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCsvList.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteCsvFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCsvFile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteCsvFile.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(changeStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(changeStatus.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(uploadSingleFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadSingleFile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(uploadSingleFile.rejected, (state: any, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default CsvSlice.reducer;
