import { updateHierarchy } from "@/state/slices/HierarchySlice";
const deepEqual = (obj1, obj2) => {
  // Check if both are identical
  if (obj1 === obj2) {
    return true;
  }

  // Check if either is not an object or is null
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  // Get keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if they have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys and values are the same
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

const checkDuplicateValueMiddleware = (store) => (next) => (action) => {
  if (action.type === updateHierarchy.type) {
    const currentState = store.getState()?.hierarchy?.data;
    if(currentState){

      const index = currentState.filter(
        (item) => item.hierarchyId === action.payload.hierarchyId
      );
      if (deepEqual(action.payload, index[0])) {
        // Don't dispatch the action if the value is the same
        return;
      }
    }else{
      return ;
    }
  }
  return next(action);
};

export default checkDuplicateValueMiddleware;
