interface LoaderProps {
  message?:string
}
const Loader = ({message}:LoaderProps) => {
  return (
    <div className="flex h-full w-fll items-center justify-center flex-col">
      <div className="spinner-square">
        <div className="square-1 square"></div>
        <div className="square-2 square"></div>
        <div className="square-3 square"></div>
      </div>
      <h1 className="text-white text-3xl">{
        message ? message : null
        }</h1>
    </div>
  );
};

export default Loader;
