import {
  EditTransparent,
  lemmLogo,
  upload_file,
} from "@/assets/icons-color/index";
import { redo, submitToPublish, undo, url, cycle } from "@/assets/icons/index";
import {
  shareIcon,
  printIcon,
  editNoteIcon,
  downloadIcon,
  deleteIcon,
} from "@/assets/icons/createportfolio";
import { useNavigate } from "react-router-dom";
import {
  APPROVAL_REQUEST_TYPE,
  EDIT_MODE,
  SideNavBarTabs,
  SYSTEM_ADMIN,
  USER_ROLES,
  VIEW_MODE,
} from "@/utils/appConstants";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/NavigationMenu";
import { Button } from "@/components/ui/Button";
import { useEffect, useState } from "react";
import { submitApproval } from "@/state/slices/SubscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import useToast from "@/hooks/useToast";
import RenamePortfolio from "./RenamePortfolio";
import { getTokenFromLocalStorage } from "../common/AuthService";

interface HeaderProps {
  handleEmailModel?: any;
  handleSaveHierarchy: (e: any, draft: boolean) => void;
  portfolioName?: any;
  isEdit?: string;
  handleUploadModel?: any;
  //handleUploadDashboardModalOpen?: () => void;
  isQuickStartEnabled?: boolean;
  handleMode?: (e: any, _Mode: string) => void;
  setPortfolioName?: any;
  portfolioId?: string;
  handleUndoAttributes?: any;
  handleRedoAttributes?: any;
  undoStack?: any;
  redoStack?: any;
  isEditAcess?: boolean;
  savingState: string;
  isSaved?: boolean;
  isFormDirty?: boolean;
}

const Header = ({
  handleSaveHierarchy,
  portfolioName,
  isEdit,
  isQuickStartEnabled,
  //handleUploadDashboardModalOpen,
  handleMode,
  setPortfolioName,
  portfolioId,
  handleUndoAttributes,
  handleRedoAttributes,
  undoStack,
  redoStack,
  isEditAcess,
  savingState,
  isSaved,
  isFormDirty,
}: HeaderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, error, info } = useToast();
  const [portfolioID, setPortfolioID] = useState("");
  const [isSubmitApproval, setIsSubmitApproval] = useState(false);
  const [isSubmitPublish, setisSubmitPublish] = useState(false);
  const [portfolioRenameModalOpen, setPortfolioRenameModalOpen] =
    useState(false);
  const [portfolioOptions, setPortfolioOptions] = useState<any>([]);

  //@ts-ignore
  const reduxTabsData = useSelector((state) => state.hierarchy.data);
  const handleRename = () => {
    setPortfolioRenameModalOpen(true);
  };

  useEffect(() => {
    loadPortfolioOptions();
  }, [portfolioId, isEditAcess]);

  // Loading portfolio options based on user Edit Access.
  function loadPortfolioOptions() {
    if (isEditAcess) {
      const portfolioOptions_ = [
        {
          text: "Rename",
          value: 1,
          icon: editNoteIcon,
          action: handleRename,
          _disabled: false,
        },
        // {
        //   text: "Print",
        //   value: 2,
        //   icon: printIcon,
        //   action: () => {
        //     console.log("print");
        //   },
        //   _disabled: true,
        // },
        // {
        //   text: "Download",
        //   value: 3,
        //   icon: downloadIcon,
        //   action: () => {},
        //   _disabled: true,
        // },
        // Below code commented as part of LEM-951 ticket
        /*  {
          text: "Upload Dashboard Url",
          value: 5,
          icon: url,
          action: handleUploadDashboardModalOpen,
          _disabled: false,
        }, */
        {
          text: "Share",
          value: 4,
          icon: shareIcon,
          action: () => { },
          _disabled: true,
        },
        // {
        //   text: "Delete",
        //   value: 5,
        //   icon: deleteIcon,
        //   action: () => {},
        //   _disabled: false,
        // },
      ];
      setPortfolioOptions(portfolioOptions_);
    } else {
      setPortfolioOptions([
        {
          text: "Share",
          value: 4,
          icon: shareIcon,
          action: () => { },
          _disabled: true,
        },
      ]);
    }
  }
  const handleClose = () => {
    // navigate("/");
    setPortfolioRenameModalOpen(!portfolioRenameModalOpen);
  };

  const handlePortfolioNameModalOpen = (status: boolean) => {
    setPortfolioRenameModalOpen(status);
  };

  const handleSubmitForApproval = () => {
    let submitForApproval = {
      portfolioId: portfolioId,
      requestType: APPROVAL_REQUEST_TYPE.SUBSCRIBE_PORTFOLIO,
      planType: SideNavBarTabs.ELITE,
      reason: "Base Subscription",
    };

    // @ts-ignore
    dispatch(submitApproval({ payload: submitForApproval })).then(
      (action: any) => {
        if (action.meta.requestStatus === "fulfilled") {
          success("Request submitted for finance approval");
        } else if (action.meta.requestStatus === "rejected") {
          error("Unable to submit request for finance approval");
        }
      }
    );
  };
  const user_roles = getTokenFromLocalStorage(USER_ROLES);
  const userRole = JSON.parse(user_roles ?? "[]");
  function showAlert() {
    const result = window.confirm(
      "You have unsaved changes. Are you sure you want to leave this page without saving?"
    );

    if (result) {
      navigate("/dashboard");
    } else {
      handleSaveHierarchy(null, true);
    }
  }
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 py-2 px-4 items-center gap-2 w-full">
        <div className="col-span-1 flex items-center justify-between">

          <button
            className="h-17 w-[114px] cursor-pointer"
            onClick={
              !userRole?.includes(SYSTEM_ADMIN)
                ? () => {
                  isFormDirty ? showAlert() : navigate("/dashboard");
                }
                : () => navigate("/admin")
            }
          >
            <img src={lemmLogo} alt="Lemmata Logo" />
          </button>
          {isEdit !== VIEW_MODE && (
            <div className="flex ml-0 md:ml-[-130px]">
              <Button
                onClick={
                  undoStack.length > 1 && !isSaved ? handleUndoAttributes : null
                }
                className={`${undoStack.length > 1 && !isSaved
                    ? "cursor-pointer"
                    : "cursor-default"
                  } rounded-r-none border border-gray-300 hover:bg-gray-100 bg-transparent h-8`}
              >
                <img src={undo} className="h-[16px] w-[16px]" alt="Undo" />
              </Button>
              <Button
                onClick={
                  redoStack.length > 0 && !isSaved ? handleRedoAttributes : null
                } // && (!isSaved)
                className={`redobtn rounded-l-none border border-gray-300 hover:bg-gray-100 bg-transparent h-8 ${redoStack.length > 0 && !isSaved
                    ? "cursor-pointer"
                    : "cursor-default"
                  }`}
              >
                <img src={redo} className="h-[16px] w-[16px]" alt="Redo" />
              </Button>
            </div>
          )}

          <div className="relative md:w-[200px] flex justify-end mt-4 md:mt-0">
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger
                    className="!bg-transparent "
                    onPointerMove={(event) => event.preventDefault()}
                    onPointerLeave={(event) => event.preventDefault()}
                  >
                    {portfolioName}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent
                    onPointerEnter={(event) => event.preventDefault()}
                    onPointerLeave={(event) => event.preventDefault()}
                  >
                    <ul className="flex w-[200px] items-start font-semibold flex-col gap-1 p-2">
                      {portfolioOptions?.map((item: any) => (
                        <li
                          key={item.text}
                          className={`flex items-center w-[180px] justify-start gap-2 flex-1 ${item._disabled ? " text-gray-400 " : ""
                            }`}
                        >
                          <NavigationMenuLink
                            asChild
                            className={`flex h-full w-full p-2 select-none flex-row items-center gap-2 rounded-md ${!item._disabled &&
                              "hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                              }  `}
                            onClick={!item._disabled ? item.action : undefined}
                          >
                            <div className="flex flex-wrap flex-row text-sm font-medium">
                              <img
                                src={item.icon}
                                alt={item.text}
                                className="w-[16px] h-[16px]"
                              />
                              <span className="flex-1">{item.text}</span>
                            </div>
                          </NavigationMenuLink>
                        </li>
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </div>
        </div>

        <div className="col-span-1 flex justify-end mt-4 md:mt-0">
          {isEdit === VIEW_MODE ? (
            isEditAcess && (
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-2 px-3 rounded-sm flex items-center"
                onClick={
                  handleMode ? (e) => handleMode(e, EDIT_MODE) : () => { }
                }
              >
                <img src={EditTransparent} alt="edit" className="mr-2" />
                <span>Edit</span>
              </button>
            )
          ) : (
            <div className="flex items-center justify-between gap-3">
              {savingState && (
                <div className="flex gap-1">
                  <img
                    src={cycle}
                    alt="loading"
                    className=" animate-spin h-5 w-5 "
                  />
                  <span
                    className={` transition-opacity ease-in duration-900 opacity-100 mr-5 line-clamp-1 font-semibold text-sm `}
                  >
                    {savingState}
                  </span>
                </div>
              )}
              <div className="flex gap-3">
                <button
                  className={`bg-blue-500 hover:bg-blue-600 ${!isSaved || reduxTabsData[0]?.nodes?.length === 0
                      ? "cursor-not-allowed opacity-40"
                      : "cursor-pointer"
                    } text-white text-xs py-2 px-3 rounded-sm flex items-center`}
                  onClick={(e) => handleSaveHierarchy(e, true)}
                  disabled={!isSaved || reduxTabsData[0].nodes.length === 0}
                >
                  <img
                    src={upload_file}
                    alt="Submit to Publish"
                    className="mr-1"
                    style={{ color: "blue" }}
                  />
                  <span>Save</span>
                </button>

                {isSubmitApproval ? (
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-2 px-3 rounded-sm flex items-center"
                    onClick={handleSubmitForApproval}
                    disabled={isQuickStartEnabled}
                  >
                    <img
                      src={submitToPublish}
                      alt="Submit to Publish"
                      className="mr-2"
                    />
                    <span>Submit for approval</span>
                  </button>
                ) : (
                  ""
                )}

                {isSubmitPublish ? (
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-2 px-3 rounded-sm flex items-center"
                    onClick={(e) => handleSaveHierarchy(e, false)}
                    disabled={isQuickStartEnabled}
                  >
                    <img
                      src={submitToPublish}
                      alt="Submit to Publish"
                      className="mr-2"
                    />
                    <span>Submit to Publish</span>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <RenamePortfolio
        isOpen={portfolioRenameModalOpen}
        onClose={handleClose}
        setPortfolioName={setPortfolioName}
        handlePortfolioNameModalOpen={handlePortfolioNameModalOpen}
        portfolioId={portfolioId}
        portfolioName={portfolioName}
      />
    </>
  );
};

export default Header;
