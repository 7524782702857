import React, { useState } from "react";
import { REASONBOX_TYPE } from "@/utils/appConstants";
import { Button } from "@/components/ui/Button";
import { Textarea } from "@/components/ui/Textarea";
type ReasonBoxProps = {
  paramsData: any;
  setIsDeny?: any;
  requestType?: string;
  headerText?: string;
  methodCall?: any;
};

const ReasonBox = ({
  paramsData,
  setIsDeny,
  requestType,
  headerText,
  methodCall,
}: ReasonBoxProps) => {
  const [reason, setReason] = useState("");
  const handleConfirmation = () => {
    if (requestType == REASONBOX_TYPE.DENY) {
      methodCall(paramsData.item, paramsData.reqType, reason);
    }
  };
  const handleCancelModel = () => {
    setIsDeny?.(false);
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50">
        <div className="bg-white rounded-lg p-6 sm:w-1/3 w-1/3 l:w-1/3 w-1/3">
          <h2 className="text-lg font-semibold ml-4 mb-4">{headerText}</h2>
          <div className="p-2">
              <div className="mb-2">
                  {/* <input
                  type="text"
                  id="DenyReason"
                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  //onKeyDown={handleKeyDown}
                /> */}
                <Textarea
                  id="reason"
                  placeholder="Why was this request denied?"
                  value={reason}
                  onChange={(e:any) => setReason(e.target.value)}
                />
            </div>
            <div className="flex justify-center items-center gap-4">
              <Button
                variant={"outline"}
                className="mr-2 px-4 py-2 border border-gray-300 rounded-md flex-1 max-w-24 mt-5"
                onClick={handleCancelModel}
              >
                Cancel
              </Button>
              <Button
                variant="default"
                onClick={handleConfirmation}
                className="px-4 py-2 bg-blue-500  flex-1 max-w-24 mt-5"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReasonBox;
