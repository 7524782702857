import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "@/utils/axiosConfig";
import { RootState } from "@/state/store";
const API_PATH = import.meta.env.VITE_API_CORE_PATH;
const GET_AI_ANALYTICS = `${API_PATH}/aianalytics/pyramid`;
interface PyramidConfig {
  data: any | null;
  loading: boolean;
  error: string | null | undefined;
}

const initialState: PyramidConfig = {
  data: null,
  loading: false,
  error: null,
};

export const getPyramidConfig = createAsyncThunk(
  "/pyramid/configuration",
  async () => {
    try {
      const response = await instance.get(`${GET_AI_ANALYTICS}/configuration`);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
export const postPyramidConfig = createAsyncThunk(
  "/pyramid/configuration",
  async (data: any) => {
    try {
      const response = await instance.post(`${GET_AI_ANALYTICS}/configuration`, data);
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
) 

const AIAnalyticsSlice = createSlice({
  name: "pyramid/configuration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPyramidConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPyramidConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPyramidConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default AIAnalyticsSlice.reducer;
export const AIAnalyticsSelector = (state: RootState) => state?.pyramidConfig?.data;