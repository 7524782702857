import React, { useState } from "react";
import { Label } from "@/components/ui/Label";
import { Textarea } from "@/components/ui/Textarea";
type CommentAreaProps = {
  setFileComment: any;
  fileComment:string;
};
const CommentArea: React.FC<CommentAreaProps> = ({ setFileComment,fileComment }) => {
  const handleChange = (e: any) => {
    const commentText = e.target.value;
    setFileComment(commentText);
  };
  return (
    <div>
      <Label className="font-bold" htmlFor="Comment">
        Comment
      </Label>
      <Textarea
        id="Comment"
        placeholder="Comment about file"
        value={fileComment}
        // @ts-ignore
        onChange={(e) => handleChange(e)}
        className={`my-3`}
      />
    </div>
  );
};
export default CommentArea;
