import { searchIcon } from "@/assets/icons-color";
import { useEffect, useState } from "react";
interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}
const Header: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length >= 2) {
        onSearch(searchTerm);
      }
       else {
        searchTerm.length === 0 && onSearch("");
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  const handleClear = () => {
    setSearchTerm("");
    onSearch("");
  };
  return (
    <div className="p-4 flex items-center justify-between h-[76px] gap-3 w-full">
      <div className="flex items-center rounded-md p-2 w-full border">
        <img src={searchIcon} alt="Search" className="w-6 h-6 mr-3" />
        <input
          type="text"
          placeholder="Search all portfolios"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-grow outline-none text-black "
        />
        {searchTerm && (
          <button onClick={handleClear} className="mr-5 text-lg text-black">
            &times;
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
