import { generateUuid, generatingHierarchyAftResponse } from "@/utils/utils";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import instance from "@/utils/axiosConfig";
const API_PATH = import.meta.env.VITE_API_CORE_PATH;
interface HierarchyData {
  hierarchyName: string;
  hierarchyId: string;
  nodes: any[];
  edges: any[];
}
interface initialStateInterface {
  data: HierarchyData[];
  loading: boolean;
  error: string | null | undefined;
  embed: string;
}
const initialState: initialStateInterface = {
  data: [
    {
      hierarchyName: "Untitled",
      hierarchyId: generateUuid(),
      nodes: [],
      edges: [],
    },
  ],
  loading: false,
  error: null,
  embed: "",
};

export const getPortfolioHierarchyDatabyId = createAsyncThunk(
  "get Portfolio Hierarchy Data",
  async ({ id }: { id: string }) => {
    try {
      const response = await instance.get(`${API_PATH}/portfolios/${id}`);
      const data =  generatingHierarchyAftResponse(
        response.data.data.hierarchies,
        response.data.data.attributes
      );
      if(data){
        return data;
      }else{
        return initialState.data;
      }

    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
const HierarchyDataSlice = createSlice({
  name: "HierarchyData",
  initialState,
  reducers: {
    updateHierarchy: (state, action: PayloadAction<HierarchyData>) => {
      const { hierarchyId, hierarchyName, nodes, edges } = action.payload;
      // check if the state value and the updating value is same or not
      const index = state.data.findIndex(
        (item) => item.hierarchyId === hierarchyId
      );
      if (index !== -1) {
        state.data[index] = { hierarchyId, hierarchyName, nodes, edges };
      }
      return state;
    },

    updateAllHierarchyOnEdit: (
      state,
      action: PayloadAction<HierarchyData[]>
    ) => {
      state.data = action.payload;
      return state;
    },

    AddHierarchy: (state, action: PayloadAction<HierarchyData>) => {
      const { hierarchyId, hierarchyName, nodes, edges } = action.payload;
      state.data.push({ hierarchyName, hierarchyId, nodes, edges });

      return state;
    },
    deleteHierarchy: (
      state,
      action: PayloadAction<{ hierarchyId: string }>
    ) => {
      const { hierarchyId } = action.payload;
      state.data = state.data.filter(
        (item) => item.hierarchyId !== hierarchyId
      );

      return state;
    },
    renameHierarchy: (
      state,
      action: PayloadAction<{ hierarchyId: string; hierarchyName: string }>
    ) => {
      const { hierarchyId, hierarchyName } = action.payload;
      const index = state.data.findIndex(
        (item) => item.hierarchyId === hierarchyId
      );
      if (index !== -1) {
        state.data[index].hierarchyName = hierarchyName;
      }

      return state;
    },
    getAllHierarchyData: (state) => {
      return state;
    },
    resetHierarchyData: (state) => {
      return initialState;
    },
    setErrors: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPortfolioHierarchyDatabyId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPortfolioHierarchyDatabyId.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPortfolioHierarchyDatabyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setErrors,
  resetErrors,
  updateHierarchy,
  AddHierarchy,
  getAllHierarchyData,
  deleteHierarchy,
  renameHierarchy,
  resetHierarchyData,
  updateAllHierarchyOnEdit,
} = HierarchyDataSlice.actions;
export default HierarchyDataSlice.reducer;
export function getHierarchyDataById(id: string) {
  const hierarchyData = useSelector(
    (state: RootState) =>
      state.hierarchy &&
      state.hierarchy.data?.find((item) => item?.hierarchyId === id)
  );

  if (!hierarchyData) {
    return {
      type: "GET_HIERARCHY_DATA_BY_ID_SUCCESS",
      dataNodes: [],
      dataEdges: [],
    };
  }

  const { nodes, edges, hierarchyName } = hierarchyData;
  return {
    type: "GET_HIERARCHY_DATA_BY_ID_SUCCESS",
    nodesData: nodes,
    edgesData: edges,
    hierarchyName,
  };
}
