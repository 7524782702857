import { Label } from "@/components/ui/Label";
import { Input } from "@/components/ui/Input";
import { Textarea } from "@/components/ui/Textarea";
import { close, url } from "@/assets/icons/index";
import React, { useState } from "react";
import { yearlyPlansDetails } from "@/utils/appConstants";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/state/store";
import { platformSubscription } from "@/state/slices/SubscriptionSlice";
import useToast from "@/hooks/useToast";
type PlatformSubscribeDialogProps = {
  setIsPlatformSubscribing?: any;
  setIsPlatformSubscribed?: any;
};

export default function PlatformSubscribeDialog({
  setIsPlatformSubscribing,
  setIsPlatformSubscribed,
}: PlatformSubscribeDialogProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { success } = useToast();
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    yearlyPlansDetails[1]?.plan
  );
  const [billingFrequency, setBillingFrequency] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const onClose = () => {
    setIsPlatformSubscribing(false);
  };

  const handleOnChange = (e: any, plan: any) => {
    setSelectedOption(e.target.value);
    setBillingFrequency(plan);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    } else if (event.key === "Escape") {
      setIsPlatformSubscribing(false);
    }
  };

  const handlePromoCodeChange = (e: any) => {
    setErrorMsg("");
    setPromoCode(e.target.value);
  };
  const handleSubmit = (e: any) => {
    setIsDisabled(true);
    let billingFrequencyPlan: any;
    if (!billingFrequency) {
      billingFrequencyPlan = selectedOption;
    } else {
      billingFrequencyPlan = billingFrequency;
    }
    // Prepare the payload object
    const payload: any = { planType: billingFrequencyPlan };

    // Conditionally add promo code if promo code is not empty
    if (promoCode) {
      payload.couponCode = promoCode;
    }
    dispatch(platformSubscription(payload as any)).then((action: any) => {
      if (platformSubscription.fulfilled.match(action)) {
        success(`Platform Subscription Activated Successfully`);
        setIsPlatformSubscribed(true); //enabling create button
        setPromoCode("");
        setIsPlatformSubscribing(false);
      } else if (platformSubscription.rejected.match(action)) {
        if (action?.error?.message === "400") {
          setIsDisabled(false);
          setErrorMsg("Invalid promo code");
        }
        if (action?.error?.message === "409") {
          setIsDisabled(true);
          setErrorMsg("Platform subscription already exists");
        }
      }
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50 z-20">
      <div className="bg-white rounded-lg p-6 sm:w-1/3 w-1/3 l:w-1/3 w-1/3">
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-lg font-semibold">Platform Subscription</h2>
          <button className="text-gray-500" onClick={onClose}>
            <img src={close} className="w-6 h-6 text-gray-600" alt="close" />
          </button>
        </div>
        <div className="p-4">
          <p className="text-sm mb-3">
            Get started by activating a platform subscription. Choose from
            annual plans with 1 or 3 year terms
          </p>
          {yearlyPlansDetails.map((item: any) => (
            <div className="mb-4" key={item.id}>
              <input
                type="radio"
                id={item.id}
                className="px-3 py-2 cursor-pointer"
                value={item.plan}
                checked={selectedOption === item.plan}
                onChange={(e) => handleOnChange(e, item.plan)}
                onKeyDown={handleKeyDown}
              />
              <span className="text-sm font-medium ml-3 ">{item.planName}</span>
            </div>
          ))}
        </div>
        <div>
          <Label htmlFor="promo">Promo Code</Label>
          <Input
            className="mt-1"
            id="promo"
            placeholder="Please enter Promo Code"
            value={promoCode}
            onChange={(e) => handlePromoCodeChange(e)}
          />
          {errorMsg && <p className="text-red-500">{errorMsg}</p>}
        </div>
        <div className="flex justify-center  mt-5 ">
          <button
            className="mr-2 px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2  bg-blue-500 text-white rounded-md hover:bg-blue-600 ${isDisabled ? "cursor-not-allowed opacity-50" : ""}`}
            onClick={(e) => handleSubmit(e)}
            disabled={isDisabled}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
