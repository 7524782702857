// Function to save access token to local storage
export const saveTokenToLocalStorage = (tokenName:string,tokenValue:string) => {
    localStorage.setItem(tokenName, tokenValue);
    
};
  
// Function to get access token from local storage
export const getTokenFromLocalStorage = (tokenName:string) => {
    return localStorage.getItem(tokenName);
    
};

// Function to remove access token from local storage
export const removeTokenFromLocalStorage = (tokenNames: Array<string>) => {
    if (Array.isArray(tokenNames) && tokenNames.length>0) {
      tokenNames.forEach((tokenName) => {
        localStorage.removeItem(tokenName);
      });
    } else {
      console.warn('Expected an array of token names.');
    }
};