/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  className: any;
  help: string;
}

export const Help = ({ className, help = "/icons/help.svg" }: Props): JSX.Element => {
  return <img className={`absolute w-[24px] h-[24px] top-0 left-0 ${className}`} alt="Help" src={help} />;
};

Help.propTypes = {
  help: PropTypes.string,
};
