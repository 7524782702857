import {
  submitToPublish,
  submitSuccessIcon,
} from "@/assets/icons";
import React, { useState } from "react";
import { Button } from "@/components/ui/Button";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatus,
  getCsvList,
  uploadCsv,
} from "@/state/slices/CsvSlice";
import { PROCESSING_STATUS } from "@/utils/appConstants";
import useToast from "@/hooks/useToast";

import DropArea from "@/components/custom-components/upload-csv/DropArea";
import Uploads from "@/components/custom-components/upload-csv/Uploads"
import ModalHeader from "./ModalHeader";

type UploadModalProps = {
  onClose: () => void;
  isOpen: boolean;
  portfolioId: string;
};
const UploadCsvModal: React.FC<UploadModalProps> = ({
  onClose,
  isOpen,
  portfolioId,
}) => {
  const dispatch = useDispatch();
  const [csvList, setCsvList] = useState([]);
  const [file, setFile] = useState<File | null>(null);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [isSubmitToPublish, setIsSubmitToPublish] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { success, error, info, promise } = useToast();

/*   function isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  } */

  //console.log('Portfolio Id => ',portfolioId);
 /*  const handleDrop = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    if (acceptedFiles.length > 0) {
      if (isCSVFile(acceptedFiles[0])) {
        setFile(acceptedFiles[0]);
      } else {
        // Handle non-CSV files
        setTimeout(() => {
          info("Please select CSV file only.");
        }, 1000);
        return;
      }
    }
  }; */

  const handleCancel = () => {
    setFile(null);
    setIsUpload(false);
    setIsSubmitToPublish(false);
    onClose();
  };
  const handleUpload = () => {
    if (!file) {
      setTimeout(() => {
        info("Please select/drop CSV file.");
      }, 1000);
      return;
    }
    // @ts-ignore
    dispatch(uploadCsv({ pid: portfolioId, file: file })).then(
      (action: any) => {
        if (uploadCsv.fulfilled.match(action)) {
          setTimeout(() => {
            success("File uploaded successfully");
          }, 1000);
          /* Get All Uploads */
          // @ts-ignore
          dispatch(getCsvList({ id: portfolioId })).then((action) => {
            if (getCsvList.fulfilled.match(action)) {
              setIsUpload(true); // Set isUpload to true when upload button is clicked
              setCsvList(action.payload.data);
            }
            if (getCsvList.rejected.match(action)) {
              error("Error while getting the list of files");
            }
          });
        }
        if (uploadCsv.rejected.match(action)) {
          error("Error while uploading the file");
        }
      }
    );
  };
  const handleUploadMore = () => {
    setIsUpload(false); // Set isUpload to true when upload button is clicked
    setFile(null);
  };

  if (!isOpen) {
    return null;
  }

  const handleSubmit = () => {
    const payload = {
      status: PROCESSING_STATUS,
    };
    //@ts-ignore
    dispatch(changeStatus({ id: portfolioId, data: payload })).then(
      (action: unknown) => {
        if (changeStatus.fulfilled.match(action)) {
          setTimeout(() => {
            success("Portfolio status updated successfully");
          }, 1000);
          //setCsvList(action.payload.data);
          setIsSubmitToPublish(true);
        }
        if (changeStatus.rejected.match(action)) {
          error("Error while uploading the portfolio");
        }
      }
    );
  };
  const handleReturnToPortfolio = () => {
    setIsUpload(false);
    setFile(null);
    setIsSubmitToPublish(false);
    navigate("/");
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 my-auto bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 sm:w-1/2 w-2/3">
        <ModalHeader isUpload = {isUpload} 
          handleUploadMore = {handleUploadMore} 
          handleCancel = {handleCancel}
          isSubmitToPublish = {isSubmitToPublish}
          modalTitle = {"Submit to Publish"}
        />

        {!isUpload && !isSubmitToPublish && (          
          <DropArea file = {file} setFile={setFile}/>
        )}
        {isUpload && !isSubmitToPublish && (
          <Uploads csvList={csvList} setCsvList={setCsvList} />
        
        )}
        {isSubmitToPublish && (
          <div className="publishSucess mb-3 h-[317px] p-4 border-gray-300 rounded-md cursor-pointer flex justify-center items-center">
            <div className="w-full sm:w-auto">
              <div className="rounded-md">
                <img
                  src={submitSuccessIcon}
                  alt="Submit Success"
                  className="mx-auto"
                />
                <h4 className="text-center">
                  Your Portfolio has been successfully submitted
                </h4>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-end">
          <div className="mr-2">
            <Button
              variant="secondary"
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>

          {!isUpload && !isSubmitToPublish && (
            <Button
              onClick={handleUpload}
              className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center ${!file ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={!file}
            >
              <img src={submitToPublish} alt="Upload" className="mr-2" />
              Upload
            </Button>
          )}
          {isUpload && !isSubmitToPublish && (
            <Button
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center"
            >
              <img src={submitToPublish} alt="Upload" className="mr-2" />
              Submit
            </Button>
          )}
          {isSubmitToPublish && (
            <Button
              onClick={handleReturnToPortfolio}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center"
            >
              Return to portfolio
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadCsvModal;