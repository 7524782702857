import { BusinessUnit, IndustrySegment, MajorFeature, MinorFeature, Product, Program, Project, StrategicInitiative, Strategy, TechSegment } from "@/assets/attributesIcon";

export type ConstantsItem = {
  [x: string]: any;
  attributeId: string;
  attributeName: string;
  icon: any; // If you have a specific type for icons, you can include it here
  description: string;
};

export const AttributeConstants: ConstantsItem[] = [
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc001",
    attributeName: "Strategy",
    icon: Strategy,
    description: "Strategy",
  },
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc002",
    attributeName: "Strategic Initiative",
    icon: StrategicInitiative,
    description: "Strategic Initiative",
  },
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc003",
    attributeName: "Industry Segment",
    icon: IndustrySegment,
    description: "Industry Segment",
  },
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc004",
    attributeName: "Tech Segment",
    icon: TechSegment,
    description:  "Tech Segment",
  },
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc005",
    attributeName: "Business Unit",
    icon: BusinessUnit,
    description: "Business Unit",
  },
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc006",
    attributeName: "Product",
    icon: Product,
    description: "Product",
  },
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc007",
    attributeName: "Program",
    icon: Program,
    description: "Program",
  },
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc008",
    attributeName: "Project",
    icon: Project,
    description:  "Project",
  },
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc009",
    attributeName: "Major Feature",
    icon: MajorFeature,
    description: "Major Feature",
  },
  {
    attributeId: "11bf5b37-e0b8-42e0-8dcf-dc8c4aefc000",
    attributeName: "Minor Feature",
    icon: MinorFeature,
    description: "Minor Feature",
  },
];


