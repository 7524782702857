import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "@/utils/axiosConfig";
import { RootState } from "@/state/store";
import { ServerError, SideNavBarTabs } from "@/utils/appConstants";
const API_PATH = import.meta.env.VITE_API_CORE_PATH;

interface PortfolioData {
  // My Subscription
  mySubscription: Record<string, any>;
  // Rapid
  portfolioRapid: Record<string, any>;
  pendingRapid: Record<string, any>;
  // Accelerate
  portfolioAccelerate: Record<string, any>;
  pendingAccelerate: Record<string, any>;
  // Elite
  portfolioElite: Record<string, any>;
  pendingElite: Record<string, any>;
 
  // My portfolios
  published: Record<string, any>;
  unPublished: Record<string, any>;
}

interface PortfolioState {
  data: PortfolioData | null;
  loading: boolean;
  error: string | null | undefined;
  embed: string;
}

const initialState: PortfolioState = {
  data: {
    mySubscription:{},
    portfolioRapid: {},
    pendingRapid: {},
    portfolioAccelerate: {},
    pendingAccelerate: {},
    portfolioElite: {},
    pendingElite: {},
     published: {},
    unPublished: {},
  },
  loading: false,
  error: null,
  embed: "",
};
// get all published and un published for my portfolio section both tabs data is handled in same api call as we are getting the data in a same api
export const getMyPortfolios = createAsyncThunk(
  "portfolio/getMyPortfolios",
  async () => {
    try {
      const response = await instance.get(
        `${API_PATH}/portfolios/my-portfolios`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
// pass plan type to this function and get all the pending portfolio approvals after the user is subscribed too
export const pendingPortfolios = createAsyncThunk(
  "/api/portfolios/get/pending-dashboards",
  async (data: any) => {
    try {
      const response = await instance.get(
        `${API_PATH}/subscriptions/pending?planType=${data}`
      );
      return response.data.data;
    } catch (error: any) {
      throw error.response
        ? error?.response?.data?.message[0]
        : "Error while creating portfolio";
    }
  }
);
// get the subscribed portfolio list based on the plan type
export const getSubscribedPortfolios = createAsyncThunk(
  "/portfolios/my-subscriptions/{subscriptionPlans}",
  async (data: any) => {
    try {
      const response = await instance.get(
        `${API_PATH}/portfolios/my-subscriptions/${data}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response
        ? error?.response?.data?.message[0]
        : "Error while creating portfolio";
    }
  }
);
// Get all the portfolios which are subscribed
export const getMySubscription = createAsyncThunk(
  "portfolio/getMySubscription",
  async () => {
    try {
      const response = await instance.get(
        `${API_PATH}/portfolios/my-subscriptions/all`
      );
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

const PortfolioSliceUpdate = createSlice({
  name: "portfolio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyPortfolios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyPortfolios.fulfilled, (state, action) => {
        state.loading = false;
        if (state.data) {
          state.data.published = action.payload.data.published;
          state.data.unPublished = action.payload.data.unpublished;
        }
      })
      .addCase(getMyPortfolios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? ServerError;
      });

    // Pending Dashboards block
    builder
      .addCase(pendingPortfolios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(pendingPortfolios.fulfilled, (state, action) => {
        state.loading = false;
        if (state.data) {
          if (action.meta.arg === SideNavBarTabs.RAPID)
            state.data.pendingRapid = action.payload.portfolios;
          else if (action.meta.arg === SideNavBarTabs.ACCELERATE) {
            state.data.pendingAccelerate = action.payload.portfolios;
          } else if (action.meta.arg === SideNavBarTabs.ELITE) {
            state.data.pendingElite = action.payload.portfolios;
          }
        }
      })
      .addCase(pendingPortfolios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? ServerError;
      });

    // Subscription block
    builder
      .addCase(getSubscribedPortfolios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubscribedPortfolios.fulfilled, (state, action) => {
        state.loading = false;
        if (state.data) {
          if (action.meta.arg === SideNavBarTabs.RAPID)
            state.data.portfolioRapid = action.payload;
          else if (action.meta.arg === SideNavBarTabs.ACCELERATE) {
            state.data.portfolioAccelerate = action.payload;
          } else if (action.meta.arg === SideNavBarTabs.ELITE) {
            state.data.portfolioElite = action.payload;
          }
        }
      })
      .addCase(getSubscribedPortfolios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? ServerError;
      });
    // My Subscription
    builder
      .addCase(getMySubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMySubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.data.mySubscription = action.payload ?? [];
      })
      .addCase(getMySubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? ServerError;
      });
  },
});

export default PortfolioSliceUpdate.reducer;
export const PortfolioSliceUpdateSelector = (state: RootState) =>
  state?.portfolioUpdate?.data;

export const isLoadingPortfolio = (state: RootState) =>
  state?.portfolioUpdate?.loading;
