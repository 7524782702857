import { useEffect, useState } from "react";
import { Button } from "../ui/Button";
import { Label } from "../ui/Label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";
import timezones from "timezones-list";
import { TimePicker12Demo } from "../timer/timepicker";
import { extractTime } from "@/utils/utils";
import { AppDispatch } from "@/state/store";
import { useDispatch } from "react-redux";
import { GetAppReload, PostAppReload } from "@/state/slices/AppReloadSlice";
import useToast from "@/hooks/useToast";
import Loader from "./Loader";
import { BadgeCheck } from "@/assets/icons";
import { Period } from "../timer/time-picker-utils";

type DataReloadScheduleProps = {
  setDataConnectorRequest: (status: boolean) => void;
  portfolioId: string;
};
type AppReloadSelectType = {
  _id: string;
  AppRoutineName: string;
  AppRoutineDisplayName: string;
};

const WeeksDays = [
  { value: "Monday", label: "Mon" },
  { value: "Tuesday", label: "Tues" },
  { value: "Wednesday", label: "Wed" },
  { value: "Thursday", label: "Thu" },
  { value: "Friday", label: "Fri" },
  { value: "Saturday", label: "Sat" },
  { value: "Sunday", label: "Sun" },
];
const datesOfMonth = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
  { value: 31, label: "31" },
];
const DataReloadSchedule = ({
  setDataConnectorRequest,
  portfolioId,
}: DataReloadScheduleProps) => {
  const { success, error } = useToast();
  const [date, setDate] = useState<Date>();
  const [appReload, setAppReload] = useState<string>("");
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedDates, setSelectedDates] = useState<any[]>([]);
  const [timeZoneData, setTimeZoneData] = useState<string>("");
  const [isActive, setIsActive] = useState(false);
  const [period, setPeriod] = useState<Period>("PM");
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [createSchedule, setCreateSchedule] = useState(true);
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const appReloadSelect = [
    {
      _id: "1",
      AppRoutineName: "daily",
      AppRoutineDisplayName: "Daily",
    },
    {
      _id: "2",
      AppRoutineName: "weekly",
      AppRoutineDisplayName: "Weekly",
    },
    {
      _id: "3",
      AppRoutineName: "monthly",
      AppRoutineDisplayName: "Monthly",
    },
  ];

  const handleTimeChange = (timeString: string) => {
    const timeRegex = /^(\d{1,2}):(\d{2})\s?(AM|PM)?$/i;
    const match = timeString.match(timeRegex);

    if (!match) {
      console.error("Invalid time format");
      setDate(null); // Clear the date if invalid
      return;
    }

    let hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const ampm = match[3];

    if (ampm) {
      if (ampm.toUpperCase() === "PM" && hours !== 12) {
        hours += 12;
      } else if (ampm.toUpperCase() === "AM" && hours === 12) {
        hours = 0;
      }
    } else if (hours > 23 || minutes > 59) {
      console.error("Invalid time format");
      setDate(null);
      return;
    }

    const now = new Date();
    now.setHours(hours, minutes, 0, 0);

    setDate(now); // Update the state with the new Date object
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(GetAppReload({ id: portfolioId }))
      .then((action: any) => {
        if (GetAppReload.fulfilled.match(action)) {
          setCreateSchedule(false);
          setAppReload(action.payload.data.frequency);
          setTimeZoneData(action.payload.data.timezone);
          setSelectedDays(action.payload.data.daysOfWeek);
          handleTimeChange(action.payload.data.timeOfDay);
          setSelectedDates(action.payload.data.daysOfMonth);
          setIsActive(action.payload.data.status === "Active" ? true : false);
          setPeriod(action.payload.data.timeOfDay.split(" ")[1]);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setCreateSchedule(true);
      });
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [portfolioId]);

  const [errors, setErrors] = useState({
    appReload: "",
    weekly: "",
    monthly: "",
    timeZone: "",
  });

  const validate = () => {
    const newErrors = { ...errors }; // Create a copy to avoid directly mutating state

    if (appReload === "") {
      newErrors.appReload = "App Reload is required";
    } else {
      newErrors.appReload = "";
    }

    if (appReload === "weekly" && selectedDays.length === 0) {
      newErrors.weekly = "Weekly selection is required";
    } else {
      newErrors.weekly = "";
    }

    if (appReload === "monthly" && selectedDates.length === 0) {
      newErrors.monthly = "Monthly selection is required";
    } else {
      newErrors.monthly = "";
    }

    if (timeZoneData === "") { // Corrected condition: Check if it's empty
      newErrors.timeZone = "Time Zone is required";
    } else {
      newErrors.timeZone = "";
    }

    setErrors(newErrors); // Update state once after all checks
    return Object.values(newErrors).some((error) => error !== ""); // Return true if there are errors
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsDisabledBtn(true);
    const hasErrors = validate();

    if (hasErrors) {
      return; // Stop further execution if there are errors
    }
    const timeOfDay = extractTime(date);
    let payloadData = {};
    if (appReload === "daily") {
      payloadData = {
        frequency: appReload,
        timesPerDay: "once",
        timezone: timeZoneData,
        timeOfDay: timeOfDay,
        runSchedule: "continuously",
        daysOfWeek: [],
        daysOfMonth: [],
      };
    } else if (appReload === "weekly") {
      payloadData = {
        frequency: appReload,
        timesPerDay: "once",
        timezone: timeZoneData,
        timeOfDay: timeOfDay,
        runSchedule: "continuously",
        daysOfWeek: selectedDays,
        daysOfMonth: [],
      };
    } else if (appReload === "monthly") {
      payloadData = {
        frequency: appReload,
        timesPerDay: "once",
        timezone: timeZoneData,
        timeOfDay: timeOfDay,
        runSchedule: "continuously",
        daysOfMonth: selectedDates,
        daysOfWeek: [],
      };
    }
    dispatch(PostAppReload({ id: portfolioId, data: payloadData }))
      .then((action) => {
        if (PostAppReload.fulfilled.match(action)) {
          setConfirmationBox(true);
          setIsDisabledBtn(false);
          success("Portfolio data reload request sent.");
        } else {
          setDataConnectorRequest(true);
          error("Portfolio data reload request failed.");
        }
      })
      .catch((err: any) => {
        setDataConnectorRequest(true);
        setConfirmationBox(false);
        error("Portfolio data reload request failed.");
      });
  };

  const handleWeekDays =
    (value: string) => (event: React.MouseEvent<HTMLDivElement>) => {
      setSelectedDates([]);
      setSelectedDays((prev) => {
        if (prev.includes(value)) {
          return prev.filter((item) => item !== value);
        } else {
          return [...prev, value];
        }
      });
    };
  const handleDates =
    (value: number | string) => (event: React.MouseEvent<HTMLDivElement>) => {
      setSelectedDays([]);
      setSelectedDates((prev) => {
        if (prev.includes(value)) {
          return prev.filter((item) => item !== value);
        } else {
          return [...prev, value];
        }
      });
    };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg">
            {createSchedule ? (
              <div className="flex flex-col justify-center items-center gap-4">
                <span className="font-bold">
                  No schedule for this portfolio
                </span>
                <div className="flex flex-col justify-center items-center">
                  <span>
                    Automatically update your portfolio with the latest{" "}
                  </span>
                  <span> data from its data sources.</span>
                </div>
                <div className="flex gap-3">
                  <Button
                    variant="outline"
                    className="px-4 py-2"
                    onClick={() => setDataConnectorRequest(false)}
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => setCreateSchedule(false)}>
                    Add schedule
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-semibold mb-4">
                    Reload Schedule
                  </h2>
                  {isActive && (
                    <span className="flex gap-1 text-green-500 font-bold items-center">
                      <img src={BadgeCheck} className="w-4 h-4" alt="check" />
                      Active
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <div className="gap-3 mb-3">
                    <Label>Reload this portfolio</Label>
                    <sup className="text-red-500">*</sup>
                    <Select
                      value={appReload}
                      onValueChange={(value) => setAppReload(value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select reload this portfolio" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>
                            Select the reload portfolio frequency
                          </SelectLabel>
                          {appReloadSelect.map((item: AppReloadSelectType) => (
                            <SelectItem
                              key={item._id}
                              value={item.AppRoutineName}
                            >
                              {item.AppRoutineDisplayName}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {errors.appReload != "" && (
                      <span className="text-red-500 text-sm">
                        {errors.appReload}
                      </span>
                    )}
                  </div>
                  {appReload === "weekly" && (
                    <>
                      <Label>Run on these days</Label>
                      <sup className="text-red-500">*</sup>
                      <div className="mt-3 flex flex-wrap align-center justify-between border p-2">
                        {WeeksDays.map((item) => (
                          <div
                            className={`flex align-center justify-center w-12 p-3 align-center hover:bg-blue-100 hover:text-blue-500 hover:font-bold cursor-pointer ${
                              selectedDays.includes(item.value)
                                ? "bg-blue-100 text-blue-500 font-bold"
                                : ""
                            }`}
                            onClick={handleWeekDays(item.value)}
                            key={item.value}
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                      {errors.weekly != "" && (
                        <span className="text-red-500 text-sm">
                          {errors.weekly}
                        </span>
                      )}
                    </>
                  )}
                  {/* Dates */}
                  {appReload === "monthly" && (
                    <>
                      <Label>Days of the month</Label>
                      <sup className="text-red-500">*</sup>
                      <div className="gap-3 mt-3 flex flex-wrap justify-center border p-2">
                        {datesOfMonth.map((item, index) => (
                          <div
                            className={`flex align-center justify-center border hover:border-blue-500 hover:bg-blue-100 text-sm hover:text-blue-400 cursor-pointer w-9 py-2 hover:font-bold ${
                              selectedDates.includes(item.value)
                                ? "border-blue-500 bg-blue-100 text-blue-400 font-bold"
                                : ""
                            }`}
                            onClick={handleDates(item.value)}
                            key={index}
                          >
                            {item.label}
                          </div>
                        ))}
                        <span>
                          <div
                            className={`border hover:border-blue-400 text-sm hover:text-blue-400 hover:bg-blue-100 p-2 cursor-pointer  hover:font-bold ${
                              selectedDates.includes("last day of the month")
                                ? "border-blue-500 bg-blue-100 text-blue-400 font-bold"
                                : ""
                            }`}
                            onClick={handleDates("last day of the month")}
                          >
                            last day of the month
                          </div>
                        </span>
                      </div>
                      {errors.monthly != "" && (
                        <span className="text-red-500 text-sm">
                          {errors.monthly}
                        </span>
                      )}
                    </>
                  )}
                  <div className="gap-3 mt-3">
                    <Label>Timezone</Label>
                    <sup className="text-red-500">*</sup>
                    <Select
                      value={timeZoneData}
                      onValueChange={(value) => setTimeZoneData(value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Timezone" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>Select a Product</SelectLabel>
                          {timezones?.map((item, index) => (
                            <SelectItem key={index} value={item.tzCode}>
                              {item.label}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {errors.timeZone != "" && (
                      <span className="text-red-500 text-sm">
                        {errors.timeZone}
                      </span>
                    )}
                  </div>
                  <div className="gap-3 mt-3">
                    <Label>Time of day</Label>
                    <TimePicker12Demo setDate={setDate} date={date} period={period} setPeriod={setPeriod} />
                  </div>
                </div>
                <div className="flex justify-end gap-4">
                  <Button
                    variant="outline"
                    className="px-4 py-2"
                    onClick={() => setDataConnectorRequest(false)}
                  >
                    Cancel
                  </Button>
                  <button
                    className={`px-4 py-2  bg-blue-500 text-white rounded-md hover:bg-blue-600 ${
                      isDisabledBtn
                        ? "cursor-not-allowed opacity-50 hover:bg-blue-600"
                        : ""
                    }`}
                    onClick={(e) => handleSubmit(e)}
                    disabled={isDisabledBtn}
                  >
                    {isDisabledBtn ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            )}
          </div>
          {confirmationBox && (
            <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50">
              <div className="bg-white rounded-lg p-6 sm:w-1/3 w-1/3 l:w-1/3">
                <div className="p-4">
                  <div className="mb-4 text-center">
                    <p className="text-md font-semibold">
                      Your requested schedule will be active within the next
                      business day.
                    </p>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <button
                      className="mr-2 px-4 py-2 border border-gray-300 rounded-md flex-1 max-w-24 mt-5 hover:bg-gray-100"
                      onClick={() => {
                        setConfirmationBox(false);
                        setDataConnectorRequest(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DataReloadSchedule;
