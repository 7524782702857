import { PUBLISHED, SideNavBarTabs } from "@/utils/appConstants";

interface MenuProps {
  _id: number;
  _icon: any;
  _name: string;
  _counter: number;
  onclick: (name: string, path: string) => void;
  selectedMenuItem: string;
  selectedIcon: any;
  _path: string;
  isPlatformSubscribed:boolean 
}

const MenuCard = ({
  _id,
  _icon,
  _name,
  _counter,
  onclick,
  selectedMenuItem,
  selectedIcon,
  _path,
  isPlatformSubscribed
}: MenuProps) => {
  return (
    <div
      onClick={isPlatformSubscribed ? () => onclick(_name, _path) : undefined}
    >
      <ul>
        <li key={_id} className=" flex-between py-2 pl-8 pr-6">
          <div className="flex-center gap-3">
            <img
              src={selectedMenuItem === _path ? selectedIcon : _icon}
              alt={_name}
            />
            <p>{_name}</p>
          </div>
          <span>{_counter}</span>
        </li>
      </ul>
    </div>
  );
};

export default MenuCard;
