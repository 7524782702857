import { Position } from "reactflow";
import { Direction } from "@/algorithms";
import { v4 as uuidv4 } from "uuid";
import { newAttrributeDescIcon } from "@/assets/icons/createportfolio";
import {
  DRAFT_STATUS,
  PROCESSING_STATUS,
  PUBLISHED,
  READY_FOR_REVIEW_STATUS,
  ALL_PORTFOLIO,
  SAVED,
  BUILDING_VISUALIZATIONS,
  ANALYZING_DATA,
  READY_FOR_MAPPING,
  UNPUBLISHED,
  SideNavBarTabs,
  _PORTFOLIOS,
  USER_ROLES,
  LemmataProducts,
  SORTINGDATA,
  VIEW_DASHBOARD,
  VIEW_DRAFT_DASHBOARD,
  CLEAR_FILTER,
} from "@/utils/appConstants";
import elkLayout from "@/algorithms/elk";
import { ConstantsItem } from "@/constants/nodes";
import { LayoutOptions } from "@/hooks/useAutoLayout";
import { FormDataProps, PlanType } from "@/components/admin-page/Configure";
import { getTokenFromLocalStorage } from "@/components/common/AuthService";
import { PortfolioItem } from "@/screens/PortfolioPage";
const TENANTS_URL = import.meta.env.VITE_TENANTS_URL;

//this is super important as we are generating the PAG Graph using this function
export const generatePahGraphUsingElk = async (
  node: any,
  edge: any,
  options: any
) => {
  const { nodes: nextNodes, edges: nextEdges } = await elkLayout(
    node,
    edge,
    options
  );

  // Mutating the nodes and edges directly here is fine because we expect our
  // layouting algorithms to return a new array of nodes/edges.
  for (const node of nextNodes) {
    node.style = { ...node.style, opacity: 1 };

    node.sourcePosition = getSourceHandlePosition(options.direction);

    node.targetPosition = getTargetHandlePosition(options.direction);
  }

  return { nextNodes, nextEdges };
};

// function to generate UUID
export const generateUuid = () => {
  const shortUuid = uuidv4();
  return shortUuid;
};
// function to get the position od the handle
export function getSourceHandlePosition(direction: Direction) {
  switch (direction) {
    case "TB":
      return Position.Bottom;
    case "BT":
      return Position.Top;
    case "LR":
      return Position.Right;
    case "RL":
      return Position.Left;
  }
}

export function getTargetHandlePosition(direction: Direction) {
  switch (direction) {
    case "TB":
      return Position.Top;
    case "BT":
      return Position.Bottom;
    case "LR":
      return Position.Left;
    case "RL":
      return Position.Right;
  }
}

export const defaultLayoutHierarchy: LayoutOptions = {
  algorithm: "d3-hierarchy",
  direction: "TB",
  spacing: [80, 80],
};

export const defaultLayoutHierarchyOnEdit: LayoutOptions = {
  algorithm: "d3-hierarchy",
  direction: "TB",
  spacing: [79, 79],
};

// Function to convert the array to nodes as per our custom nodes
export const generateNodes = (inputArray: any, id: string) => {
  return inputArray?.map((item: any, index: number) => {
    const isFirstChild = index === 0;
    const isLastChild = index === inputArray.length - 1;
    const attrId = item?.attributeId;
    const nodeType = isFirstChild
      ? "beginningNode"
      : isLastChild
      ? "endingNode"
      : "customDefaultNode";
    return {
      id: id + attrId,
      position: { x: 0, y: 0 }, //{ x: 0, y: 200 },
      data: {
        label: item?.attributeName || item?.pahName,
        id: attrId,
        pahName: item?.pahName || "",
        attributeId: attrId,
        icon: item?.icon,
      },
      type: nodeType,
    };
  });
};
export const generateSiblingsNodes = (inputArray: any) => {
  return inputArray?.map((item: any, index: number) => {
    const isLastChild = index === inputArray.length - 1;
    const attrId = item?.attributeId;
    let nodeType = isLastChild ? "endingNode" : "customDefaultNode";
    return {
      id: generateUuid(),
      position: { x: 0, y: 0 },
      data: {
        label: item?.attributeName || item?.pahName,
        id: attrId,
        pahName: item?.pahName || "",
        attributeId: attrId,
        icon: item?.icon,
      },
      type: nodeType,
    };
  });
};
// You need this for the pro version
export const proOptions = {
  account: "paid-pro",
  hideAttribution: true,
};
export const generateEdges = (nodes: any) => {
  const edges = [];

  // Generate edges connecting adjacent nodes
  for (let i = 0; i < nodes?.length - 1; i++) {
    const sourceNode = nodes[i];
    const targetNode = nodes[i + 1];

    const edge = {
      id: generateUuid(),
      source: sourceNode.id,
      target: targetNode.id,
      style: {
        strokeWidth: 2,
        stroke: "black",
      },
    };

    edges.push(edge);
  }
  return edges;
};

export const customEdgeStyles = {
  strokeWidth: 2,
  stroke: "#000",
};
export const markerEndStyle = { type: "arrow", color: "#000" };
export const edgeType = "smoothstep";

export function findNextItemById(attributeData: any[], id: string) {
  const index = attributeData.findIndex((item) => item.attributeId === id);
  let nextIndex = index + 1;
  if (index !== -1 && index < attributeData.length - 1) {
    const isLastElement = nextIndex === attributeData.length - 1;
    const isFirstElement = nextIndex === 0;
    return {
      data: attributeData[nextIndex],
      isLast: isLastElement,
      isFirst: isFirstElement,
    };
  }
  return null;
}
export const truncatedString = (inputString: string, length: number = 20) =>
  inputString?.length > length
    ? inputString.slice(0, length) + "..."
    : inputString;

export const addAttributeLevel = (attribute: any) =>
  attribute.map((att: any, index: number) => {
    return { ...att, attributeLevel: index + 1 };
  });

export const createNodePayload = (nodes: any) => {
  return nodes?.map((node: any) => {
    return {
      pahId: node.id,
      attributeId: node.data.attributeId,
      pahName: node.data.pahName === "" ? node.data.label : node.data.pahName,
      position: node.position,
    };
  });
};
export const convertEdgeToPayload = (edges: any) => {
  return edges?.map((edge: any) => {
    return {
      edgeId: edge.id,
      sourcePahId: edge.source,
      targetPahId: edge.target,
    };
  });
};

export const getFilteredPortfolioData = (
  portfolioData: any,
  status: string
) => {
  const filteredPortfolioData = portfolioData?.filter((item: any) => {
    if (status === ALL_PORTFOLIO) return item;
    if (status === SAVED) return item.status === DRAFT_STATUS;
  });
  return {
    length: filteredPortfolioData.length,
    data: filteredPortfolioData,
  };
};

export const generatingHierarchyAftResponse = (
  hierarchy: any,
  attributes: any
) => {
  const newHierarchy = hierarchy?.map((item: any) => {
    const nodesDataAfterMap = generateNodesAfterResponse(
      item.nodes,
      attributes
    );
    const edgesDataAfterMap = generateEdgesAftResponse(item.edges);
    return {
      ...item,
      nodes: nodesDataAfterMap,
      edges: edgesDataAfterMap,
    };
  });
  return newHierarchy;
};
export const generateNodesAfterResponse = (
  nodesData: any,
  attributeList: any,
  disableNodeButtons: boolean = true
) => {
  return nodesData
    ? nodesData?.map((item: any) => {
        const attrId = item?.attributeId;
        const attributeIcon = attributeList.filter(
          (att: any) => att.attributeId === attrId
        )[0]?.icon;
        return {
          id: item?.pahId,
          position: { x: 0, y: 0 },
          data: {
            label: item?.attributeName || item?.pahName,
            id: item?.pahId,
            pahName: item?.pahName || "",
            attributeId: attrId,
            icon: attributeIcon ?? newAttrributeDescIcon,
            disableNodeButtons: disableNodeButtons,
          },
          type: updateNodeType(attrId, attributeList),
        };
      })
    : [];
};
export const generateEdgesAftResponse = (edges: any) => {
  const newEdges = edges?.map((edge: any) => ({
    id: edge?.edgeId,
    source: edge?.sourcePahId,
    target: edge?.targetPahId,
    // type: edgeType,
    // markerEnd: markerEndStyle,
    // style: { opacity: 1 },
    style: {
      strokeWidth: 2,
      stroke: "black",
    },
  }));

  return newEdges;
};

export function getUpdateStatus(
  dateString: string,
  updatedString = "Updated on"
) {
  const currentDate = new Date();
  const updateDate = new Date(dateString);

  const millisecondsPerMinute = 1000 * 60;
  const millisecondsPerHour = millisecondsPerMinute * 60;
  const millisecondsPerDay = millisecondsPerHour * 24;

  const differenceMilliseconds = currentDate.getTime() - updateDate.getTime();
  const day = updateDate.getDate();
    const month = updateDate.getMonth() + 1; // Months are zero-indexed
    const year = updateDate.getFullYear();
  if(updatedString === "Created on "){
    return `Created on ${month}/${day}/${year}`
  }
  if (differenceMilliseconds < millisecondsPerMinute) {
    return updatedString === "" ? "Just Now" : "Updated just now";
  } else if (differenceMilliseconds < millisecondsPerHour) {
    const minutes = Math.floor(differenceMilliseconds / millisecondsPerMinute);
    return `${updatedString === "" ? "" : "Updated"} ${minutes} minute${
      minutes !== 1 ? "s" : ""
    } ago`;
  } else if (differenceMilliseconds < millisecondsPerDay) {
    const hours = Math.floor(differenceMilliseconds / millisecondsPerHour);
    return `${updatedString === "" ? "" : "Updated"} ${hours} hour${
      hours !== 1 ? "s" : ""
    } ago`;
  } else {
    return `${updatedString} ${month}/${day}/${year}`;
  }
}

export type StatusTagType =
  | typeof PUBLISHED
  | typeof DRAFT_STATUS
  | typeof PROCESSING_STATUS
  | typeof READY_FOR_REVIEW_STATUS
  | typeof BUILDING_VISUALIZATIONS
  | typeof ANALYZING_DATA
  | typeof READY_FOR_MAPPING
  | typeof UNPUBLISHED;

export function convertDateTime(dateTimeString: Date) {
  const dateTime = new Date(dateTimeString);
  return dateTime.toLocaleString("en-US", {
    month: "long", // Full month name
    day: "numeric", // Day of the month, numeric
    year: "numeric", // Full year
    hour: "numeric", // Hours (12-hour clock)
    minute: "2-digit", // Minutes
    second: "2-digit", // Seconds
    hour12: true, // Use 12-hour clock with AM/PM
  });
}

export function formatFileSize(sizeInBytes: number) {
  if (sizeInBytes < 1024) {
    return sizeInBytes + " B";
  } else if (sizeInBytes < 1024 * 1024) {
    return Math.ceil(sizeInBytes / 1024) + " KB";
  } else {
    return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
  }
}
/* This function is for checking Duplicate Portfolio Names
  @inputs: Data: Portfolios Array, portfolioName:string
  @return: True/False
*/
export const checkDuplicatePortfolio = (
  portfolioNamesArray: Array<string>,
  portfolioName: string
) => {
  const lowercaseArray = portfolioNamesArray.map((item: string) =>
    item?.toLowerCase().trim()
  );
  const portfolioNameLowerCase = portfolioName?.toLowerCase().trim();
  return lowercaseArray.includes(portfolioNameLowerCase) ? true : false;
};

export const checkAttributeIsEmpty = (attributes: any) => {
  const hasEmptyAttributeName = attributes.some(
    (item: any) => item.attributeName === ""
  );
  return !hasEmptyAttributeName;
};

export const checkAttributesName = (
  attributeList: ConstantsItem[],
  attributeName: string,
  index: any
) => {
  if (
    attributeList[index] &&
    attributeList[index].attributeName === attributeName
  ) {
    return false;
  } else {
    // we need to check the attributeList for the same attribute name other than the index passed
    const count = attributeList.reduce((acc, item) => {
      if (
        item.attributeName?.toLowerCase().trim() ===
        attributeName?.toLowerCase().trim()
      ) {
        acc++;
      }
      return acc;
    }, 0);
    return count >= 1;
  }
};
/*****************************************************
 * This function is for checking Duplicate Portfolio Names
  @inputs: Arrays
  @return: True/False
 ******************************************************
 */
export function checkArraysAreEqual(arr1: Array<any>, arr2: Array<any>) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

//update  disableNodeButtons to false
export const editModeUpdatePah = (nodesData: any) => {
  return nodesData
    ? nodesData.map((node: any) => {
        return {
          ...node,
          data: {
            ...node.data,
            disableNodeButtons: false,
          },
        };
      })
    : [];
};
export const updatePahAfterSaveAttributeInEditMode = (
  nodesData: any,
  attributes: any
) => {
  return nodesData
    ? nodesData.map((node: any) => {
        const attrId = node.data.attributeId;
        return {
          ...node,
          type: updateNodeType(attrId, attributes),
        };
      })
    : [];
};

export const updateNodeType = (attrId: string, attributes: any) => {
  let nodeType = "customDefaultNode";
  if (attributes && attributes?.length > 0) {
    nodeType =
      attrId === attributes[0].attributeId
        ? "beginningNode"
        : attrId === attributes[attributes.length - 1].attributeId
        ? "endingNode"
        : "customDefaultNode";
  }
  return nodeType;
};
export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
export function getOwnerNames(owners: any) {
  if (!owners || !Array.isArray(owners)) {
    return "";
  }
  const ownersName = owners
    .map((owner: any) => {
      return owner?.firstName + " " + owner?.lastName;
    })
    .join(",");

  return ownersName.repeat(owners.length);
}

// function to create monogram we sues
export function createMonogram(inputString: string) {
  const words = inputString.trim().split(/\s+/);

  const firstLetter = words[0][0].toUpperCase();
  const lastLetter = words[words.length - 1][0].toUpperCase();

  const monogram = firstLetter + lastLetter;

  return monogram;
}

interface ObjectProps {
  appId: string;
  sheet: string;

  planType: string;
}

type LiveOutputProps = {
  contents: Array<{ appId: string; sheet: string; planType: string }>;
};
type DraftOutputProps = {
  draftContents: Array<{ appId: string; sheet: string; planType: string }>;
};

export function convertFormData(
  formData: Record<PlanType, FormDataProps>,
  dashboardType: string
): LiveOutputProps | DraftOutputProps {
  if (dashboardType === "Live") {
    const output: LiveOutputProps = { contents: [] };
    for (const planKey of Object.keys(formData) as PlanType[]) {
      output.contents.push({
        appId: formData[planKey].appId,
        sheet: formData[planKey].sheetId,
        planType: planKey.charAt(0).toUpperCase() + planKey.slice(1),
      });
    }
    return output;
  } else {
    const output: DraftOutputProps = { draftContents: [] };

    for (const planKey of Object.keys(formData) as PlanType[]) {
      output.draftContents.push({
        appId: formData[planKey].appId,
        sheet: formData[planKey].sheetId,
        planType: planKey.charAt(0).toUpperCase() + planKey.slice(1),
      });
    }
    return output;
  }
}

export function extractIframeData(iframe: any) {
  const url = new URL(iframe.src);
  const params = new URLSearchParams(url.search);

  return {
    host: `${url.origin}/`,
    appId: params.get("appid") || "",
    sheet: params.get("sheet") || "",
    theme: params.get("theme") || "",
    opt: params.get("opt") || "",
    style: iframe.style.cssText || "",
  };
}

interface SideBarItem {
  _id: number;
  _name: string;
  _icon?: string;
  _icon_color?: string;
  children: SideBarItem[];
  isCollapsible?: boolean;
  path: string;
  selectedIcon?: string;
  _counter?: number;
  onClick?: string;
}
export interface PortfolioCountsProps {
  RapidPortfolio: number;
  RapidPending: number;
  AcceleratePortfolio: number;
  AcceleratePending: number;
  ElitePortfolio: number;
  ElitePending: number;
  PublishedPortfolio: number;
  UnPublishedPortfolio: number;
  subscriptionApproval: number;
}
export const updateHomepageSideBarCounters = (
  sideBarData: SideBarItem[],
  portfolioCounts: PortfolioCountsProps
): SideBarItem[] => {
  const updatedSideBarData = sideBarData.map((item) => {
    if (item._name === SideNavBarTabs.MY_SUBSCRIPTIONS) {
      item.children = item.children.map((child) => {
        if (child._name === SideNavBarTabs.RAPID) {
          child.children = child.children.map((grandChild) => {
            if (grandChild._name === _PORTFOLIOS) {
              grandChild._counter = portfolioCounts.RapidPortfolio;
            } else if (grandChild._name === SideNavBarTabs.PENDING_APPROVALS) {
              grandChild._counter = portfolioCounts.RapidPending;
            }
            return grandChild;
          });
        } else if (child._name === SideNavBarTabs.ACCELERATE) {
          child.children = child.children.map((grandChild) => {
            if (grandChild._name === _PORTFOLIOS) {
              grandChild._counter = portfolioCounts.AcceleratePortfolio;
            } else if (grandChild._name === SideNavBarTabs.PENDING_APPROVALS) {
              grandChild._counter = portfolioCounts.AcceleratePending;
            }
            return grandChild;
          });
        } else if (child._name === SideNavBarTabs.ELITE) {
          child.children = child.children.map((grandChild) => {
            if (grandChild._name === _PORTFOLIOS) {
              grandChild._counter = portfolioCounts.ElitePortfolio;
            } else if (grandChild._name === SideNavBarTabs.PENDING_APPROVALS) {
              grandChild._counter = portfolioCounts.ElitePending;
            }
            return grandChild;
          });
        }
        return child;
      });
    } else if (item._name === SideNavBarTabs._MY_PORTFOLIOS) {
      item.children = item.children.map((child) => {
        if (child._name === SideNavBarTabs.PUBLISHED) {
          child._counter = portfolioCounts.PublishedPortfolio;
        } else if (child._name === SideNavBarTabs._UN_PUBLISHED) {
          child._counter = portfolioCounts.UnPublishedPortfolio;
        }
        return child;
      });
    }
    return item;
  });
  return updatedSideBarData;
};

export const getUserRoles = () => {
  const user_roles = getTokenFromLocalStorage(USER_ROLES);
  const userRole = JSON.parse(user_roles ?? "[]");
  return userRole;
};
export type SubscriptionListProps = {
  _id: string;
  portfolioName: string;
  productCode: string;
  subscribers: [];
  owners: [
    {
      _id: string;
      firstName: string;
      lastName: string;
    }
  ];
  updatedAt: string;
  isSubmittedForApproval: boolean;
  billingFrequency: string;
  subscriptionPlanType: string;
  isSubscribed: boolean;
};
export function groupSubscriptionByProducts(
  inputData: SubscriptionListProps[]
) {
  const groupedData: Record<string, SubscriptionListProps[]> = {};

  LemmataProducts.forEach((company) => {
    const filteredData = inputData.filter(
      (item) => item.productCode === company.productName
    );

    // Only include the company in the result if there is any data
    if (filteredData.length > 0) {
      groupedData[company.productName] = filteredData;
    }
  });

  return groupedData;
}
interface Owner {
  _id: string;
  firstName: string;
  lastName: string;
}

export interface PortfolioProps {
  _id: string;
  portfolioName: string;
  productCode: string;
  subscribers: string[];
  createdBy: string;
  createdAt: string;
  owners: Owner[];
  updatedAt: string;
  planType: string;
  status?: any;
  permissions?: any;
  permiumInsigths?: any;
}

export const sortByProductCode = (
  array: PortfolioProps[]
): PortfolioProps[] => {
  return array?.toSorted((a: PortfolioProps, b: PortfolioProps) => {
    if (a.productCode < b.productCode) {
      return -1;
    }
    if (a.productCode > b.productCode) {
      return 1;
    }
    return 0;
  });
};
export const functionToDownloadFile = (data: any, fileName: string) => {
  const blob = new Blob([data], {
    type: "application/octet-stream",
  });
  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);
  // Create a link and trigger the download
  const link = document.createElement("a");
  link.href = url;
  // Optional: Set a filename (if not provided by the API)
  link.download = fileName;
  // Append link to the body, trigger click, and remove link
  document.body.appendChild(link);
  link.click();
  link.remove();
  // Clean up the URL
  window.URL.revokeObjectURL(url);
};

export function formatToLocalDate(isoDate: string): string {
  const date = new Date(isoDate);

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true, // Ensures time is in 12-hour format
  };

  // Format the date using toLocaleString in the local time zone
  return date.toLocaleString("en-US", options);
}

export const redirectToTenants = () => {
  localStorage.clear();
  window.location.href = `${TENANTS_URL}?logout=yes`;
};

export const sortPortfolios = (
  portfolios: PortfolioItem[] | undefined,
  sortBy: string
): PortfolioItem[] => {
  const portfoliosCopy = [...portfolios];
  return portfoliosCopy.sort((a, b) => {
    switch (sortBy) {
      case SORTINGDATA.portfolio.asc:
        return a.portfolioName?.localeCompare(b.portfolioName ?? "") ?? 0;
      case SORTINGDATA.portfolio.desc:
        return b.portfolioName?.localeCompare(a.portfolioName ?? "") ?? 0;
      case SORTINGDATA.productNames.asc:
        return a.productCode?.localeCompare(b.productCode) ?? 0;
      case SORTINGDATA.productNames.desc:
        return b.productCode?.localeCompare(a.productCode) ?? 0;
      case SORTINGDATA.creator.asc: {
        const aCreatorAsc = `${a.owners?.[0]?.firstName ?? ""} ${a.owners?.[0]?.lastName ?? ""
          }`;
        const bCreatorAsc = `${b.owners?.[0]?.firstName ?? ""} ${b.owners?.[0]?.lastName ?? ""
          }`;
        return aCreatorAsc.localeCompare(bCreatorAsc);
      }
      case SORTINGDATA.creator.desc: {
        const aCreatorDes = `${a.owners?.[0]?.firstName ?? ""} ${a.owners?.[0]?.lastName ?? ""
          }`;
        const bCreatorDes = `${b.owners?.[0]?.firstName ?? ""} ${b.owners?.[0]?.lastName ?? ""
          }`;
        return bCreatorDes.localeCompare(aCreatorDes);
      }
      case SORTINGDATA.status.asc:
        return a.status?.localeCompare(b.status) ?? 0;
      case SORTINGDATA.status.desc:
        return b.status?.localeCompare(a.status) ?? 0;
      case SORTINGDATA.createdDate.asc:
        return (
          (a.createdAt ? new Date(a.createdAt).getTime() : 0) -
          (b.createdAt ? new Date(b.createdAt).getTime() : 0)
        );
      case SORTINGDATA.createdDate.desc:
        return (
          (b.createdAt ? new Date(b.createdAt).getTime() : 0) -
          (a.createdAt ? new Date(a.createdAt).getTime() : 0)
        );
      case SORTINGDATA.updatedDate.asc:
        return (
          (a.updatedAt ? new Date(a.updatedAt).getTime() : 0) -
          (b.updatedAt ? new Date(b.updatedAt).getTime() : 0)
        );
      case SORTINGDATA.updatedDate.desc:
        return (
          (b.updatedAt ? new Date(b.updatedAt).getTime() : 0) -
          (a.updatedAt ? new Date(a.updatedAt).getTime() : 0)
        );
      default:
        return 0;
    }
  });
};
export function highlightText(highlightedText: string, actualText: string): string {
  if (!highlightedText.trim()) return actualText;
  const regex = new RegExp(`(${highlightedText})`, "gi");
  return actualText.replace(regex, `<span style="background-color: yellow;">$1</span>`);
}

export function extractTime(dateTimeString: string): string {
  const date = new Date(dateTimeString);
  let hours: number | string = date.getHours();
  let minutes = date.getMinutes().toString().padStart(2, '0') ?? "00";
  if(minutes === 'NaN') {minutes="00"}
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  hours = hours.toString().padStart(2, '0');

  return `${hours}:${minutes} ${amOrPm}`;
}

export const filterPortfolios = (
  portfolios: PortfolioItem[] | undefined,
  filterBy: string
): PortfolioItem[] => {
  const returnData = portfolios ? portfolios?.filter(portfolio => {
    switch (filterBy) {
      case PUBLISHED:
        return portfolio.isPublished;
      case UNPUBLISHED:
        return portfolio.isPublished === false;
      case READY_FOR_MAPPING:
        return portfolio.status === READY_FOR_MAPPING;
      case READY_FOR_REVIEW_STATUS:
        return portfolio.status === READY_FOR_REVIEW_STATUS;
      case VIEW_DASHBOARD:
        return portfolio.isLiveContentAvailable;
      case VIEW_DRAFT_DASHBOARD:
        return portfolio.isDraftContentAvailable;
      case CLEAR_FILTER:
        return portfolios;
      default:
        return portfolios;
    }
  }) : [];
  return returnData
}