import {
  renamePortfolio,
} from "@/state/slices/PortfolioSlice";
import {
  ALLOWED_SPECIAL_CHARS_REG_EXP,
} from "@/utils/appConstants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { close } from "@/assets/icons/index";
import { PORTFOLIO_LENGTH_AND_SPECIAL_CHAR_MSG, PORTFOLIO_NAME_EXIST_MSG, PORTFOLIO_NAME_REQUIRED_MSG } from "@/utils/messageConstants";
import { AppDispatch } from "@/state/store";

type RenamePortfolioProps = {
  onClose: () => void;
  isOpen: boolean;
  setPortfolioName: (name:string) => void;
  handlePortfolioNameModalOpen: (status: boolean) => void;
  portfolioId: string | undefined;
  portfolioName: string;
};

const RenamePortfolio = ({
  onClose,
  isOpen,
  setPortfolioName,
  handlePortfolioNameModalOpen,
  portfolioId,
  portfolioName,
}: RenamePortfolioProps) => {
  if (!isOpen) return null;
  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState<string>(portfolioName);

  const [errors, setErrors] = useState<string>("");

  const handleOnChange = (event: any) => {
    setName(event.target.value);
    setErrors("");
    const enteredValue = event.target.value;
    if (
      (enteredValue.trim() &&
        !ALLOWED_SPECIAL_CHARS_REG_EXP.test(enteredValue)) ||
      enteredValue.length > 30
    ) {
      setErrors(PORTFOLIO_LENGTH_AND_SPECIAL_CHAR_MSG);
      return;
    }
    if (event.key === "Enter") {
      if (!enteredValue.trim()) {
        setErrors(PORTFOLIO_NAME_REQUIRED_MSG);
        return;
      }
    }
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    setErrors("");
    if (name == "") {
      setErrors(PORTFOLIO_NAME_REQUIRED_MSG);
    } else if (name.trim() && !ALLOWED_SPECIAL_CHARS_REG_EXP.test(name)) {
      setErrors(PORTFOLIO_LENGTH_AND_SPECIAL_CHAR_MSG);
      return;
    } else {
      //@ts-ignore
      await dispatch(
        //@ts-ignore
        renamePortfolio({ id: portfolioId, data: { portfolioName: name.trim() } })
      ).then((action: any) => {
        if (renamePortfolio.fulfilled.match(action)) {
          setPortfolioName(name);
          handlePortfolioNameModalOpen(false);
          toast.dismiss();
          toast.success("Portfolio Name updated successfully");
        } else if (renamePortfolio.rejected.match(action)) {
          if (action.error.message === 'Request failed with status code 409') {
            setErrors(PORTFOLIO_NAME_EXIST_MSG)
          }
          handlePortfolioNameModalOpen(true);
          toast.dismiss();
          toast.error("Failed to rename the Portfolio Name");
        }
      });
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center my-auto bg-gray-800 bg-opacity-50 z-10">
      <div className="bg-white rounded-lg p-6 sm:w-1/3 w-1/3 l:w-1/3 ">
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-lg font-semibold">Rename Portfolio</h2>
          <button name='close-btn' className="text-gray-500" onClick={onClose}>
            <img src={close} className="w-6 h-6 text-gray-600" alt="close" />
          </button>
        </div>
        <div className="p-4">
          <div className="mb-4">
            <input
              type="text"
              id="portfolioName"
              className={`w-full border ${errors ? 'border-red-500' : 'border-gray-300'} rounded-md px-3 py-2`}
              value={name}
              onChange={(e) => handleOnChange(e)}
              autoFocus={true}
            />
            {errors && <p className="text-red-500 mt-3">{errors}</p>}
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className="mr-2 px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2  bg-blue-500 text-white rounded-md hover:bg-blue-600
         "
            onClick={(e) => handleSave(e)}
            disabled={errors ? true : false}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenamePortfolio;
