import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "@/screens/HomePage";
import LoginPage from "@/screens/LoginPage";
import CreatePortfolio from "@/screens/CreatePortfolio";
import { ReactFlowProvider } from "reactflow";
import PrivateRoute from "./components/common/PrivateRoute";
import PublicRoute from "./components/common/PublicRoutes";
import StytchLogin from "@/screens/StytchLogin";
import { useState, useEffect, useCallback, useRef } from "react";
import AiAnalytics from "./screens/AiAnalytics";
import AdminPage from "./screens/AdminPage";
import AdminRoute from "./components/common/AdminRoute";
import Notfound from "./screens/Notfound";
import useOnlineStatus from "./hooks/useOnlineStatus";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import {
  getTokenFromLocalStorage,
  saveTokenToLocalStorage,
} from "./components/common/AuthService";
import { ACCESS_TOKEN, REFRESH_TOKEN, SYSTEM_ADMIN } from "./utils/appConstants";
import LemmataLoginPage from "./screens/LemmataLoginPage";
import { useInactivityTracker } from "./hooks/useInactivityTracker";
import { InactivityWarning } from "./components/common/InactivityWarning";
import { useDispatch } from "react-redux";
import { LogoutCall } from "./state/slices/LogoutSlice";
import { AppDispatch } from "./state/store";
import useToast from "./hooks/useToast";
import { getUserRoles, redirectToTenants } from "./utils/utils";
const INACTIVITY_DURATION_MINUTES = import.meta.env.VITE_USER_INACTIVITY_DURATION_MINUTES;
const WARNING_DURATION_SECONDS = import.meta.env.VITE_WARNING_DURATION_SECONDS;


const SLUG = import.meta.env.VITE_STYTCH_SLUG;
const BASE_AUTH_URL = import.meta.env.VITE_BASE_AUTH_URL;
const API_AUTH_PATH = import.meta.env.VITE_API_AUTH_PATH;
const TENANTS_URL = import.meta.env.VITE_TENANTS_URL;

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const isOnline = useOnlineStatus();
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(
    getTokenFromLocalStorage(ACCESS_TOKEN)
  );
  const isRefreshing = useRef(false); // Track if a refresh token request is in progress
  const refreshPromise = useRef(null); // Store ongoing refresh promise to reuse
  const { success, error } = useToast();
  const [failedAttempts, setFailedAttempts] = useState(0); // State to track failed attempts
  // const stytch = useStytchB2BClient();
  function handleLogout() {
    if (getUserRoles().includes(SYSTEM_ADMIN)) {
      redirectToTenants();
      success('You have successfully logged out');
    } else {
      dispatch(LogoutCall()).finally(redirectToTenants);
    }
  }

  const { isWarningOpen, countdown, handleStayLoggedIn, setIsWarningOpen } = useInactivityTracker(
    {
      inactivityTime: INACTIVITY_DURATION_MINUTES,
      warningTime: WARNING_DURATION_SECONDS,
    }
  );
  useEffect(() => {
    if (failedAttempts >= 2) {
      if (!getUserRoles().includes(SYSTEM_ADMIN)) {
        // After 2 sequent failed attempts, clear local storage and redirect to login page
        dispatch(LogoutCall()).then((action) => {
          if (LogoutCall.fulfilled.match(action)) {
            localStorage.clear();
            success('You have successfully logged out')
            window.location.href = TENANTS_URL + "?logout=yes";
          } else {
            error('We encountered an issue while logging you out. Please check your connection and try again')
          }
        });
      } else {
        localStorage.clear();
        success('You have successfully logged out')
        window.location.href = TENANTS_URL + "?logout=yes";
      }
    }
  }, [failedAttempts]);

  const refreshAccessToken = useCallback(async () => {
    // If a refresh is already in progress, return the ongoing promise
    if (isRefreshing.current) {
      return refreshPromise.current;
    }

    try {
      isRefreshing.current = true; // Mark as refreshing
      // Always get the latest refresh token from local storage
      const localStoredRefreshToken = getTokenFromLocalStorage(REFRESH_TOKEN);
      if (!localStoredRefreshToken) {
        throw new Error("No refresh token found in local storage.");
      }
      refreshPromise.current = await axios.post(
        `${BASE_AUTH_URL}${API_AUTH_PATH}/refresh-token`,
        { refreshToken: localStoredRefreshToken } // Use latest refresh token
      );
      const response = await refreshPromise.current; // Await the refresh request
      //@ts-ignore
      const { accessToken, refreshToken } = response?.data?.data;
      if (accessToken && refreshToken) {
        // Save both the new access token and the new refresh token to local storage
        saveTokenToLocalStorage(ACCESS_TOKEN, accessToken);
        saveTokenToLocalStorage(REFRESH_TOKEN, refreshToken);

        // Update state with the new access token
        setAccessToken(accessToken);
        setFailedAttempts(0); // Reset failed attempts on success
        return { accessToken, refreshToken };
      } else {
        console.error("Access Token and Refresh token values not available");
        return;
      }
    } catch (error: any) {
      console.log("Failed to call refresh token api:", error);
      setFailedAttempts((prevAttempts) => prevAttempts + 1);
    } finally {
      isRefreshing.current = false; // Mark refresh as complete
      refreshPromise.current = null; // Reset refresh promise
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      const decodedAccessToken = jwtDecode(accessToken);
      const expirationTime = decodedAccessToken.exp! * 1000;
      const now = new Date().getTime();
      const timeUntilExpiration = expirationTime - now - 60 * 1000; // 1 min before expiration

      // Set a timeout to refresh the token 1 minute before it expires
      const timeoutId = setTimeout(refreshAccessToken, timeUntilExpiration);

      // Clean up the timeout when the component unmounts or token changes
      return () => clearTimeout(timeoutId);
    }
  }, [accessToken, refreshAccessToken]);

  useEffect(() => {
    // Polling every minute to check if the token is close to expiration
    const intervalId = setInterval(() => {
      const accessTokenFromLocal = getTokenFromLocalStorage(ACCESS_TOKEN);
      if (accessTokenFromLocal) {
        const decodedToken = jwtDecode(accessTokenFromLocal);
        const expirationTime = decodedToken.exp! * 1000;
        const now = new Date().getTime();
        const timeUntilExpiration = expirationTime - now;

        // If the token is about to expire in the next minute, refresh it
        if (timeUntilExpiration <= 60 * 1000) {
          refreshAccessToken();
        }
      }
    }, 60 * 1000); // Check every minute

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [refreshAccessToken]);

  return (
    <ReactFlowProvider>
      {isOnline ? (
        <Routes>
          {/* public route */}
          <Route path="/" element={<PublicRoute />}>
            <Route
              path={`${loading ? "/login" : "/"}`}
              element={<LoginPage setLoading={setLoading} />}
            />
            <Route path={`/${SLUG}`} element={<StytchLogin />} />
            <Route
              path={`/stytch/login/callback`}
              element={<StytchLogin setLoading={setLoading} />}
            />
            <Route path="/admin/login" element={<LemmataLoginPage />} />
          </Route>

          {/* Private route */}
          <Route path="/" element={<PrivateRoute />}>
            {/* Wrap all private routes */}
            <Route path="/dashboard" element={<HomePage />} />
            <Route path="/portfolio/:id" element={<CreatePortfolio />} />
            <Route
              path="/ai-analytics/:id/:planType/:dashboardType"
              element={<AiAnalytics />}
            />
          </Route>

          {/* Admin route */}
          <Route path="/" element={<AdminRoute />}>
            {/* Wrap all Admin routes */}
            <Route path="/admin" element={<AdminPage />} />
          </Route>
          <Route path="*" element={<Notfound isBadRoute={true} />} />
        </Routes>
      ) : (
        <Notfound isBadRoute={false} />
      )}
      <InactivityWarning
        isOpen={isWarningOpen}
        countdown={countdown}
        onStayLoggedIn={handleStayLoggedIn}
        handleLogout={handleLogout}
        setIsWarningOpen={setIsWarningOpen}
      />
    </ReactFlowProvider>
  );
}

export default App;
