import useToast from "@/hooks/useToast";
import {
  addAiConfiguration,
  getAdminPageQlikData,
} from "@/state/slices/AdminPageSlice";
import { AppDispatch } from "@/state/store";
import { convertFormData } from "@/utils/utils";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Label } from "../ui/Label";

export interface FormDataProps {
  appId: string;
  sheetId: string;
  planType?: string;
}

export type PlanType = "rapid" | "accelerate" | "elite";
type ConfigureProps = {
  handleConfigure: () => void;
  portfolioId: string;
  isPublished:boolean;
};
const Configure = ({ handleConfigure, portfolioId, isPublished }: ConfigureProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { error, success, info } = useToast();
  const [formData, setFormData] = useState<Record<PlanType, FormDataProps>>({
    rapid: { appId: "", sheetId: "" },
    accelerate: { appId: "", sheetId: "" },
    elite: { appId: "", sheetId: "" },
  });
  const [dashboardType, setDashboardType] = useState("Live");
  const [isChecked, setIsChecked] = useState(false);
  const [checkboxErrorMsg, setCheckboxErrorMsg] = useState("");
  const transformArrayToFormData = (qlikContent: any) => {
    const result: Record<PlanType, FormDataProps> = {
      rapid: { appId: "", sheetId: "" },
      accelerate: { appId: "", sheetId: "" },
      elite: { appId: "", sheetId: "" },
    };

    qlikContent.forEach((item: any) => {
      const planType = item.planType?.toLowerCase() as PlanType;
      result[planType] = {
        appId: item.appId,
        sheetId: item.sheet,
      };
    });
    return result;
  };

  useEffect(() => {
    dispatch(getAdminPageQlikData({ id: portfolioId }))
      .then((action: any) => {
        const qlikContent = action.payload?.data?.qlik?.contents ?? [];
        const draftContents = action.payload?.data?.qlik?.draftContents ?? [];
        if(dashboardType=="Draft"){
          setFormData(transformArrayToFormData(draftContents));
        } else if(dashboardType=="Live"){
          setFormData(transformArrayToFormData(qlikContent));
        }
      })
      .catch((err: any) => console.log(err));
  }, [dashboardType, portfolioId]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    plan: PlanType
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [plan]: {
        ...prevFormData[plan],
        [name]: value,
      },
    }));
  };

  const handleSubmit = () => {
    let contentType = "contents"
    if (!isChecked && dashboardType === "Live") {
      setCheckboxErrorMsg("Please check the checkbox before proceeding.");
      return;
    }
    if (dashboardType === "Draft") {
      contentType = "draftContents"
    }
    const formDataArray:any = convertFormData(formData, dashboardType);
   // Validate appId and sheet in all objects
  const invalidEntries = formDataArray[contentType].filter(
    (item: { appId: string; sheet: string }) => !item.appId.trim() || !item.sheet.trim()
  );

  if (invalidEntries.length > 0) {
    error("App ID and Sheet ID cannot be empty.");
    return;
  }
    //handleConfigure();
    dispatch(
      addAiConfiguration({ id: portfolioId, dashboardType:dashboardType, data: convertFormData(formData, dashboardType) })
    ).then((action: any) => {
      if (addAiConfiguration.fulfilled.match(action)) {
        success("Configuration saved successfully");
        handleConfigure();
      } else if (addAiConfiguration.rejected.match(action)) {
        error("Failed to save configuration. Please try again ");
      }
    }).catch((err: any) => console.log(err));
  };
  // Function to check if all appId and sheetId are empty
  const isFormDataEmpty = Object.values(formData).every(
    (entry) => !entry.appId && !entry.sheetId
  );
  const handleStatusChange = (status:string) => {
    //console.log("status=>",status,"formData=>", formData,"isFormDataEmpty=>", isFormDataEmpty, "isPublished=>",isPublished );
    if (status === "Draft" && (isFormDataEmpty || !isPublished) ) {
      setDashboardType("Live")
      info("A draft can only be created after the initial live content is set up and the portfolio is published.")
      return;
    }
    setDashboardType(status)    
  };
  const handleChange = () => {
    setIsChecked(!isChecked)
    setCheckboxErrorMsg("")
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20">
      <div className="bg-white p-8 rounded shadow-lg w-3/4">
        <h2 className="text-xl mb-6 ">Configure Dashboards</h2>
        {/* Added Select Box */}
        <div className="mb-4 text-center">
          <div className="grid grid-cols-1 gap-2 w-full">
            <div className="flex items-center justify-center gap-2">
              <h3 className="text-lg">Dashboard Type:</h3>
              <select
                id="dashboardStatus"
                name="dashboardStatus"
                className="p-2 border rounded w-1/4 text-sm"
                onChange={(e) => handleStatusChange(e.target.value)}
                value={dashboardType}
              >
                <option value="Live">Live</option>
                <option value="Draft">Draft</option>
              </select>
            </div>
          </div>
        </div>
        {/* <hr className="mb-4"/> */}
        <div className="flex items-center justify-between gap-4 mb-2 divide-x-2 ">
          {Object.keys(formData).map((plan) => (
            <div key={plan} className="w-full">
              <h3 className="text-lg mb-2 mx-auto pl-[45px]  ">
                {plan.charAt(0).toUpperCase() + plan.slice(1)}
              </h3>
              <div className="grid grid-cols-1 gap-2  pl-10 w-full">
                {["appId", "sheetId"].map((field) => (
                  <div key={field} className="mb-2 w-full">
                    <label
                      htmlFor={`${plan}-${field}`}
                      className="block mb-1 text-sm"
                    >
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                    </label>
                    <input
                      type="text"
                      id={`${plan}-${field}`}
                      name={field}
                      value={formData[plan as PlanType][
                        field as keyof FormDataProps
                      ]?.trim()}
                      onChange={(e) => handleInputChange(e, plan as PlanType)}
                      className="p-2 border rounded w-full text-xs"
                      placeholder={
                        field.charAt(0).toUpperCase() + field.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div>
          {dashboardType == "Live" && (
              <>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={()=>handleChange()}
                  id="checkbox_qlik_live_configuration"
                  className="mt-3"
                />
                <Label htmlFor="checkbox_qlik_live_configuration">
                  &nbsp;&nbsp;Check to confirm these changes to the live dashboard
                </Label>
                {checkboxErrorMsg && <p style={{ color: "red" }}>{checkboxErrorMsg}</p>}
              </>
              )
            }
        </div>
        <div className="flex justify-center space-x-4">
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded"
            onClick={() => handleConfigure()}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleSubmit}
          >
            Configure
          </button>
        </div>
      </div>
    </div>
  );
};

export default Configure;
