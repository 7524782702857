import React from "react";
import { uploadBlueIcon } from "@/assets/icons";
import { Button } from "@/components/ui/Button";
import { close } from "@/assets/icons/index";
type ModalHeaderProps = {
    isUpload: boolean;
    handleUploadMore: any;
    handleCancel:any;
    isSubmitToPublish:boolean;
    modalTitle:string;
  };
const ModalHeader: React.FC<ModalHeaderProps> = ({
    isUpload,
    handleUploadMore,
    handleCancel,
    isSubmitToPublish,
    modalTitle
  }) => {

    return (
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-semibold">{modalTitle}</h2>
          <div className="flex justify-between items-center mb-4">
            {isUpload && !isSubmitToPublish && (
              <Button
                variant="text"
                onClick={()=>handleUploadMore(false)}
                className="text-blue-700 font-semibold"
              >
                <img src={uploadBlueIcon} alt="Upload" className="mr-2" />{" Upload New"}
              </Button>
            )}
            <Button
              variant="text"
              className="text-gray-500 "
              onClick={handleCancel}
            >
               <img src={close} className="w-6 h-6 text-gray-600" />
            </Button>
          </div>
        </div>
    )
  }
  export default ModalHeader;