import React from "react";
import { Button } from "../ui/Button";
import { deletePortfolio } from "@/assets/icons-color";
type ContextMenuProps = {
  isOpen: boolean;
  position: { x: number; y: number };
  action: any;
  onMouseLeave: () => void;
};
const ContextMenu = ({
  isOpen,
  position,
  action = [],
  onMouseLeave,
}: ContextMenuProps) => {
  return (
    isOpen && (
      <div
        style={{
          position: "fixed",
          left: position.x,
          top: position.y,
          zIndex: 1000,
          border: "solid 1px #CCC",
          borderRadius: 3,
          backgroundColor: "white",
          padding: 5,
          display: "flex",
          flexDirection: "column",
        }}
        onMouseLeave={onMouseLeave}
      >
        {action.map((action: any) => (
          <Button
            key={action?.label}
            onClick={() => action?.effect(action?.nodeId)}
            variant="outline"
            style={{
              color: "rgba(255, 63, 63, 1)",
              width: "243px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <div className="flex gap-2">
              <img src={deletePortfolio} alt="delete" />
              {action.label}
            </div>
          </Button>
        ))}
      </div>
    )
  );
};
export default ContextMenu;
