
import {
  SideNavBarTabs,
} from "./appConstants";
import { Badge } from "@/components/ui/badge";

const TabPillStatus = ({ status }: { status: string }) => {
  let textColor = "";
  let bgColor = "";
  switch (status) {
    case SideNavBarTabs.RAPID:
      textColor = '#FF4D00';
      bgColor = "#FFFFFF";
      break;
    case SideNavBarTabs.ACCELERATE:
      textColor = "#EB00FF";
      bgColor = "#FFFFFF";
      break;
    case SideNavBarTabs.ELITE:
      textColor = "#009934";
      bgColor = "#FFFFFF";
      break;
    default:
      textColor = "text-gray-500";
      bgColor = "bg-gray-100";
  }

  return (
    <Badge style={{ color: `black`, border: `solid 1px #333333`, background:`${bgColor}` }}>
      <small className="text-sm">{status}</small>
    </Badge>
  )
};

export default TabPillStatus;
